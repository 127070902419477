import React from 'react';
import styled from 'styled-components';

const Card = ({ children, onClick, ...rest }) => {
	return (
		<StyledCard onClick={onClick} {...rest}>
			{children}
		</StyledCard>
	);
};

const StyledCard = styled.div`
	width: 100%;
	height: 100%;

	padding: 10px 15px 10px 16px;
	border-radius: 8px;
	border: solid 0.8px #d8d8d8;
	background-color: #ffffff;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	word-break: break-word;

	${(props) => props.onClick && `cursor: pointer;`}
`;

export default Card;
