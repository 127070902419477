import React from 'react';
import styled from 'styled-components';
import AudioIcon from 'assets/images/audioicon@2x.png';
import { Image, Space, Button } from 'antd';
import useAudioPlayer from 'util/useAudioPlayer';
import Bar from './AudioBar';

const AudioPlayer = ({ file, onDelete, onChangeFile, children }) => {
	const { curTime, duration, playing, setPlaying, setClickedTime } = useAudioPlayer();
	return (
		<Container>
			<Image src={AudioIcon} preview={false} width={100} />
			<div>{file.name}</div>
			<Space>
				{children}
				<Button type="text" onClick={onDelete}>
					<span style={{ color: '#f64e60' }}>Delete</span>
				</Button>
			</Space>
			<audio id="audio">
				<source src={file?.src} type={file.type} />
			</audio>
			<PlayerContainer>
				<Bar
					playing={playing}
					curTime={curTime}
					duration={duration}
					onTimeUpdate={(time) => setClickedTime(time)}
					onPlay={() => setPlaying(true)}
					onPause={() => setPlaying(false)}
				/>
			</PlayerContainer>
		</Container>
	);
};

export default AudioPlayer;

const Container = styled.div`
	width: 100%;
	height: 220px;
	border: 1px solid #80808080;
	border-radius: 6px;
	position: relative;
	overflow: hidden;
	padding-bottom: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;
const PlayerContainer = styled.div`
	width: '100%';
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(230, 230, 230, 0.3);

	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 10px;
`;
