import { Col, Input, List, Row, Popover } from 'antd';
import { Search } from 'assets/icons';
import Card from 'components/Card/Card';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import FormItem from '../formItem';
import mDefault from 'assets/images/Mdefault.png';
import mAudio from 'assets/images/Model_default_s.jpg';
import mImage from 'assets/images/Model_default_m.jpg';
import mTimeseries from 'assets/images/Model_default_l.jpg';
import OriginalHeightImage from 'components/etc/OriginalHeightImage';

const ModelSubtypeForm = ({
	formProps: {
		control,
		formState: { errors },
	},
	selectedType,
	select,
	subtypes,
}) => {
	const [selected, setSelected] = useState(select ? select : null);
	const [DATA, setData] = useState([]);

	useEffect(() => {
		setData(subtypes.filter((item) => item.type === selectedType));
	}, []);

	const onSearch = (e) => {
		const val = e.target.value.toLowerCase();
		const searchData = subtypes.filter((x) => x.subTypeName.toLowerCase().includes(val));

		setData(searchData);
	};

	const renderPopoverContent = (text) => {
		return <div>{text}</div>;
	};

	const getDefaultImg = (type) => {
		switch (type) {
			case 1:
				return mImage;
			case 2:
				return mAudio;
			case 3:
				return mTimeseries;
			default:
				return mDefault;
		}
	};

	return (
		<>
			<Row align="middle" justify="space-between">
				<Col>
					Results: <span style={{ fontWeight: 'bold' }}>{DATA.length}</span> sub-types
				</Col>
				<Col>
					<StyledInput onChange={onSearch} />
				</Col>
			</Row>
			<FormItem error={errors?.modelSubType?.message}>
				<Controller
					name="modelSubType"
					control={control}
					render={({ field }) => (
						<Row gutter={16}>
							{DATA.map((item, index) => (
								<Col xs={24} lg={12} key={`subType_${index}`}>
									<List.Item style={{ display: 'flex', height: '100%' }}>
										<OptionsCard
											active={item.id === selected ? true : false}
											onClick={() => {
												setSelected(item.id);
												field.onChange(item.id);
											}}
										>
											<Radio>
												<InnerRadio />
											</Radio>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'center',
													paddingTop: 10,
												}}
											>
												<Popover
													content={renderPopoverContent(item.popUp)}
													visible={(item.popUp === '' || !item.popUp) && false}
												>
													<OriginalHeightImage
														src={item.image || getDefaultImg(selectedType)}
														fallback={getDefaultImg(selectedType)}
														preview={false}
														style={{
															marginBottom: '10px',
														}}
													/>
												</Popover>
											</div>
											<div style={{ padding: '10px 40px' }}>
												<OptionTitle>{item.subTypeName}</OptionTitle>
												<OptionDescrib>{item.description}</OptionDescrib>
											</div>
										</OptionsCard>
									</List.Item>
								</Col>
							))}
						</Row>
					)}
				/>
			</FormItem>
		</>
	);
};

export default ModelSubtypeForm;

const Radio = styled.div`
	width: 19px;
	height: 19px;
	border-radius: 12px;
	border: solid 1px #d8d8d8;
	background-color: #fff;

	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: 25px;
	left: 20px;

	z-index: 999;
`;
const InnerRadio = styled.div`
	width: 11px;
	height: 11px;
	border-radius: 7px;
`;

const OptionsCard = styled(Card)`
	cursor: pointer;
	justify-content: start;
	padding: 0;
	height: 100%;
	overflow: hidden;

	${({ active }) =>
		active &&
		`
		border: 1px solid #ffaf02;
		background-color: #ffaf021a;

		${Radio} {
			border: 1px solid #ffaf02;
		}
		${InnerRadio} {
			background-color: #ffaf02;
		}
	`}
`;
const OptionTitle = styled.div`
	font-size: 14px;
	font-weight: 600;
	color: #4d4d4d;
`;
const OptionDescrib = styled.div`
	font-size: 12px;
	font-weight: 300;
	color: #4d4d4d;
`;
const StyledInput = styled(Input).attrs({
	placeholder: 'Search',
	prefix: <Search />,
})`
	border-radius: 4px;
	margin-bottom: 10px;

	input {
		border: 0 !important;

		&:focus {
			border: 0 !important;
		}
	}
`;
