import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/close_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<polygon
				id="Combined-Shape"
				fill="currentColor"
				points="19 6.40999998 17.59 4.99999997 12 10.59 6.40999998 4.99999997 4.99999997 6.40999998 10.59 12 4.99999997 17.59 6.40999998 19 12 13.41 17.59 19 19 17.59 13.41 12"
			></polygon>
		</g>
	</svg>
);

const Close = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Close;
