import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const { Text } = Typography;

const LabelDivider = ({ children, label }) => {
	const length = label?.length - (label?.match(/i/g)?.length * 0.5 || 0);
	return (
		<DividerContainer haveChildren={!!!children} length={length}>
			{[
				<DividerLabel key={`divider-${label}`}>{label}</DividerLabel>,
				!children ? !label ? <DividerLine key="divider-line" length={length} /> : null : children,
			]}
		</DividerContainer>
	);
};

LabelDivider.propTypes = {
	label: PropTypes.string,
};

LabelDivider.defaultProps = {
	label: 'label',
};

export default LabelDivider;

const DividerContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: ${(props) => (props.haveChildren ? (props.length !== 0 ? '18px' : '8px') : 0)};
	//margin-bottom: ${(props) => (props.haveChildren ? (props.length !== 0 ? '18px' : '8px') : 0)};
	position: relative;
`;
const DividerLabel = styled(Text)`
	padding: 1px 0 0 11px;
	font-size: 12px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 1px;
	color: #808080;
`;

const DividerLine = styled.li`
	transform: scaleY(0.95);
	height: 0.5px;
	display: inline-box;
	width: ${(props) => (props.length === 0 ? '100%' : `calc(90% - ${props.length * 9}px)`)};
	position: absolute;
	right: 0;
	margin: 1px 0;
	padding: 0;
	overflow: hidden;
	line-height: 0;
	background-color: rgba(207, 218, 230, 0.5);
`;
