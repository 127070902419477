import { Button, Image, Skeleton } from 'antd';
import Linkexpired from 'assets/images/Linkexpired.png';
import PublicContainer from 'components/Layouts/PublicContainer';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { activateEmail, resendActivateEmail } from 'reduxStore/actions/authAction';
import styled from 'styled-components';

const ActivateEmailPage = ({ loading, isActivate, activateEmail, resendActivateEmail, email, username, ...props }) => {
	let params = queryString.parse(props.location.search);

	useEffect(() => {
		activateEmail(params.token);
	}, []);

	if (loading) {
		return <Skeleton active />;
	}

	return (
		<PublicContainer>
			{isActivate === true && (
				<ParentContainer>
					<div style={{ margin: '10px 0px' }}>
						<Label>Successfully Verified!</Label>
						<SubLabel>You have successfully verified account</SubLabel>
						<UserAndE>
							Username: <ValueUserAndE>{username || 'Daisy2021'}</ValueUserAndE>
						</UserAndE>
						<UserAndE>
							Email: <ValueUserAndE>{email || 'Daisy2021@gmail.com'}</ValueUserAndE>
						</UserAndE>
					</div>

					<Link to={'/login'}>
						<Button type="primary" style={{ width: '100%' }}>
							Log in to Daysie
						</Button>
					</Link>
				</ParentContainer>
			)}

			{isActivate === false && (
				<ParentContainer>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<Image src={Linkexpired} preview={false} width="76px" />
					</div>
					<div style={{ margin: '10px 0px', textAlign: 'center' }}>
						<Label>Link Expired!</Label>
						<SubLabel>The link you trying to acces is no longer available.</SubLabel>

						<Link to={'/login'}>
							<Button type="primary" style={{ marginTop: '10px' }}>
								Back to Log in
							</Button>
						</Link>
					</div>
				</ParentContainer>
			)}
		</PublicContainer>
	);
};

const mapStateToProps = ({ authReducer }) => ({
	isActivate: authReducer.isActivate,
	loading: authReducer.loading,
	username: authReducer.atvUsername,
	email: authReducer.atvEmail,
});

const mapDispatchToProps = {
	activateEmail: activateEmail.request,
	resendActivateEmail: resendActivateEmail.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateEmailPage);

const Label = styled.div`
	font-size: 28px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.44px;
	color: #333333;
`;

const SubLabel = styled.div`
	font-size: 14px;
	font-weight: 300;
	line-height: normal;
	letter-spacing: normal;
	color: #808080;
`;
const ParentContainer = styled.div`
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	max-width: 380px;

	@media only screen and (max-width: 768px) {
		width: 90%;
	}
`;

const UserAndE = styled.div`
	font-size: 14px;
	font-weight: 300;
	color: #4d4d4d;
`;

const ValueUserAndE = styled.span`
	font-size: 16px;
	font-weight: 500;
	color: #4d4d4d;
`;
