import { push } from 'connected-react-router';
import jwt_decode from 'jwt-decode';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import cookie from 'util/cookie';
import AuthAPI from '../../util/axios/authApi';
import UserAPI from '../../util/axios/userApi';
import * as actions from '../actions/authAction';

export function* appInitialize() {
	const token = yield select(({ authReducer }) => authReducer.token);
	if (token) {
		yield put({ type: actions.AUTHENTICATED });
	} else {
		yield put({ type: actions.UNAUTHENTICATED });
	}
}

function* login(action) {
	try {
		const { username, password } = action.payload.data;
		const path = `/auth/signin`;
		const res = yield call(AuthAPI.post, path, {
			username: username,
			password: password,
		});

		cookie.set('accessToken', res.data.accessToken);
		cookie.set('userId', res.data.id);
		yield put(actions.login.success(res.data));
		localStorage.setItem('activate_email', '');
		localStorage.setItem('confirm_type', '');
		// yield put({ type: actions.AUTHENTICATED });
		yield put(push('/p'));
	} catch (error) {
		if(error.response){
			if (error && error.response.status === 403) {
				localStorage.setItem('activate_email', error.response?.data.email);
				localStorage.setItem('confirm_type', 'login');
				yield put(push('/confirmaccount'));
			} else {
				yield put(actions.login.failure(error?.response?.data || error));
			}
		}else{
			yield put(actions.login.failure({message: 'something wrong'}));
		}	
		
	}
}

function* signOut() {
	yield put({ type: actions.UNAUTHENTICATED });
	cookie.remove('accessToken');
	cookie.remove('userId');
	yield put(push('/login'));
}

function* setuppassword(action) {
	try {
		const { email, password, token } = action.payload;
		const path = `/auth/resetpassword`;
		const res = yield call(AuthAPI.post, path, {
			email,
			newPassword: password,
			resetEmailToken: token,
		});

		yield put(actions.setuppassword.success(res.data));
		yield put(push('/login'));

		if (res.errors) throw res.errors;
	} catch (error) {
		if(error.response){
			yield put(actions.setuppassword.failure(error.response.data || error));
		}else{
			yield put(actions.setuppassword.failure({message: 'something wrong'}));
		}
	}
}

function* resetPassword(action) {
	try {
		const { email } = action.payload;
		const path = `/auth/forgetpassword`;
		const res = yield call(AuthAPI.post, path, email);

		yield put(actions.resetPassword.success(res.data));
		yield put(push('/login'));

		if (res.errors) throw res.errors;
	} catch (error) {
		if(error.response){
			yield put(actions.resetPassword.failure(error.response.data || error));
		}else{
			yield put(actions.resetPassword.failure({message: 'something wrong'}));
		}
	}
}

function* changepassword(action) {
	try {
		const { user } = action.payload;
		const path = `/user/changepassword`;
		const res = yield call(UserAPI.post, path, {
			username: user.username,
			currentPassword: user.currentpassword,
			newPassword: user.newpassword,
		});

		yield put(actions.changepassword.success(res.data));

		if (res.errors) throw res.errors;
	} catch (error) {
		if(error.response){
			yield put(actions.changepassword.failure(error.response.data || error));
		}else{
			yield put(actions.changepassword.failure({message: 'something wrong'}));
		}
	}
}

function* register(action) {
	try {
		const { user } = action.payload;
		const data = {
			username: user.username,
			email: user.email,
			name: user.name,
			password: user.password,
		};
		const path = `/auth/signup`;
		const res = yield call(AuthAPI.post, path, data);

		yield put(actions.register.success(res.data));
		localStorage.setItem('activate_email', user.email);
		localStorage.setItem('confirm_type', 'register');

		yield put(push('/confirmaccount'));

		if (res.errors) throw res.errors;
	} catch (error) {
		if(error.response){
			yield put(actions.register.failure(error.response.data || error));
		}else{
			yield put(actions.register.failure({message: 'something wrong'}));
		}
	}
}

function* getProfile(action) {
	try {
		const { userid } = action.payload;

		const path = `/user/getprofile`;

		const res = yield call(UserAPI.post, path, {
			_id: userid,
		});

		yield put(actions.getProfile.success({ ...res.data, userid }));
		if (res.errors) throw res.errors;
	} catch (error) {
		if(error.response){
			yield put(actions.getProfile.failure(error.response.data || error));
		}else{
			yield put(actions.getProfile.failure({message: 'something wrong'}));
		}
	}
}

function* updateProfile(action) {
	try {
		const { user } = action.payload;

		const path = `/user/updateprofile`;

		const res = yield call(UserAPI.post, path, {
			username: user.username,
			name: user.name,
		});

		if (res.data) {
			const path = `/user/getprofile`;
			const resGet = yield call(UserAPI.post, path, {
				_id: user.userid,
			});
			yield put(actions.updateProfile.success({ ...resGet.data, userid: user.userid }));
		}

		if (res.errors) throw res.errors;
	} catch (error) {
		if(error.response){
			yield put(actions.updateProfile.failure(error.response.data || error));
		}else{
			yield put(actions.updateProfile.failure({message: 'something wrong'}));
		}
	}
}

function* resendActivateEmail(action) {
	try {
		const { email } = action.payload;

		const path = `/auth/reactivate`;

		const res = yield call(UserAPI.post, path, {
			email,
		});

		yield put(actions.resendActivateEmail.success(res.data));

		if (res.errors) throw res.errors;
	} catch (error) {
		if(error.response){
			yield put(actions.resendActivateEmail.failure(error.response.data || error));
		}else{
			yield put(actions.resendActivateEmail.failure({message: 'something wrong'}));
		}
	}
}

function* activateEmail(action) {
	try {
		const { token } = action.payload;
		const decodedToken = jwt_decode(token);

		const path = `/auth/verifyemail`;

		const res = yield call(AuthAPI.post, path, {
			token,
		});

		yield put(
			actions.activateEmail.success({
				email: decodedToken.email || null,
				username: decodedToken.username || null,
				data: res.data,
			})
		);
		if (res.errors) throw res.errors;
	} catch (error) {
		if(error.response){
			yield put(actions.activateEmail.failure(error.response.data || error));
		}else{
			yield put(actions.activateEmail.failure({message: 'something wrong'}));
		}
	}
}

function* readToken(action) {
	try {
		const { token } = action.payload;
		const decodedToken = jwt_decode(token);

		yield put(actions.readToken.success(decodedToken));
	} catch (error) {
		if(error.response){
			yield put(actions.readToken.failure(error));
		}else{
			yield put(actions.readToken.failure({message: 'something wrong'}));
		}
	}
}

export default function* watchAuth() {
	yield all([
		takeLatest(actions.LOGIN.REQUEST, login),
		takeLatest(actions.RESEND_ACTIVATE.REQUEST, resendActivateEmail),
		takeEvery(actions.SIGN_OUT_REQUEST, signOut),
		takeLatest(actions.SETUPPASSWORD.REQUEST, setuppassword),
		takeLatest(actions.RESET_PASSWORD.REQUEST, resetPassword),
		takeLatest(actions.CHANGE_PASSWORD.REQUEST, changepassword),
		takeLatest(actions.GET_PROFILE.REQUEST, getProfile),
		takeLatest(actions.UPDATE_PROFILE.REQUEST, updateProfile),
		takeLatest(actions.REGISTER.REQUEST, register),
		takeLatest(actions.ACTIVATE_EMAIL.REQUEST, activateEmail),
		takeLatest(actions.READ_TOKEN.REQUEST, readToken),
	]);
}
