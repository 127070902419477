import {
	LOAD_DEVICES,
	LOAD_DEVICE,
	CREATE_DEVICE,
	UPDATE_DEVICE,
	DELETE_DEVICE,
	UNLINK_DEVICE,
	LOAD_RDEVICES,
	LINK_DEVICE,
} from '../actions/deviceAction';
import { shapeMessage } from 'util/openNotification';

const initState = {
	devices: [],
	device: {},
	rDevices: [],
	loading: false,
	crudLoading: false,
	linkLoading: false,
	message: '',
};

const deviceReducer = (state = initState, action) => {
	switch (action.type) {
		// ----- LOAD_DEVICES -----
		case LOAD_DEVICES.REQUEST:
			return {
				...state,
				loading: true,
				message: '',
			};
		case LOAD_DEVICES.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				loading: false,
			};
		case LOAD_DEVICES.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		//  ----- LOAD RECIPE DEVICE -----
		case LOAD_RDEVICES.SUCCESS:
			return {
				...state,
				rDevices: action.payload.rDevices,
				loading: false,
			};

		// ----- LOAD_DEVICE -----
		case LOAD_DEVICE.REQUEST:
			return {
				...state,
				message: '',
				loading: true,
			};
		case LOAD_DEVICE.SUCCESS:
			return {
				...state,
				device: action.payload.device,
				loading: false,
			};
		case LOAD_DEVICE.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- CREATE_DEVICE -----
		case CREATE_DEVICE.REQUEST:
			return {
				...state,
				message: '',
				crudLoading: true,
			};
		case CREATE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				crudLoading: false,
				message: shapeMessage(action.type),
			};
		case CREATE_DEVICE.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- UPDATE_DEVICE -----
		case UPDATE_DEVICE.REQUEST:
			return {
				...state,
				message: '',
				crudLoading: true,
			};
		case UPDATE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				crudLoading: false,
				message: shapeMessage(action.type),
			};
		case UPDATE_DEVICE.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- DELETE_DEVICE -----
		case DELETE_DEVICE.REQUEST:
			return {
				...state,
				message: '',
				crudLoading: true,
			};
		case DELETE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				crudLoading: false,
				message: shapeMessage(action.type),
			};
		case DELETE_DEVICE.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- UNLINK_DEVICE -----
		case UNLINK_DEVICE.REQUEST:
		case LINK_DEVICE.REQUEST:
			return {
				...state,
				linkLoading: true,
			};
		case UNLINK_DEVICE.SUCCESS:
		case LINK_DEVICE.SUCCESS:
			return {
				...state,
				rDevices: action.payload.rdevices,
				linkLoading: false,
				message: shapeMessage(action.type),
			};
		case UNLINK_DEVICE.FAILURE:
		case LINK_DEVICE.FAILURE:
			return {
				...state,
				linkLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- DEFAULT -----
		default:
			return state;
	}
};

export default deviceReducer;
