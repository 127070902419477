import React, { useState, useEffect } from 'react';
import { Space, Form, Button } from 'antd';
import BaseModal from './baseModal';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { EdgeInfoForm, LocalConfigForm } from '../forms/edge';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AlertModal from './AlertModal';
import { useHistory } from 'react-router-dom';

// ----- schema -----
// edge info
const infoSchema = yup.object().shape({
	name: yup.string().required('Name is a required field').max(50, 'Name must be at most 50 characters'),
	description: yup.string().max(200, 'Description must be at most 200 characters'),
});
// local config
const localConfigSchema = yup.object().shape({
	edgeClientsNo: yup.number().required('This field is required').nullable(true),
	hardware: yup.string().required('This field is required'),
});

// ----- custom step dot -----
const customDot = (current, index) => {
	const status = current < index ? 'waiting' : current === index ? 'process' : current > index ? 'finish' : 'error';
	return (
		<Step status={status} key={index}>
			<InnerStep />
		</Step>
	);
};

const AddEdgeModal = ({ handleCancel, handleSubmit, loading, visible, models, ...rest }) => {
	const [DATA, setData] = useState();
	const [saveModal, setSaveModal] = useState(false);
	const [current, setCurrent] = useState(0);
	const history = useHistory();

	const infoForm = useForm({ mode: 'onTouched', resolver: yupResolver(infoSchema) });
	const localForm = useForm({ mode: 'onTouched', resolver: yupResolver(localConfigSchema) });

	const steps = [
		{
			title: 'Recipe info',
			content: <EdgeInfoForm formProps={infoForm} />,
		},
		{
			title: 'Local Configs',
			content: <LocalConfigForm formProps={localForm} models={models} onBuildModel={() => setSaveModal(true)} />,
		},
	];

	useEffect(() => {
		if (visible === false) {
			setCurrent(0);
			infoForm.reset();
			localForm.reset();
			setData(null);
		}
	}, [visible]);

	// ----- Func. -----
	const next = () => {
		setCurrent(current + 1);
	};
	const prev = () => {
		setCurrent(current - 1);
	};
	const onSubmit = (data) => {
		setData({ ...DATA, ...data });

		if (current === steps.length - 1) {
			handleSubmit({ ...DATA, ...data }, saveModal);
		} else next();
	};

	// ----- render -----
	return (
		<>
			<BaseModal
				title="Create Recipe"
				onCancel={handleCancel}
				visible={visible}
				footer={[
					<FooterContainer key="create-edge-footer">
						{current === 0 && <Button onClick={handleCancel}>Cancel</Button>}
						{current > 0 && (
							<Button onClick={() => prev()}>
								<Space align="center">
									<LeftOutlined /> Previous
								</Space>
							</Button>
						)}
						<Space direction="vertical" align="center" size={1}>
							<Title style={{ margin: 0 }}>STEP :{current + 1}of 2</Title>
							<Space>{steps.map((_, index) => customDot(current, index))}</Space>
						</Space>

						{current < steps.length - 1 && (
							<Button type="primary" onClick={infoForm.handleSubmit(onSubmit)}>
								<Space align="center">
									Next <RightOutlined />
								</Space>
							</Button>
						)}
						{current === steps.length - 1 && (
							<Button type="primary" onClick={localForm.handleSubmit(onSubmit)} loading={loading}>
								Create recipe
							</Button>
						)}
					</FooterContainer>,
				]}
				{...rest}
			>
				<Title>
					STEP {current + 1}: <span>{steps[current].title}</span>
				</Title>
				<Form layout="vertical">{steps[current].content}</Form>
			</BaseModal>
			<AlertModal
				alertType="draft"
				item={{ ...DATA }}
				visible={saveModal}
				loading={loading}
				handleClose={() => setSaveModal(false)}
				handleCancel={() => history.push('/m/createmodel')}
				handleOk={() => {
					localForm.handleSubmit(onSubmit)();
					//! have to wait for response
					// history.push('/m/createmodel');
				}}
			/>
		</>
	);
};

export default AddEdgeModal;

// ----- style -----
const FooterContainer = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;
const InnerStep = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 8px;
`;
const Step = styled.div`
	width: 14px;
	height: 14px;
	border-radius: 12px;
	border: solid 1px #d8d8d8;

	display: flex;
	align-items: center;
	justify-content: center;

	${({ status }) =>
		status === 'process' &&
		`
      border: solid 1px #ffaf02; 
      ${InnerStep} {
        background-color: #ffaf02
      };
    `}

	${({ status }) =>
		status === 'finish' &&
		`
      border: solid 1px #ffaf02;
      background-color: #ffaf02;
   `}
`;

const Title = styled(Space)`
	font-size: 14px;
	font-weight: 500;
	color: #b3b3b3;
	margin: 0 0 10px 0;

	span {
		font-weight: 600;
		color: #4d4d4d;
	}
`;
