import { notification } from 'antd';
import AlertIcon from 'components/etc/AlertIcon';
import { lowerCase, upperFirst } from 'lodash';
import { CheckOutlined, CloseOutlined, ExclamationOutlined } from '@ant-design/icons';

const NotiStyle = {
	paddingBottom: '20px',
	borderRadius: '4px',
	boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
	backgroundColor: '#ffffff',
};

const shapeMessage = (actionType, description) => {

	const tempType = actionType.split('_');
	const type = tempType[tempType.length - 1] === 'SUCCESS' ? 'success' : 'error';
	const MESSAGE = Array.isArray(description) ? description[0]?.message : description?.message;
	return {
		type,
		message: lowerCase(`${tempType[1]}_${tempType[0]}_${tempType[2]}`),
		description: upperFirst(lowerCase(MESSAGE)),
	};
};

const openNotification = (msg, onClose) => {
	try {
		const { type, message, description } = msg;
		switch (type) {
			case 'success':
				notification[type]({
					message: message.toUpperCase(),
					description: description ? `${description}.` : '',
					duration: 3,
					icon: <AlertIcon icon={<CheckOutlined />} color="#52c41a" />,
					style: {
						...NotiStyle,
					},
					onClose: onClose ? onClose : null,
				});
				break;
			case 'error':
				notification[type]({
					message: message.toUpperCase(),
					description: description ? `${description}.` : 'Something went wrong. Try again.',
					duration: 3,
					icon: <AlertIcon icon={<CloseOutlined />} color="#f64e60" />,
					style: {
						...NotiStyle,
						// borderLeft: `6px solid ${color.danger}`
					},
					onClose: onClose ? onClose : null,
				});
				break;
			case 'warning':
				notification[type]({
					message: message.toUpperCase(),
					description : description ? `${description}.` : '',
					duration: 3,
					icon: <AlertIcon icon={<ExclamationOutlined />} color="#ffaf02" />,
					style: {
						...NotiStyle,
					},
					onClose: onClose ? onClose : null,
				});
				break;
			default:
				break;
		}
	} catch (e) {
		return;
	}
};

export { shapeMessage, openNotification };
