import { shapeMessage } from 'util/openNotification';
import {
	LOAD_MODELS,
	LOAD_MODEL,
	LOAD_TYPE,
	LOAD_SUBTYPE,
	CREATE_MODEL,
	UPDATE_MODEL,
	CONFIG_MODEL,
	TRAIN_MODEL,
	CANCEL_QUEUE,
	DELETE_MODEL,
	PREVIEW_MODEL,
	PREDICT_VALUE,
	CLEAR_PREDICT_RESULT,
	CLEAR_PREDICT_CONFIG,
	UPLOAD_TRAINING_FILE,
} from '../actions/modelAction';

const initState = {
	loading: false,
	crudLoading: false,
	models: [],
	model: null,
	preview: null,
	types: [],
	subtypes: [],
	message: '',
	result: null,
	predicting: false,
	trainStatus: false,
	uploading: false,
	uploadSuccess: false,
	uploadError: null,
	uploadData: null,
};

const modelReducer = (state = initState, action) => {
	switch (action.type) {
		// ----- load models -----
		case LOAD_MODELS.REQUEST:
			return {
				...state,
				message: '',
				loading: true,
			};
		case LOAD_MODELS.SUCCESS:
			return {
				...state,
				loading: false,
				models: action.payload.models,
			};
		case LOAD_MODELS.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		// ----- load model -----
		case LOAD_MODEL.REQUEST:
			return {
				...state,
				message: '',
				loading: true,
			};
		case LOAD_MODEL.SUCCESS:
			return {
				...state,
				loading: false,
				model: action.payload.model,
			};
		case LOAD_MODEL.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		// ----- LOAD_TYPE -----
		case LOAD_TYPE.REQUEST:
			return {
				...state,
				message: '',
				loading: true,
			};
		case LOAD_TYPE.SUCCESS:
			return {
				...state,
				loading: false,
				types: action.payload.types,
			};
		case LOAD_TYPE.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		// ----- LOAD_SUBTYPE -----
		case LOAD_SUBTYPE.REQUEST:
			return {
				...state,
				message: '',
				loading: true,
			};
		case LOAD_SUBTYPE.SUCCESS:
			return {
				...state,
				loading: false,
				subtypes: action.payload.subtypes,
			};
		case LOAD_SUBTYPE.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- CRUD -----
		case CREATE_MODEL.REQUEST:
		case UPDATE_MODEL.REQUEST:
		case CONFIG_MODEL.REQUEST:
		case DELETE_MODEL.REQUEST:
			return {
				...state,
				message: '',
				crudLoading: true,
			};
		case CREATE_MODEL.SUCCESS:
		case UPDATE_MODEL.SUCCESS:
		case CONFIG_MODEL.SUCCESS:
		case DELETE_MODEL.SUCCESS:
			return {
				...state,
				crudLoading: false,
				model: action.payload?.model,
				models: action.payload.models,
				message: shapeMessage(action.type),
			};
		case CREATE_MODEL.FAILURE:
		case UPDATE_MODEL.FAILURE:
		case CONFIG_MODEL.FAILURE:
		case DELETE_MODEL.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- load preview -----
		case PREVIEW_MODEL.REQUEST:
			return {
				...state,
				loading: true,
			};
		case PREVIEW_MODEL.SUCCESS:
			return {
				...state,
				loading: false,
				preview: action.payload.preview,
			};
		case PREVIEW_MODEL.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		// ----- predict -----
		case PREDICT_VALUE.REQUEST:
			return {
				...state,
				predicting: true,
			};
		case PREDICT_VALUE.SUCCESS:
			return {
				...state,
				predicting: false,
				result: action.payload.result,
			};
		case PREDICT_VALUE.FAILURE:
			return {
				...state,
				predicting: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		// ----- cancel queue -----
		case CANCEL_QUEUE.REQUEST:
			return {
				...state,
				crudLoading: true,
				message: '',
			};
		case CANCEL_QUEUE.SUCCESS:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type),
			};
		case CANCEL_QUEUE.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		case CLEAR_PREDICT_RESULT:
			return {
				...state,
				result: null,
			};
		case CLEAR_PREDICT_CONFIG:
			return {
				...state,
				preview: null,
			};
		case TRAIN_MODEL.REQUEST:
			return {
				...state,
				trainStatus: false,
				message: '',
			};
		case TRAIN_MODEL.SUCCESS:
			return {
				...state,
				trainStatus: true,
			};
		case TRAIN_MODEL.FAILURE:
			return {
				...state,
				trainStatus: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- UPLOAD_TRAINING_FILE -----
		case UPLOAD_TRAINING_FILE.REQUEST:
			return {
				...state,
				uploading: true,
				uploadSuccess: false,
				uploadError: null,
			};
		case UPLOAD_TRAINING_FILE.SUCCESS:
			return {
				...state,
				uploading: false,
				uploadSuccess: true,
				uploadData: action.payload,
			};
		case UPLOAD_TRAINING_FILE.FAILURE:
			return {
				...state,
				uploading: false,
				uploadSuccess: false,
				uploadError: action.payload,
			};

		default:
			return state;
	}
};

export default modelReducer;
