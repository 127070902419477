import React, { useMemo } from 'react';
import { Button, Typography, Dropdown, Menu, Row } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Card from './Card';
import StatusCard from './StatusCard';
import { Close, Construction, Delete, Edit, Ellipsis, PlaySquare } from 'assets/icons';

const ModelListCard = ({ onClickConfig, onClickPreview, onClickEdit, onCancelQueue, onClickDelete, item }) => {
	const timeFormat = (time) => dayjs(+time).format('D MMMM YYYY h:mm a').toUpperCase();

	const getStatus = useMemo(() => {
		const statusMap = {
			0: { status: 'draft', label: `Created date: ${timeFormat(item.createdtime)}` },
			1: { status: 'ready', label: `Finished: ${timeFormat(item.updatetime)}` },
			2: { status: 'uploading', label: 'Uploading data' },
			3: { status: 'queuing', label: `${item.queueNumber} Waiting for training` },
			4: { status: 'upload-failed', label: `Upload failed: ${timeFormat(item.updatetime)}` },
			5: { status: 'train-failed', label: `Training failed: ${timeFormat(item.updatetime)}` },
			6: { status: 'training', label: 'Training to complete' },
		};
		return statusMap[item.status] || {};
	}, [item]);

	const menu = (
		<StyledMenu>
			{(item.status === 3 || item.status === 6) && (
				<>
					<Menu.Item style={{ color: '#f64e60' }} danger icon={<Close />} onClick={onCancelQueue}>
						Cancel {item.status === 6 ? 'Training' : 'Waiting'}
					</Menu.Item>
					<Menu.Divider />
				</>
			)}
			{item.status !== 3 && item.status !== 6 && (
				<>
					<Menu.Item icon={<Construction />} onClick={onClickConfig}>
						Configure / Train
					</Menu.Item>
					{item.status === 1 && item.modeltype !== 3 && (
						<Menu.Item icon={<PlaySquare />} onClick={onClickPreview}>
							Test
						</Menu.Item>
					)}
					<Menu.Item icon={<Edit />} onClick={onClickEdit}>
						Edit
					</Menu.Item>
					<Menu.Divider />
				</>
			)}
			<Menu.Item danger onClick={onClickDelete} icon={<Delete />}>
				Delete
			</Menu.Item>
		</StyledMenu>
	);

	return (
		<CardWrapper>
			<Card>
				<TopCard>
					<StatusCard status={getStatus.status} />
					<Dropdown overlay={menu} trigger={['click']} placement="bottomRight" disabled={item.status === 2}>
						<Button type="text" icon={<Ellipsis />} />
					</Dropdown>
				</TopCard>
				<Name>{item.name}</Name>
				<Description ellipsis={{ rows: 2 }}>{item.description}</Description>
				<Row align="bottom" justify="space-between">
					<Label>{getStatus.label}</Label>
					{item.status === 1 && (
						<Accuracy>
							<Label>
								Accuracy: <b>{item.accuracy}%</b>
							</Label>
						</Accuracy>
					)}
				</Row>
			</Card>
		</CardWrapper>
	);
};

export default ModelListCard;

const CardWrapper = styled.div`
	height: 100%;
`;

const Name = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: #333333;
	max-width: 288px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Description = styled(Typography.Paragraph)`
	margin-top: 5px;
	font-size: 14px;
	font-weight: 300;
	color: #4d4d4d;
	min-height: 42px;
`;

const TopCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Label = styled.span`
	font-size: 12px;
	color: #808080;
`;

const Accuracy = styled.div`
	background: lightyellow;
	padding: 0 5px;
	border-radius: 10px;
`;

const StyledMenu = styled(Menu)`
	.ant-dropdown-menu-item,
	.ant-dropdown-menu-submenu-title {
		font-size: 14px;
		padding-left: 20px;
	}
	min-width: 180px;
	padding: 8px 0;
	border-radius: 5px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	background-color: #ffffff;
	.ant-dropdown-menu-item-divider,
	.ant-dropdown-menu-submenu-title-divider {
		background-color: rgba(207, 218, 230, 0.5);
	}
	.ant-dropdown-menu-item:hover,
	.ant-dropdown-menu-submenu-title:hover {
		background-color: #fff8e8;
	}
	.ant-dropdown-menu-item-danger {
		color: #4d4d4d;
		:hover {
			color: #f64e60;
			background-color: rgba(246, 78, 96, 0.14);
		}
	}
`;
