import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import BaseModal from './baseModal';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ProjectForm from 'components/forms/project/ProjectForm';

const ProjectModal = ({ project, visible, handleCancel, onSubmit, loading }) => {
	const infoSchema = yup.object().shape({
		name: yup.string().required().max(50),
		description: yup.string().max(200),
	});

	const infoForm = useForm({ mode: 'onTouched', resolver: yupResolver(infoSchema) });

	useEffect(() => {
		if (visible === true) {
			infoForm.setValue('name', project?.name || '');
			infoForm.setValue('description', project?.description || '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	useEffect(() => {
		visible === false && infoForm.reset();
	}, [visible, infoForm.reset]);

	const onCancel = () => {
		infoForm.reset();
		handleCancel();
	};

	return (
		<BaseModal
			title={project ? 'Edit Project' : 'Create Project'}
			visible={visible}
			onCancel={onCancel}
			footer={[
				<Button onClick={onCancel}>Cancel</Button>,
				<Button type="primary" onClick={infoForm.handleSubmit(onSubmit)} loading={loading}>
					{project ? 'Save' : 'Create'}
				</Button>,
			]}
		>
			<Form layout="vertical" onFinish={infoForm.handleSubmit(onSubmit)}>
				<ProjectForm formProps={infoForm} />
			</Form>
		</BaseModal>
	);
};

export default ProjectModal;
