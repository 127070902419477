import React from 'react';
import { Input } from 'antd';
import { Controller } from 'react-hook-form';
import FormItem from '../formItem';

const ProjectForm = ({
	formProps: {
		control,
		formState: { errors },
	},
}) => {
	return (
		<div>
			<FormItem label="Name" error={errors?.name?.message}>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <Input placeholder="Enter your project name" {...field} />}
				/>
			</FormItem>

			<FormItem label="Description" error={errors?.description?.message}>
				<Controller
					name="description"
					control={control}
					render={({ field }) => (
						<Input.TextArea rows={4} placeholder="Enter your project description" {...field} />
					)}
				/>
			</FormItem>
		</div>
	);
};

export default ProjectForm;
