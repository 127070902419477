import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Form } from 'antd';
import RegisterForm from 'components/forms/auth/RegisterForm';
import PublicContainer from 'components/Layouts/PublicContainer';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { register } from 'reduxStore/actions/authAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';
import * as yup from 'yup';

const registerSchema = yup.object().shape({
	name: yup.string().required('Please enter your name.'),
	email: yup.string().required('Please enter your email.').email('Incorrect email address.'),
	username: yup.string().required('Please enter your username.').min(3, 'Password must be at least 3 characters.'),
	password: yup.string().required('Please enter your password.').min(6, 'Password must be at least 6 characters.'),
	confirmpassword: yup
		.string()
		.required('Please enter your password confimation.')
		.min(6, 'Password must be at least 6 characters.')
		.when('password', (password, field) =>
			password
				? field
						.required('Please enter your password confimation.')
						.oneOf([yup.ref('password'), null], 'Your password and confirmation password does not match.')
						.min(6, 'Password must be at least 6 characters.')
				: field
		),
	agree: yup.bool().required('Please confirm the Privacy Policy and Terms of Service.').oneOf([true], 'Please confirm the Privacy Policy and Terms of Service.')
});

const RegisterPage = ({ register, message }) => {
	const registerProps = useForm({ mode: 'onTouched', resolver: yupResolver(registerSchema) });
	const prevMsg = usePrevious(message);

	// func
	const onRegister = (form) => {
		register(form);
	};
	const onEnter = (e) => {
		if (e.code === 'Enter') registerProps.handleSubmit(onRegister)();
	};

	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			if (message && message.description === 'Failed username is already in use') {
				registerProps.setError('username', { message: message.description });
			} else if (message && message.description === 'Failed email is already in use') {
				registerProps.setError('email', { message: message.description });
			} else {
				openNotification(message);
			}
		}
	}, [message]);

	return (
		<PublicContainer>
			<ParentContainer>
				<Label>Register</Label>
				<SubLabel>Fill the form below to create a new account.</SubLabel>

				<Form layout="vertical" onKeyDown={(e) => onEnter(e)}>
					<RegisterForm formProps={registerProps} />
					<Button block type="primary" onClick={registerProps.handleSubmit(onRegister)}>
						Register
					</Button>
				</Form>
				<StyledDivider />
				<Already>
					Already have an account?{' '}
					<Link to="/login">
						<TextLogin>Login </TextLogin>
					</Link>
				</Already>
			</ParentContainer>
		</PublicContainer>
	);
};
const mapStateToProps = ({ authReducer }) => ({
	message: authReducer.message,
	loading: authReducer.loading,
});

const mapDispatchToProps = { register: register.request };

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);

const Label = styled.div`
	font-size: 28px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.44px;
	color: #333333;
`;

const SubLabel = styled.div`
	margin: 8px 0px 24px 0px;
	font-size: 14px;
	font-weight: 300;
	line-height: normal;
	letter-spacing: normal;
	color: #808080;
`;

const StyledDivider = styled(Divider)`
	border-top: 1px solid #979797;
`;

const Already = styled.span`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #808080;
`;

const TextLogin = styled.span`
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
	letter-spacing: 0.5px;
	text-align: center;
	color: #ffaf02;
`;

const ParentContainer = styled.div`
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	max-width: 380px;
	@media only screen and (max-width: 768px) {
		width: 90%;
	}
`;
