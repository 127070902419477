import { UploadOutlined } from '@ant-design/icons';
import { Image, Row, Space, Upload, Tooltip, Button, notification } from 'antd';
import { CloseFilled, InfoFilled } from 'assets/icons';
import FileSuccess from 'assets/images/Filezip_success@2x.png';
import fileUpload from 'assets/images/file@2x.png';
import FileSelected from 'assets/images/fileicon@2x.png';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import axios from 'axios';
import { loadModel } from 'reduxStore/actions/modelAction';
import { apolloMutation } from 'util/apollo/apollo';
import gql from 'graphql-tag';

const UploadTrainFile = ({ onClickTrain, configUnsave }) => {
	useEffect(() => {
		return () => {
			setSelectedFile(null);
		};
	}, []);

	const { model } = useSelector(({ modelReducer }) => modelReducer);
	const dispatch = useDispatch();

	const [selectedFile, setSelectedFile] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [isError, setIsError] = useState(model?.fileError || false);
	const isUploaded = model?.filename;
	const acceptType = model?.modeltype !== 3 ? '.zip' : '.csv';

	const uploadProps = {
		// general
		name: 'file',
		multiple: false,
		showUploadList: false,
		accept: acceptType,

		// upload
		beforeUpload: (file) => {
			setSelectedFile(file);
			setIsError(false);
			return false;
		},

		// style
		style: {
			border: 0,
			borderRadius: 3,
		},
	};

	const baseUrl = 'https://dock.daysie.io/upload';

	const uploadFile = async () => {
		const modelid = model?.modelid;
		if (!selectedFile) {
			showMessage('Please select a file', 'error');
			return;
		}

		const query = gql`
			mutation uploadFile($modelid: ID!, $filename: String!, $fileID: String!, $mimetype: String!) {
				updateModelGGdrive(modelid: $modelid, filename: $filename, fileID: $fileID, mimetype: $mimetype) {
					modelid
					filename
					fileID
				}
			}
		`;
		const variables = {
			modelid: modelid,
			filename: selectedFile.name,
			fileID: `./${modelid}/train/`,
			mimetype: selectedFile.type,
		};

		const formData = new FormData();
		formData.append('file', selectedFile);

		try {
			setUploading(true);
			const response = await axios.post(`${baseUrl}?modelid=${modelid}`, formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: (progressEvent) => {
					const percent = Math.round((progressEvent.loaded * 99) / progressEvent.total);
					updateProgress(percent);
				},
			});
			const res = await apolloMutation(query, variables);
			if (res.errors) throw res.errors;

			showMessage(response.data.message || 'File uploaded successfully', 'success');
			setSelectedFile(null);
			updateProgress(100);
		} catch (error) {
			showMessage('Error uploading file: ' + error.message, 'error');
			setIsError(true);
		} finally {
			dispatch(loadModel.request(modelid));
			setUploading(false);
		}
	};
	const showMessage = (message, type) => {
		notification[type]({
			message: `Upload training data ${type}`,
			description: message,
			duration: 3,
		});
	};

	const updateProgress = (percent) => {
		setProgress(percent);
	};

	return (
		<>
			<Row align="middle" justify="space-between">
				<Space>
					<Title>Training Data</Title>
					<Tooltip
						title={
							<Space>
								Learn how to prepare your training data files for uploading.
								<Button type="link" size="small">
									Learn more
								</Button>
							</Space>
						}
						placement="right"
						overlayInnerStyle={{ width: 'max-content' }}
					>
						<InfoFilled style={{ color: '#ffaf02', fontSize: 20 }} />
					</Tooltip>
				</Space>

				{/* Action button */}
				<Space>
					{/* select new file */}
					{isUploaded && !selectedFile && (
						<Upload {...uploadProps}>
							<Button>Select new file</Button>
						</Upload>
					)}
					{/* cancel */}
					{selectedFile && <Button onClick={() => setSelectedFile(null)}>Remove selected file</Button>}
					{/* train */}
					{isUploaded && !selectedFile && (
						<Button type="primary" onClick={onClickTrain} disabled={model?.fileError || configUnsave}>
							Start training
						</Button>
					)}
					{/* upload */}
					{selectedFile && (
						<Button type="primary" onClick={uploadFile}>
							Upload
						</Button>
					)}
				</Space>
			</Row>
			<Container isUploaded={isUploaded} selectedFile={selectedFile} error={isError}>
				{/* 5. file upload failed / model.fileError */}
				{model?.fileError !== '' && !selectedFile && !uploading && (
					<Upload.Dragger {...uploadProps}>
						<div className="ant-upload-drag-icon">
							<CloseFilled style={{ fontSize: 30, color: 'red' }} />
						</div>
						<div style={{ fontSize: '16px', fontWeight: 'bold' }}>File error: {model?.fileError}</div>
						<p className="ant-upload-text">Drag and drop a file here, or click to select a new file</p>
						<p className="ant-upload-hint">Maximum file size: 5 MB. Accepted file types: {acceptType}</p>
					</Upload.Dragger>
				)}
				{/* 3. file uploading */}
				{uploading && (
					<ProgressWrapper>
						<Progress>
							<svg viewBox="0 0 100 100" width="100%" height="100%">
								<circle className="progress-circle-bg" cx="50" cy="50" r="45"></circle>
								<circle
									className="progress-circle"
									cx="50"
									cy="50"
									r="45"
									style={{ strokeDashoffset: (1 - progress / 100) * 2 * Math.PI * 45 }}
								></circle>
								<text className="progress-text" x="50" y="50">
									{Math.round(progress)}%
								</text>
							</svg>
						</Progress>
					</ProgressWrapper>
				)}
				{/* 1. upload dragger */}
				{!isUploaded && !selectedFile && (
					<Upload.Dragger {...uploadProps}>
						<p className="ant-upload-drag-icon">
							<UploadOutlined />
						</p>
						<p className="ant-upload-text">Drag and drop a file here, or click to select a file</p>
						<p className="ant-upload-hint">Maximum file size: 5 MB. Accepted file types: {acceptType}</p>
					</Upload.Dragger>
				)}
				{/* 2. file selected but not uploaded */}
				{selectedFile && (
					<Upload.Dragger {...uploadProps}>
						<div className="ant-upload-drag-icon">
							<Image src={FileSelected} width={100} preview={false} />
						</div>
						<div style={{ fontSize: '16px', fontWeight: 'bold' }}>{selectedFile.name}</div>
						<p className="ant-upload-text">Drag and drop a file here, or click to select a new file</p>
						<p className="ant-upload-hint">Maximum file size: 5 MB. Accepted file types: {acceptType}</p>
					</Upload.Dragger>
				)}
				{/* 4. file uploaded */}
				{isUploaded && (
					<Wrapper>
						<Image src={isUploaded ? FileSuccess : fileUpload} width={100} preview={false} />

						<div style={{ fontSize: '16px', fontWeight: 'bold' }}>
							{isUploaded ? model?.filename : 'No file'}
						</div>

						<div style={{ fontSize: '12px' }}>
							Last modified: {dayjs(Number(model.updatetime)).format('MMM DD, YYYY')}
						</div>
					</Wrapper>
				)}
			</Container>
		</>
	);
};

export default UploadTrainFile;

const Container = styled.div`
	margin: 10px 0;
	height: 200px;
	overflow: hidden;
	padding: 5px;

	border: ${({ isUploaded, selectedFile, error }) =>
		!isUploaded || selectedFile ? `1px dashed ${error ? '#f64e60' : '#ffaf02'}` : '1px solid #d8d8d8'};
	border-radius: 8px;
`;
const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 4;
`;
const Title = styled.div`
	font-size: 20px;
	font-weight: 500;
	color: #333333;
`;
const ProgressWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;
const Progress = styled.div`
	width: 150px;
	height: 150px;
	position: relative;
	display: inline-block;

	.progress-circle-bg {
		fill: none;
		stroke: #ddd;
		stroke-width: 10;
	}
	.progress-circle {
		fill: none;
		stroke: #ffbb5c; /* Update to #FFBB5C */
		stroke-width: 10;
		stroke-dasharray: 314;
		transition: stroke-dashoffset 0.3s;
	}
	.progress-text {
		font-size: 20px;
		fill: #ffbb5c; /* Update to #FFBB5C */
		text-anchor: middle;
	}
`;
