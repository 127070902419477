import {
	LOAD_RECIPES,
	LOAD_RECIPE,
	CREATE_RECIPE,
	UPDATE_RECIPE,
	DELETE_RECIPE,
	UPDATE_RECIPE_CONFIG,
	DUPLICATE_RECIPE,
	DOWNLOAD_RECIPE,
} from 'reduxStore/actions/recipeAction';
import { shapeMessage } from 'util/openNotification';
import { IMPORT_FLOW } from '../actions/recipeAction';

const initState = {
	recipes: [],
	recipe: null,
	loading: false,
	crudLoading: false,
	message: '',
	file: null
};

const recipeReducer = (state = initState, action) => {
	switch (action.type) {
		// LOAD_RECIPES
		case LOAD_RECIPES.REQUEST:
		case DOWNLOAD_RECIPE.REQUEST:
		case IMPORT_FLOW.REQUEST:
				return {
				...state,
				loading: true,
				message: '',
				file: null,
			};
		case LOAD_RECIPES.SUCCESS:
			return {
				...state,
				recipes: action.payload.recipes,
				loading: false,
			};
		case LOAD_RECIPES.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// LOAD_RECIPE
		case LOAD_RECIPE.REQUEST:
			return {
				...state,
				loading: true,
				message: '',
			};
		case LOAD_RECIPE.SUCCESS:
			return {
				...state,
				recipe: action.payload.recipe,
				loading: false,
			};
		case LOAD_RECIPE.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// crud
		case CREATE_RECIPE.REQUEST:
		case UPDATE_RECIPE.REQUEST:
		case DELETE_RECIPE.REQUEST:
		case UPDATE_RECIPE_CONFIG.REQUEST:
		case DUPLICATE_RECIPE.REQUEST:
			return {
				...state,
				crudLoading: true,
				message: '',
			};
		case CREATE_RECIPE.SUCCESS:
		case UPDATE_RECIPE.SUCCESS:
		case UPDATE_RECIPE_CONFIG.SUCCESS:
		case DELETE_RECIPE.SUCCESS:
		case DUPLICATE_RECIPE.SUCCESS:
			return {
				...state,
				crudLoading: false,
				recipes: action.payload.recipes,
				message: shapeMessage(action.type),
			};
		case CREATE_RECIPE.FAILURE:
		case UPDATE_RECIPE.FAILURE:
		case UPDATE_RECIPE_CONFIG.FAILURE:
		case DELETE_RECIPE.FAILURE:
		case DUPLICATE_RECIPE.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		case DOWNLOAD_RECIPE.SUCCESS:
			return {
				...state,
				loading: false,
				file: action.payload.file,
			};
		case DOWNLOAD_RECIPE.FAILURE:
		case IMPORT_FLOW.FAILURE:
			return {
				...state,
				loading: false,
				file: null,
				message: shapeMessage(action.type, action.payload.error),
			};
		case IMPORT_FLOW.SUCCESS:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type),
			};
		
		default:
			return state;
	}
};

export default recipeReducer;
