import { createAction, createTypes } from 'util/createActionHelper';

export const AUTHENTICATED = 'AUTHENTICATED';
export const UNAUTHENTICATED = 'UNAUTHENTICATED';
export const APP_INITIALIZED = 'APP_INITIALIZED';
export const SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST';

export const signOut = () => ({ type: SIGN_OUT_REQUEST });

const LOGIN = createTypes('auth', 'login');
const SETUPPASSWORD = createTypes('setup', 'password');
const RESET_PASSWORD = createTypes('reset', 'password');
const CHANGE_PASSWORD = createTypes('change', 'password');
const REGISTER = createTypes('register', 'register');
const GET_PROFILE = createTypes('profile', 'get');
const UPDATE_PROFILE = createTypes('profile', 'update');
const RESEND_ACTIVATE = createTypes('auth', 'resendactivate');
const ACTIVATE_EMAIL = createTypes('auth', 'activateemail');
const READ_TOKEN = createTypes('auth', 'readtoken');

const login = {
	request: (data) => createAction(LOGIN.REQUEST, { data }),
	success: (user) => createAction(LOGIN.SUCCESS, { user }),
	failure: (error) => createAction(LOGIN.FAILURE, { error }),
};

const setuppassword = {
	request: (email, password, token) => createAction(SETUPPASSWORD.REQUEST, { email, password, token }),
	success: (res) => createAction(SETUPPASSWORD.SUCCESS, { res }),
	failure: (error) => createAction(SETUPPASSWORD.FAILURE, { error }),
};

const resetPassword = {
	request: (email) => createAction(RESET_PASSWORD.REQUEST, { email }),
	success: (res) => createAction(RESET_PASSWORD.SUCCESS, { res }),
	failure: (error) => createAction(RESET_PASSWORD.FAILURE, { error }),
};

const changepassword = {
	request: (user) => createAction(CHANGE_PASSWORD.REQUEST, { user }),
	success: (res) => createAction(CHANGE_PASSWORD.SUCCESS, { res }),
	failure: (error) => createAction(CHANGE_PASSWORD.FAILURE, { error }),
};

const register = {
	request: (user) => createAction(REGISTER.REQUEST, { user }),
	success: (res) => createAction(REGISTER.SUCCESS, { res }),
	failure: (error) => createAction(REGISTER.FAILURE, { error }),
};

const getProfile = {
	request: (userid) => createAction(GET_PROFILE.REQUEST, { userid }),
	success: (user) => createAction(GET_PROFILE.SUCCESS, { user }),
	failure: (error) => createAction(GET_PROFILE.FAILURE, { error }),
}

const updateProfile = {
	request: (user) => createAction(UPDATE_PROFILE.REQUEST, { user }),
	success: (user) => createAction(UPDATE_PROFILE.SUCCESS, { user }),
	failure: (error) => createAction(UPDATE_PROFILE.FAILURE, { error }),
}

const resendActivateEmail = {
	request: (email) => createAction(RESEND_ACTIVATE.REQUEST, { email }),
	success: (res) => createAction(RESEND_ACTIVATE.SUCCESS, { res }),
	failure: (error) => createAction(RESEND_ACTIVATE.FAILURE, { error }),
}

const activateEmail = {
	request: (token) => createAction(ACTIVATE_EMAIL.REQUEST, { token }),
	success: (res) => createAction(ACTIVATE_EMAIL.SUCCESS, { res }),
	failure: (error) => createAction(ACTIVATE_EMAIL.FAILURE, { error }),
}

const readToken = {
	request: (token) => createAction(READ_TOKEN.REQUEST, { token }),
	success: (res) => createAction(READ_TOKEN.SUCCESS, { res }),
	failure: (error) => createAction(READ_TOKEN.FAILURE, { error }),
}

export {
	LOGIN,
	login,
	SETUPPASSWORD,
	setuppassword,
	RESET_PASSWORD,
	resetPassword,
	CHANGE_PASSWORD,
	changepassword,
	REGISTER,
    register,
	GET_PROFILE,
	getProfile,
	RESEND_ACTIVATE,
	resendActivateEmail,
	activateEmail,
	ACTIVATE_EMAIL,
	readToken,
	READ_TOKEN,
	UPDATE_PROFILE,
	updateProfile
};
