import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from '../actions/projectAction';
import { QUERY_PROJECTS, QUERY_PROJECT, CREATE_PROJECT, UPDATE_PROJECT, DELETE_PROJECT } from 'util/apollo/gql/project';
import { apolloQuery, apolloMutation } from '../../util/apollo/apollo';
import { QUERY_RECIPES } from 'util/apollo/gql/recipe';

// ----- load projects -----
function* loadProjects(action) {
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECTS));
		if (errors) throw errors;

		yield put(actions.loadProjects.success(data.projects));
	} catch (error) {
		yield put(actions.loadProjects.failure(error));
	}
}

// ----- load projects with recipe -----
function* loadProjectsWithRecipe(action) {
	try {
		const projectsRes = yield call(async () => await apolloQuery(QUERY_PROJECTS));
		if (projectsRes.errors) throw projectsRes.errors;

		let DATA = [];
		yield* projectsRes.data.projects.map(function* (project) {
			const recipeRes = yield call(async () => await apolloQuery(QUERY_RECIPES, { ids: [project.projectid] }));
			DATA.push({ ...project, recipes: recipeRes.data.recipes });
		});

		yield put(actions.loadProjectsWithRecipe.success(DATA));
	} catch (error) {
		yield put(actions.loadProjectsWithRecipe.failure(error));
	}
}

// ----- load project -----
function* loadProject(action) {
	const { projectid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT, { id: projectid }));
		if (errors) throw errors;

		yield put(actions.loadProject.success(data.project));
	} catch (error) {
		yield put(actions.loadProject.failure(error));
	}
}

// ----- create project -----
function* createProject(action) {
	try {
		const { project } = action.payload;

		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_PROJECT, {
					name: project.name,
					description: project.description,
					numberEdge: 3,
				})
		);
		if (mutationRes.errors) throw mutationRes.errors;

		// update redux
		let newProjects = [];
		const prevProjects = yield select(({ projectReducer }) => projectReducer.projects);
		newProjects = [...prevProjects, mutationRes.data.createProject];

		yield put(actions.createProject.success(newProjects));
	} catch (error) {
		yield put(actions.createProject.failure(error));
	}
}

// ----- edit project -----
function* editProject(action) {
	try {
		const { project } = action.payload;

		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_PROJECT, {
					id: project.projectid,
					name: project.name,
					description: project.description,
				})
		);
		if (mutationRes.errors) throw mutationRes.errors;

		// update redux
		let newProjects = [];
		const prevProjects = yield select(({ projectReducer }) => projectReducer.projects);
		newProjects = prevProjects?.map((pj) =>
			pj.projectid === project.projectid ? mutationRes.data.updateProject : pj
		);

		yield put(actions.updateProject.success(newProjects));
	} catch (error) {
		yield put(actions.updateProject.failure(error));
	}
}

// ----- delete project -----
function* deleteProject(action) {
	try {
		const { project } = action.payload;
		const delProject = project;

		const { errors } = yield call(async () => await apolloMutation(DELETE_PROJECT, { id: delProject.projectid }));
		if (errors) throw errors;

		// update redux
		let newProjects = [];
		const prevProjects = yield select(({ projectReducer }) => projectReducer.projects);
		newProjects = prevProjects?.filter((project) => project.projectid !== delProject.projectid);

		yield put(actions.deleteProject.success(newProjects));
	} catch (error) {
		yield put(actions.deleteProject.failure(error));
	}
}

export default function* watchEdge() {
	yield all([
		takeLatest(actions.LOAD_PROJECTS.REQUEST, loadProjects),
		takeLatest(actions.LOAD_PROJECTS_WITH_RECIPE.REQUEST, loadProjectsWithRecipe),
		takeLatest(actions.LOAD_PROJECT.REQUEST, loadProject),

		takeEvery(actions.CREATE_PROJECT.REQUEST, createProject),
		takeEvery(actions.UPDATE_PROJECT.REQUEST, editProject),
		takeEvery(actions.DELETE_PROJECT.REQUEST, deleteProject),
	]);
}
