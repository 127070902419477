import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import PrivateRoute from './components/Router/PrivateRoute';
import PublicRoute from './components/Router/PublicRoute';

import Home from './pages/Home';
import { ProjectPage, DevicePage, EdgePage, ModelPage } from './pages/private';
import CreateModelPage from './pages/private/subPages/CreateModelPage';
import { LoginPage, RegisterPage, ResetPasswordPage } from './pages/public';
// import PublicContainer from 'components/Layouts/PublicContainer';
import ConfigModelPage from 'pages/private/subPages/ConfigModelPage';
import ConfirmAccountPage from 'pages/public/ConfirmAccountPage';
import ResetAccountPage from 'pages/public/ResetAccountPage';
import ActivateEmailPage from 'pages/public/ActivateEmailPage';
import RecipeDevicePage from 'pages/private/RecipeDevicePage';
import NotFoundPage from 'pages/public/NotFoundPage';

class Router extends Component {
	onRouteChanged = () => {
		// this.props.resetSearch();
	};

	componentDidUpdate = (prevProps) => {
		if (this.props.location !== prevProps.location) {
			this.onRouteChanged();
		}
	};

	render() {
		return (
			<Switch>
				<PublicRoute exact path="/login" component={LoginPage} />
				<PublicRoute exact path="/reset" component={ResetPasswordPage} />
				<PublicRoute exact path="/register" component={RegisterPage} />
				<PublicRoute exact path="/confirmaccount" component={ConfirmAccountPage} />
				<PublicRoute exact path="/resetaccount" component={ResetAccountPage} />
				<PublicRoute exact path="/activateemail" component={ActivateEmailPage} />
				<PrivateRoute exact path="/" component={Home} />
				<PrivateRoute exact path="/p" component={ProjectPage} />
				<PrivateRoute exact path="/d" component={DevicePage} />
				<PrivateRoute exact path="/p/:projectid/edge" component={EdgePage} />
				<PrivateRoute exact path="/p/:projectid/edge/:recipeid" component={RecipeDevicePage} />
				<PrivateRoute exact path="/m" component={ModelPage} />
				<PrivateRoute exact path="/m/createmodel" component={CreateModelPage} />
				<PrivateRoute exact path="/m/:modelid/config" component={ConfigModelPage} />
				{/* <PrivateRoute exact path="/document" component={ConfigModelPage} /> */}
				<Route path="*" component={NotFoundPage} />
			</Switch>
		);
	}
}

const mapStateToProps = ({ router }) => ({
	location: router.location,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Router);
