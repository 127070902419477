import { Col, Image, Input, List, Row } from 'antd';
import Card from 'components/Card/Card';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import FormItem from '../formItem';
import mDefault from 'assets/images/Mdefault.png';
import mAudio from 'assets/images/Maudio.png';
import mImage from 'assets/images/Mimage.png';
import mTimeseries from 'assets/images/Mtimeseries.png';
import { Search } from 'assets/icons';

const ModelTypeForm = ({
	formProps: {
		control,
		formState: { errors },
	},
	select,
	types,
}) => {
	const [selected, setSelected] = useState(select ? select : null);
	const [DATA, setData] = useState([]);

	useEffect(() => {
		setData(types);
	}, []);

	const onSearch = (e) => {
		const val = e.target.value.toLowerCase();
		const searchData = types.filter((x) => x.typeName.toLowerCase().includes(val));

		setData(searchData);
	};
	const getDefaultImg = (type) => {
		switch (type) {
			case 1:
				return mImage;
			case 2:
				return mAudio;
			case 3:
				return mTimeseries;
			default:
				return mDefault;
		}
	};
	return (
		<>
			<Row align="middle" justify="space-between">
				<Col>
					Results: <span style={{ fontWeight: 'bold' }}>{DATA.length}</span> types
				</Col>
				<Col>
					<StyledInput onChange={onSearch} />
				</Col>
			</Row>
			<FormItem error={errors?.modelType?.message}>
				<Controller
					name="modelType"
					control={control}
					render={({ field }) => (
						<Row gutter={16}>
							{DATA.map((item, index) => (
								<Col xs={24} sm={12} md={8} xl={6}>
									<List.Item>
										<OptionsCard
											active={item.id === selected ? true : false}
											onClick={() => {
												setSelected(item.id);
												field.onChange(item.id);
											}}
										>
											<Radio>
												<InnerRadio />
											</Radio>
											<Image
												src={item.icon}
												fallback={getDefaultImg(item.id)}
												preview={false}
												width={50}
												style={{ marginBottom: '10px' }}
											/>
											<OptionTitle>{item.typeName}</OptionTitle>
											<OptionDescrib>{item.description}</OptionDescrib>
										</OptionsCard>
									</List.Item>
								</Col>
							))}
						</Row>
					)}
				/>
			</FormItem>
		</>
	);
};

export default ModelTypeForm;

const Radio = styled.div`
	width: 19px;
	height: 19px;
	border-radius: 12px;
	border: solid 1px #d8d8d8;

	display: flex;
	align-items: center;
	justify-content: center;

	position: absolute;
	top: 25px;
	left: 20px;
`;
const InnerRadio = styled.div`
	width: 11px;
	height: 11px;
	border-radius: 7px;
`;
const OptionsCard = styled(Card)`
	cursor: pointer;
	align-items: center;
	padding: 20px 40px;

	${({ active }) =>
		active &&
		`
		border: 1px solid #ffaf02;
		background-color: #ffaf021a;

		${Radio} {
			border: 1px solid #ffaf02;
		}
		${InnerRadio} {
			background-color: #ffaf02;
		}
	`}
`;
const OptionTitle = styled.div`
	font-size: 14px;
	font-weight: 600;
	color: #4d4d4d;
`;
const OptionDescrib = styled.div`
	font-size: 12px;
	font-weight: 300;
	color: #4d4d4d;
`;
const StyledInput = styled(Input).attrs({
	placeholder: 'Search',
	prefix: <Search />,
})`
	border-radius: 4px;
	margin-bottom: 10px;

	input {
		border: 0 !important;

		&:focus {
			border: 0 !important;
		}
	}
`;
