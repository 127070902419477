import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from 'reduxStore/actions/recipeAction';
import {
	QUERY_RECIPES,
	QUERY_RECIPE,
	CREATE_RECIPE,
	DELETE_RECIPE,
	UPDATE_RECIPE,
	UPDATE_RECIPE_CONFIG,
	DUPLICATE_RECIPE,
	DOWNLOAD_RECIPE,
} from 'util/apollo/gql/recipe';
import { apolloMutation, apolloQuery } from 'util/apollo/apollo';
import { IMPORT_FLOW } from '../../util/apollo/gql/recipe';
import DownloadAPI from '../../util/axios/downloadApi';

function* loadRecipes(action) {
	const { projectid } = action.payload;
	const IDS = projectid ? projectid : '';
	try {
		const queryRecipes = yield call(async () => await apolloQuery(QUERY_RECIPES, { ids: [IDS] }));
		if (queryRecipes.errors) throw queryRecipes.errors;

		yield put(actions.loadRecipes.success(queryRecipes.data.recipes));
	} catch (error) {
		yield put(actions.loadRecipes.failure(error));
	}
}

function* loadRecipe(action) {
	const { recipeid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_RECIPE, { recipeid }));
		if (errors) throw errors;

		yield put(actions.loadRecipe.success(data.recipe));
	} catch (error) {
		yield put(actions.loadRecipe.failure(error));
	}
}

function* createRecipe(action) {
	const { recipe } = action.payload;
	const recipeData = {
		name: recipe.name,
		description: recipe.description || '',
		projectid: recipe.projectid,
		hardware: recipe.hardware,
		edgeClient: recipe.edgeClientsNo || 0,
		protocol: recipe.protocol,
		mlBox: recipe.mlbox,
		database: recipe.database,
		visualization: recipe.visualization,
	};
	try {
		const { data, errors } = yield call(async () => await apolloMutation(CREATE_RECIPE, { ...recipeData }));
		if (errors) throw errors;

		let newRecipes = [];
		// call prev recipes list from redux store
		const prevRecipes = yield select(({ recipeReducer }) => recipeReducer.recipes);
		// and the new one
		newRecipes = [...prevRecipes, data?.createRecipe];

		yield put(actions.createRecipe.success(newRecipes));
	} catch (error) {
		yield put(actions.createRecipe.failure(error));
	}
}

function* updateRecipe(action) {
	const { recipe } = action.payload;
	const recipeData = {
		id: recipe.recipeid,
		name: recipe.name,
		description: recipe.description,
	};
	try {
		const { data, errors } = yield call(async () => await apolloMutation(UPDATE_RECIPE, { ...recipeData }));
		if (errors) throw errors;

		let newRecipes = [];
		// call prev recipes list from redux store
		const prevRecipes = yield select(({ recipeReducer }) => recipeReducer.recipes);
		// and the new one
		newRecipes = prevRecipes.map((oldRecipe) =>
			oldRecipe.recipeid === recipe.recipeid ? data?.updateRecipe : oldRecipe
		);

		yield put(actions.updateRecipe.success(newRecipes));
	} catch (error) {
		yield put(actions.updateRecipe.failure(error));
	}
}

function* updateConfig(action) {
	const { recipe } = action.payload;
	const recipeData = {
		recipeid: recipe.recipeid,
		hardware: recipe.hardware,
		edgeClient: recipe.edgeClientsNo || recipe.edgeClient,
		protocol: recipe.protocol,
		mlBox: recipe.mlbox || recipe.mlBox,
		database: recipe.database,
		visualization: recipe.visualization,
	};
	try {
		const { data, errors } = yield call(async () => await apolloMutation(UPDATE_RECIPE_CONFIG, { ...recipeData }));
		if (errors) throw errors;

		let newRecipes = [];
		// call prev recipes list from redux store
		const prevRecipes = yield select(({ recipeReducer }) => recipeReducer.recipes);
		// and the new one
		newRecipes = prevRecipes.map((oldRecipe) =>
			oldRecipe.recipeid === recipe.recipeid ? data.updateRecipeConfig : oldRecipe
		);

		yield put(actions.updateConfig.success(newRecipes));
	} catch (error) {
		yield put(actions.updateConfig.failure(error));
	}
}

function* deleteRecipe(action) {
	const { recipeid } = action.payload;

	try {
		const { errors } = yield call(async () => await apolloMutation(DELETE_RECIPE, { id: recipeid }));
		if (errors) throw errors;

		let newRecipes = [];
		// call prev recipes list from redux store
		const prevRecipes = yield select(({ recipeReducer }) => recipeReducer.recipes);
		// and the new one
		newRecipes = prevRecipes.filter((item) => item.recipeid !== recipeid);

		yield put(actions.deleteRecipe.success(newRecipes));
	} catch (error) {
		yield put(actions.deleteRecipe.failure(error));
	}
}

function* duplicateRecipe(action) {
	const { recipeid } = action.payload;

	try {
		const { data, errors } = yield call(async () => await apolloMutation(DUPLICATE_RECIPE, { id: recipeid }));
		if (errors) throw errors;

		let newRecipes = [];
		// call prev recipes list from redux store
		const prevRecipes = yield select(({ recipeReducer }) => recipeReducer.recipes);
		// and the new one
		newRecipes = [...prevRecipes, data.duplicateRecipe];

		yield put(actions.duplicateRecipe.success(newRecipes));
	} catch (error) {
		yield put(actions.duplicateRecipe.failure(error));
	}
}

function* downloadRecipe(action) {
	const { recipeid, version } = action.payload;

	try {
		const { data, errors } = yield call(async () => await apolloMutation(DOWNLOAD_RECIPE, { recipeid: recipeid, version }));
		if (errors) throw errors;


		if (!data.downloadrecipe.url) {
			throw errors;
		}
		const res = yield call(DownloadAPI.get, data.downloadrecipe.url);

		yield put(
			actions.downloadRecipe.success({
				fileData: res.data,
				fileName: data.downloadrecipe.filename,
				fileType: data.downloadrecipe.mimetype,
			})
		);
	} catch (error) {
		yield put(actions.downloadRecipe.failure(error));
	}
}

function* importFlow(action) {
	const { file, recipeid } = action.payload;

	try {
		const { data, errors } = yield call(async () => await apolloMutation(IMPORT_FLOW, { file, id: recipeid }));
		if (errors) throw errors;

		yield put(actions.importFlow.success(data));
	} catch (error) {
		yield put(actions.importFlow.failure(error));
	}
}

export default function* watchRecipe() {
	yield all([
		takeLatest(actions.LOAD_RECIPES.REQUEST, loadRecipes),
		takeLatest(actions.LOAD_RECIPE.REQUEST, loadRecipe),

		takeEvery(actions.CREATE_RECIPE.REQUEST, createRecipe),
		takeEvery(actions.UPDATE_RECIPE.REQUEST, updateRecipe),
		takeEvery(actions.UPDATE_RECIPE_CONFIG.REQUEST, updateConfig),
		takeEvery(actions.DELETE_RECIPE.REQUEST, deleteRecipe),
		takeEvery(actions.DUPLICATE_RECIPE.REQUEST, duplicateRecipe),
		takeEvery(actions.DOWNLOAD_RECIPE.REQUEST, downloadRecipe),
		takeEvery(actions.IMPORT_FLOW.REQUEST, importFlow),
	]);
}
