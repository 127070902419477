import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/delete_black_24dp-(1)" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M16,8.99999999 L16,19 L7.99999998,19 L7.99999998,8.99999999 L16,8.99999999 M14.5,2.99999996 L9.49999997,2.99999996 L8.49999997,3.99999996 L4.99999997,3.99999996 L4.99999997,5.99999996 L19,5.99999996 L19,3.99999996 L15.5,3.99999996 L14.5,2.99999996 Z M18,6.99999998 L5.99999997,6.99999998 L5.99999997,19 C5.99999997,20.1 6.89999998,21 7.99999997,21 L16,21 C17.1,21 18,20.1 18,19 L18,6.99999998 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Delete = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Delete;
