import { createTypes, createAction } from 'util/createActionHelper';

// types
const LOAD_RECIPES = createTypes('recipes', 'load');
const LOAD_RECIPE = createTypes('recipe', 'load');

const CREATE_RECIPE = createTypes('recipe', 'create');
const UPDATE_RECIPE = createTypes('recipe', 'update');
const DELETE_RECIPE = createTypes('recipe', 'delete');
const UPDATE_RECIPE_CONFIG = createTypes('recipe config', 'update');
const DUPLICATE_RECIPE = createTypes('recipe', 'duplicate');
const DOWNLOAD_RECIPE = createTypes('recipe', 'download');
const IMPORT_FLOW = createTypes('flow', 'import');

// actions
const loadRecipes = {
	request: (projectid) => createAction(LOAD_RECIPES.REQUEST, { projectid }),
	success: (recipes) => createAction(LOAD_RECIPES.SUCCESS, { recipes }),
	failure: (error) => createAction(LOAD_RECIPES.FAILURE, { error }),
};
const loadRecipe = {
	request: (recipeid) => createAction(LOAD_RECIPE.REQUEST, { recipeid }),
	success: (recipe) => createAction(LOAD_RECIPE.SUCCESS, { recipe }),
	failure: (error) => createAction(LOAD_RECIPE.FAILURE, { error }),
};
const createRecipe = {
	request: (recipe) => createAction(CREATE_RECIPE.REQUEST, { recipe }),
	success: (recipes) => createAction(CREATE_RECIPE.SUCCESS, { recipes }),
	failure: (error) => createAction(CREATE_RECIPE.FAILURE, { error }),
};
const updateRecipe = {
	request: (recipe) => createAction(UPDATE_RECIPE.REQUEST, { recipe }),
	success: (recipes) => createAction(UPDATE_RECIPE.SUCCESS, { recipes }),
	failure: (error) => createAction(UPDATE_RECIPE.FAILURE, { error }),
};
const deleteRecipe = {
	request: (recipeid) => createAction(DELETE_RECIPE.REQUEST, { recipeid }),
	success: (recipes) => createAction(DELETE_RECIPE.SUCCESS, { recipes }),
	failure: (error) => createAction(DELETE_RECIPE.FAILURE, { error }),
};
const updateConfig = {
	request: (recipe) => createAction(UPDATE_RECIPE_CONFIG.REQUEST, { recipe }),
	success: (recipes) => createAction(UPDATE_RECIPE_CONFIG.SUCCESS, { recipes }),
	failure: (error) => createAction(UPDATE_RECIPE_CONFIG.FAILURE, { error }),
};
const duplicateRecipe = {
	request: (recipeid) => createAction(DUPLICATE_RECIPE.REQUEST, { recipeid }),
	success: (recipes) => createAction(DUPLICATE_RECIPE.SUCCESS, { recipes }),
	failure: (error) => createAction(DUPLICATE_RECIPE.FAILURE, { error }),
};

const importFlow = {
	request: (file, recipeid) => createAction(IMPORT_FLOW.REQUEST, { file, recipeid }),
	success: (file) => createAction(IMPORT_FLOW.SUCCESS, { file }),
	failure: (error) => createAction(IMPORT_FLOW.FAILURE, { error }),
};

const downloadRecipe = {
	request: (recipeid, version) => createAction(DOWNLOAD_RECIPE.REQUEST, { recipeid, version }),
	success: (file) => createAction(DOWNLOAD_RECIPE.SUCCESS, { file }),
	failure: (error) => createAction(DOWNLOAD_RECIPE.FAILURE, { error }),
};

export {
	LOAD_RECIPES,
	loadRecipes,
	LOAD_RECIPE,
	loadRecipe,
	CREATE_RECIPE,
	createRecipe,
	UPDATE_RECIPE,
	updateRecipe,
	DELETE_RECIPE,
	deleteRecipe,
	UPDATE_RECIPE_CONFIG,
	updateConfig,
	DUPLICATE_RECIPE,
	duplicateRecipe,
	DOWNLOAD_RECIPE,
	downloadRecipe,
	IMPORT_FLOW,
	importFlow,
};
