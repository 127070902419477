import axios from 'axios';

export const DownloadAPI = axios.create({
	timeout: 15000,
    mode: 'no-cors',
    headers: { 
        'Access-Control-Expose-Headers': 'Access-Control-*',
        'Access-Control-Allow-Headers': 'Access-Control-*, Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Origin' : '*',
        'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        'Allow': 'GET, POST, PUT, DELETE, OPTIONS, HEAD'
    }
});

export default DownloadAPI;