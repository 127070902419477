import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/visibility_off_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M3.41999996,2.44999996 L21.15,20.19 L19.74,21.6 L16.32,18.18 C14.98,18.71 13.52,19 12,19 C6.99999995,19 2.72999996,15.89 0.999999954,11.5 C1.76999996,9.52999999 3.05999996,7.82999998 4.68999997,6.54999998 L4.68999997,6.54999998 L2.00999996,3.86999997 L3.41999996,2.44999996 Z M6.10999998,7.96999998 C4.89999997,8.88999999 3.87999997,10.07 3.17999996,11.5 C4.82999997,14.87 8.20999998,17 12,17 C12.95,17 13.87,16.86 14.75,16.62 L14.75,16.62 L13.77,15.64 C13.23,15.87 12.63,16 12,16 C9.51999999,16 7.49999998,13.98 7.49999998,11.5 C7.49999998,10.87 7.62999998,10.27 7.85999998,9.71999999 L7.85999998,9.71999999 Z M12,3.99999997 C17,3.99999997 21.27,7.10999998 23,11.5 C22.31,13.26 21.21,14.8 19.82,16.03 L19.82,16.03 L18.41,14.62 C19.4,13.77 20.23,12.72 20.82,11.5 C19.17,8.12999998 15.79,5.99999997 12,5.99999997 C11.32,5.99999997 10.66,6.08999998 10.01,6.21999998 L10.01,6.21999998 L8.35999998,4.56999997 C9.50999999,4.19999997 10.73,3.99999997 12,3.99999997 Z M9.50999999,11.37 L9.49999999,11.5 C9.49999999,12.88 10.62,14 12,14 C12.04,14 12.08,13.99 12.12,13.98 L12.12,13.98 L9.50999999,11.37 L9.50999999,11.37 Z M12,6.99999998 C14.48,6.99999998 16.5,9.00999999 16.490037,11.49 C16.490037,11.86 16.43,12.22 16.35,12.56 L16.35,12.56 L14.28,10.49 C14.03,9.91999999 13.57,9.45999999 13,9.20999999 L13,9.20999999 L10.93,7.13999998 C11.28,7.04999998 11.63,6.99999998 12,6.99999998 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Invisible = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Invisible;
