import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/arrow_drop_down_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<polygon
				id="Combined-Shape"
				fill="currentColor"
				points="6.99999998 9.99999999 12 15 17 9.99999999"
			></polygon>
		</g>
	</svg>
);

const DropdownDown = (props) => {
	return <Icon {...props} component={icon} />;
};
export default DropdownDown;
