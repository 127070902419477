import { Button, Col, Dropdown, Menu, Row, Space } from 'antd';
import { DropdownDown } from 'assets/icons';
import React, { useState } from 'react';
import styled from 'styled-components';

const deviceMenu = [
	[
		{ id: 'deviceid', label: 'ID' },
		{ id: 'devicename', label: 'Name' },
		{ id: 'recipeid', label: 'Edge Recipe' },
		// { id: 'createdate', label: 'Create Date' },
	],
	[
		{ id: 'asc', label: 'Ascending (A-Z)', shtLabel: '(A-Z)' },
		{ id: 'desc', label: 'Descending (Z-A)', shtLabel: '(Z-A)' },
	],
];
const recipeDeviceMenu = [
	[
		{ id: 'deviceid', label: 'ID' },
		{ id: 'devicename', label: 'Name' },
		// { id: 'recipeid', label: 'Edge Recipe' },
		// { id: 'createdate', label: 'Create Date' },
	],
	[
		{ id: 'asc', label: 'Ascending (A-Z)', shtLabel: '(A-Z)' },
		{ id: 'desc', label: 'Descending (Z-A)', shtLabel: '(Z-A)' },
	],
];

const SortMenu = ({ onSortApply, page }) => {
	const [visible, setVisible] = useState(false);
	const [type, setType] = useState({ id: 'deviceid', label: 'ID' });
	const [order, setOrder] = useState({ id: 'asc', label: 'Ascending (A-Z)', shtLabel: '(A-Z)' });
	const menuList = page === 'recipeDevice' ? recipeDeviceMenu : deviceMenu;

	const onApply = () => {
		setVisible(false);
		onSortApply(type.id, order.id);
	};

	const menu = (
		<Menu style={{ padding: '4px' }}>
			{menuList.map((sect, index) => [
				sect.map((item, idx) => (
					<Menu.Item key={item.id} onClick={() => (index === 0 ? setType(item) : setOrder(item))}>
						<Space>
							<Radio active={item.id === type.id || item.id === order.id}>
								<InnerRadio />
							</Radio>
							<span>{item.label}</span>
						</Space>
					</Menu.Item>
				)),
				index > 0 ? null : <Menu.Divider />,
			])}
			<div key="apply" style={{ padding: '5px 10px' }}>
				<Button type="primary" style={{ width: '100%' }} onClick={onApply}>
					Apply
				</Button>
			</div>
		</Menu>
	);

	return (
		<Dropdown overlay={menu} trigger={['click']} visible={visible} placement={'bottomRight'}>
			<DropdownContainer onClick={() => setVisible(!visible)}>
				<DropdownSect title>Sort by :</DropdownSect>
				<DropdownSect style={{ width: '160px' }}>
					<Row justify="space-between">
						<Col>
							{type.label} {order.shtLabel}
						</Col>
						<Col>
							<DropdownDown />
						</Col>
					</Row>
				</DropdownSect>
			</DropdownContainer>
		</Dropdown>
	);
};

export default SortMenu;

const DropdownContainer = styled(Row)`
	cursor: pointer;
	border: 1px solid #e3e3e3;
	border-radius: 4px;
	overflow: hidden;
`;
const DropdownSect = styled(Col)`
	width: auto;
	padding: 8px;
	background-color: ${(props) => (props.title ? '#f7f7f7' : '#fff')};
`;
const Radio = styled.div`
	width: 19px;
	height: 19px;
	border-radius: 12px;
	border: solid 1px #d8d8d8;

	display: flex;
	align-items: center;
	justify-content: center;

	${({ active }) =>
		active &&
		`
		border: 1px solid #ffaf02;
		
		${InnerRadio} {
			background-color: #ffaf02;
		}
	`}
`;
const InnerRadio = styled.div`
	width: 11px;
	height: 11px;
	border-radius: 7px;
`;
