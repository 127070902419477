import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Form, Image } from 'antd';
import logo from 'assets/images/logo.png';
import LoginForm from 'components/forms/auth/LoginForm';
import PublicContainer from 'components/Layouts/PublicContainer';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';
import * as yup from 'yup';
import { login } from '../../reduxStore/actions/authAction';

const loginSchema = yup.object().shape({
	username: yup.string().required('Please enter your username.'),
	password: yup.string().required('Please enter your password.'),
});

export const LoginPage = ({ login, message, loading }) => {
	const loginForm = useForm({ resolver: yupResolver(loginSchema) });
	const prevMsg = usePrevious(message);

	// func
	const onLogin = (form) => {
		login({ username: form.username, password: form.password });
	};
	const onEnter = (e) => {
		if (e.code === 'Enter') loginForm.handleSubmit(onLogin)();
	};

	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			if (message && message.description === 'Invalid password') {
				loginForm.setError('password', { message: message.description });
			} else if (message && message.description === 'User not found') {
				loginForm.setError('username', { message: message.description });
			} else {
				openNotification(message);
			}
		}
	}, [message]);

	return (
		<PublicContainer isLogin>
			<ParentContainer>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
					<Image src={logo} preview={false} width="76px" />
					<TextLogo>Edge customization made easy </TextLogo>
				</div>

				<Label>Login</Label>
				<SubLabel>Access Daysie portal using your username and password.</SubLabel>

				{/* form */}
				<Form layout="vertical" onKeyDown={(e) => onEnter(e)}>
					<LoginForm formProps={loginForm} />
					<Button onClick={loginForm.handleSubmit(onLogin)} block type="primary" loading={loading}>
						Login
					</Button>
				</Form>

				<ForgotPass>
					<Link to="/reset">Forgot password? </Link>
				</ForgotPass>
				<StyledDivider />
				<Newon>
					New on our platform?{' '}
					<Link to="/register">
						<CreateAcc> Create an account </CreateAcc>
					</Link>
				</Newon>
			</ParentContainer>
		</PublicContainer>
	);
};

const mapStateToProps = ({ authReducer }) => ({
	message: authReducer.loginMessage,
	loading: authReducer.loading,
});

const mapDispatchToProps = { login: login.request };

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

const TextLogo = styled.div`
	margin-top: 10px;
	font-size: 18px;
	color: #333333;
`;

const Label = styled.div`
	margin-top: 30px;
	font-size: 28px;
	font-weight: 600;
	letter-spacing: 0.44px;
	color: #333333;
`;

const SubLabel = styled.div`
	margin-bottom: 30px;
	font-size: 14px;
	font-weight: 300;
	color: #808080;
`;

const StyledDivider = styled(Divider)`
	border-top: 1px solid #979797;
`;

const Newon = styled.span`
	font-size: 12px;
	color: #808080;
`;

const CreateAcc = styled.span`
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	color: #ffaf02;
`;

const ForgotPass = styled.div`
	margin-top: 16px;
	font-size: 14px;
	font-weight: 600;
	color: #4d4d4d;
	text-align: right;
`;

const ParentContainer = styled.div`
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	max-width: 380px;
	margin: 0 auto;
	@media only screen and (max-width: 768px) {
		width: 90%;
	}
`;
