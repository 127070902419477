import gql from 'graphql-tag';

// fragment
const RECIPE_FRAGMENT = gql`
	fragment recipe on recipe {
		recipeid
		projectid
		name
		description
		createdtime
		status
		recipeconfig {
			hardware
			edgeClient
			protocol
			mlBox
			database
			visualization
			version
		}
	}
`;

// query
const QUERY_RECIPES = gql`
	query recipes($ids: [ID!]!) {
		recipes(filters: $ids) {
			...recipe
		}
	}
	${RECIPE_FRAGMENT}
`;
const QUERY_RECIPE = gql`
	query recipe($recipeid: ID!) {
		recipe(recipeid: $recipeid) {
			...recipe
		}
	}
	${RECIPE_FRAGMENT}
`;

// crud
const CREATE_RECIPE = gql`
	mutation createRecipe(
		$name: String!
		$description: String!
		$projectid: ID!
		$hardware: String!
		$edgeClient: Int!
		$protocol: [String!]!
		$mlBox: [ID!]!
		$database: Boolean!
		$visualization: Boolean!
	) {
		createRecipe(
			name: $name
			description: $description
			projectid: $projectid
			hardware: $hardware
			edgeClient: $edgeClient
			protocol: $protocol
			mlBox: $mlBox
			database: $database
			visualization: $visualization
		) {
			...recipe
		}
	}
	${RECIPE_FRAGMENT}
`;
const UPDATE_RECIPE = gql`
	mutation updateRecipe($id: ID!, $name: String!, $description: String!) {
		updateRecipe(recipeid: $id, name: $name, description: $description) {
			...recipe
		}
	}
	${RECIPE_FRAGMENT}
`;
const DELETE_RECIPE = gql`
	mutation deleteRecipe($id: ID!) {
		deleteRecipe(recipeid: $id) {
			...recipe
		}
	}
	${RECIPE_FRAGMENT}
`;
const UPDATE_RECIPE_CONFIG = gql`
	mutation updateRecipeConfig(
		$recipeid: ID!
		$hardware: String!
		$edgeClient: Int!
		$protocol: [String!]!
		$mlBox: [ID!]!
		$database: Boolean!
		$visualization: Boolean!
	) {
		updateRecipeConfig(
			recipeid: $recipeid
			hardware: $hardware
			edgeClient: $edgeClient
			protocol: $protocol
			mlBox: $mlBox
			database: $database
			visualization: $visualization
		) {
			...recipe
		}
	}
	${RECIPE_FRAGMENT}
`;
const DUPLICATE_RECIPE = gql`
	mutation duplicateRecipe($id: ID!) {
		duplicateRecipe(recipeid: $id) {
			...recipe
		}
	}
	${RECIPE_FRAGMENT}
`;

const IMPORT_FLOW = gql`
	mutation importFlow($file: Upload!, $id: ID!) {
		importFlow(file: $file, recipeid: $id) {
			filename
			mimetype
			url
			msg
		}
	}
`;

const DOWNLOAD_RECIPE = gql`
	mutation downloadrecipe($recipeid: ID!, $version: String!) {
		downloadrecipe(recipeid: $recipeid, version: $version) {
			filename
			mimetype
			url
			msg
		}
	}
`;

const EXPORT_RECIPE = gql`
	mutation exportrecipe($recipeid: ID!) {
		exportrecipe(recipeid: $recipeid) {
			filename
			mimetype
			url
			msg
		}
	}
`;

export {
	QUERY_RECIPES,
	QUERY_RECIPE,
	CREATE_RECIPE,
	UPDATE_RECIPE,
	DELETE_RECIPE,
	UPDATE_RECIPE_CONFIG,
	DUPLICATE_RECIPE,
	IMPORT_FLOW,
	DOWNLOAD_RECIPE,
	EXPORT_RECIPE
};
