import { Button, Drawer, Layout, Menu, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { loadProjectsWithRecipe } from 'reduxStore/actions/projectAction';
import styled from 'styled-components';
import logo_text from 'assets/images/logo-with-text.png';
import { Bookmark, Document, DropdownDown, PlayCircle, QuestionCircle, Send, SmallCircle, Github } from 'assets/icons';
import { Link, useLocation, useHistory } from 'react-router-dom';
import LabelDivider from './SubComponent/LabelDivider';

const iconStyle = { style: { fontSize: '16px' } };

const SiderComponent = ({
	collapsed,
	onBreakpoint,
	onCloseDrawer,
	visibleDrawer,
	mode,
	loadProjectsWithRecipe,
	projectsWithRecipe,
	projects,
	recipes,
}) => {
	const location = useLocation();
	const history = useHistory();
	const [activeSub, setActiveSub] = useState([]);

	const splitPath = location.pathname.split('/');
	const firstId = splitPath[2];
	const secondId = splitPath[4];

	useEffect(() => {
		loadProjectsWithRecipe();
	}, [loadProjectsWithRecipe, projects, recipes]);

	useEffect(() => {
		if (collapsed) {
			setActiveSub([]);
		}
	}, [collapsed]);

	useEffect(() => {
		if (secondId) {
			const key = `/p/${firstId}/edge`;
			if (!activeSub.includes(key)) {
				setActiveSub((prev) => [...prev, key]);
			}
		}
	}, [secondId, firstId, activeSub]);

	const onClickLink = (link) => {
		window.open(link);
	};

	const projectsSelect = useMemo(() => {
		return projectsWithRecipe?.map((p) => ({
			routeTo: `/p/${p.projectid}/edge`,
			label: p.name,
			icon: <IconProject active={p.projectid === firstId} />,
			subMenu: p.recipes.map((r) => ({
				routeTo: `/p/${p.projectid}/edge/${r.recipeid}`,
				label: r.name,
				icon: <SmallCircle {...iconStyle} />,
			})),
		}));
	}, [projectsWithRecipe, firstId]);

	const getTutorialSubMenu = (mode) => {
		const tutorialLinks = {
			project: [
				{
					key: 'Build-an-edge-device',
					label: 'Build an edge device',
					link: 'https://daysie.io/build-an-edge-device-th/',
				},
				{
					key: 'Create-an-edge-recipe',
					label: 'Create an edge recipe',
					link: 'https://daysie.io/create-an-edge-recipe/',
				},
				{
					key: 'Update-and-manage-version-of-edge-recipe',
					label: 'Update and manage version of edge recipe',
					link: 'https://daysie.io/update-and-manage-version-of-edge-recipe-th/',
				},
			],
			device: [
				{
					key: 'Install-edge-recipe-on-a-device',
					label: 'Install edge recipe on a device',
					link: 'https://daysie.io/install-edge-recipe-on-a-device-th/',
				},
				{
					key: 'Prepare-hardware-for-edge-device',
					label: 'Prepare hardware for edge device',
					link: 'https://daysie.io/prepare-hardware-for-edge-device-th/',
				},
			],
			mlmodel: [
				{
					key: 'Build-a-machine-learning-model',
					label: 'Build a machine learning model',
					link: 'https://daysie.io/build-a-machine-learning-model/',
				},
				{
					key: 'Train-&-Retrain-a-machine-learning-model',
					label: 'Train & Retrain a machine learning model',
					link: 'https://daysie.io/train-retrain-a-machine-learning-model/',
				},
			],
		};
		return (
			tutorialLinks[mode]?.map((item) => ({
				key: item.key,
				label: item.label,
				icon: <SmallCircle {...iconStyle} />,
				onclick: () => onClickLink(item.link),
			})) || []
		);
	};

	const menuList = useMemo(
		() => ({
			edge: [{ sectionLabel: 'PROJECTS', menu: projectsSelect }],
			other: [
				{
					sectionLabel: 'Menu',
					menu: [
						{
							key: '/document',
							label: 'Document',
							icon: <Document {...iconStyle} />,
							onclick: () => onClickLink('https://daysie.io/docs/'),
						},
						{ label: 'Tutorial', icon: <Bookmark {...iconStyle} />, subMenu: getTutorialSubMenu(mode) },
						{
							key: '/ex-project',
							label: 'Example Project',
							icon: <PlayCircle {...iconStyle} />,
							onclick: () => onClickLink('https://daysie.io/example-project/'),
						},
					],
				},
				{
					sectionLabel: '',
					menu: [
						{
							key: '/daysie-github',
							label: ' Daysie Github',
							icon: <Github {...iconStyle} />,
							onclick: () => onClickLink('https://github.com/TNatapon/DaysieClient'),
						},
					],
				},
				{
					sectionLabel: '',
					menu: [
						{
							key: '/faq',
							label: 'FAQ',
							icon: <QuestionCircle {...iconStyle} />,
							onclick: () => onClickLink('https://daysie.io/faq/'),
						},
						{
							key: '/sendfeedback',
							label: 'Send Feedback',
							icon: <Send {...iconStyle} />,
							onclick: () => (window.location.href = 'mailto:daisy@gmail.com'),
						},
					],
				},
			],
		}),
		[projectsSelect, mode]
	);

	const toggleSubmenu = (key) => {
		setActiveSub((prev) => (prev.includes(key) ? prev.filter((item) => item !== key) : [...prev, key]));
	};

	const renderContent = () => (
		<>
			{mode === 'edge' && (
				<Menu
					key="project-menu"
					mode="inline"
					selectedKeys={[location.pathname]}
					openKeys={activeSub}
					expandIcon={(props) => (
						<Button
							type="link"
							style={{ position: 'absolute', right: '0' }}
							onClick={(e) => {
								toggleSubmenu(props.eventKey);
								e.stopPropagation();
							}}
						>
							<DropdownDown {...iconStyle} />
						</Button>
					)}
				>
					{menuList.edge.map((sect) => (
						<React.Fragment key={sect.sectionLabel}>
							<LabelDivider label={sect.sectionLabel} />
							{sect.menu.map((menu, index) => (
								<Submenu
									key={`menu_${menu.routeTo}_${index}`}
									icon={menu.icon}
									title={
										<Link to={menu.routeTo} onClick={(e) => e.stopPropagation()}>
											<MenuLabel>{menu.label}</MenuLabel>
										</Link>
									}
									onTitleClick={() => history.push(menu.routeTo)}
								>
									{menu.subMenu.map((sub, subIndex) => (
										<MenuItem
											key={`submenu_${sub.routeTo}_${subIndex}`}
											icon={sub.icon}
											active={sub.routeTo.split('/')[4] === secondId}
										>
											<Link to={sub.routeTo}>
												<SubMenuLabel>{sub.label}</SubMenuLabel>
											</Link>
										</MenuItem>
									))}
								</Submenu>
							))}
						</React.Fragment>
					))}
				</Menu>
			)}
			<Menu
				key="other-menu"
				mode="inline"
				selectedKeys={[location.pathname]}
				expandIcon={(props) => (
					<Button type="link" style={{ position: 'absolute', right: '0' }} {...props}>
						<DropdownDown {...iconStyle} />
					</Button>
				)}
			>
				{menuList.other.map((sect, index) => (
					<React.Fragment key={`${sect.sectionLabel}${index}`}>
						<LabelDivider label={mode === 'edge' ? '' : sect.sectionLabel} />
						{sect.menu.map((menu, index) =>
							menu.subMenu ? (
								<Submenu
									key={`menu_${menu.key || menu.routeTo}${index}`}
									icon={menu.icon}
									title={
										<Link to={menu.routeTo} onClick={(e) => e.stopPropagation()}>
											<MenuLabel>{menu.label}</MenuLabel>
										</Link>
									}
								>
									{menu.subMenu.map((sub, subIndex) => (
										<Tooltip
											title={sub.label}
											placement="right"
											key={`tooltip_${sub.key || sub.routeTo}${subIndex}`}
										>
											<MenuItem
												key={`submenu_${sub.key || sub.routeTo}${subIndex}`}
												icon={sub.icon}
												onClick={sub.onclick}
											>
												<SubMenuLabel>{sub.label}</SubMenuLabel>
											</MenuItem>
										</Tooltip>
									))}
								</Submenu>
							) : (
								<MenuItem
									key={`menu_${menu.key || menu.routeTo}${index}`}
									icon={menu.icon}
									onClick={menu.onclick}
								>
									<Link to={menu.routeTo}>
										<MenuLabel>{menu.label}</MenuLabel>
									</Link>
								</MenuItem>
							)
						)}
					</React.Fragment>
				))}
			</Menu>
		</>
	);

	const renderSider = () => (
		<>
			<Logo>
				<Link to="/">
					<img src={logo_text} alt="logo_text" />
				</Link>
			</Logo>
			{renderContent()}
		</>
	);

	return (
		<>
			<Sider onBreakpoint={onBreakpoint} collapsed={collapsed}>
				{renderSider()}
			</Sider>
			<Drawer
				placement="left"
				width={300}
				closable
				onClose={onCloseDrawer}
				visible={visibleDrawer}
				bodyStyle={{ padding: 0, overflow: 'hidden' }}
				headerStyle={{ display: 'none' }}
			>
				{renderSider()}
			</Drawer>
		</>
	);
};

const mapStateToProps = (state) => ({
	projectsWithRecipe: state.projectReducer.projectsWithRecipe,
	projects: state.projectReducer.projects,
	recipes: state.recipeReducer.recipes,
});

const mapDispatchToProps = {
	loadProjectsWithRecipe: loadProjectsWithRecipe.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiderComponent);

const Sider = styled(Layout.Sider).attrs({
	breakpoint: 'lg',
	collapsedWidth: 0,
	width: 250,
	trigger: null,
	collapsible: true,
	theme: 'light',
})`
	overflow-x: hidden;
	overflow-y: auto;
	overflow-y: overlay;
	position: fixed;
	height: 100%;
	max-height: 100vh;

	::-webkit-scrollbar {
		width: 4px;
	}
	::-webkit-scrollbar-track {
		background-color: #f2f2f2;
	}
	::-webkit-scrollbar-thumb {
		background-color: #d8d8d8;
		border-radius: 20px;
	}
	::-webkit-scrollbar-thumb:hover {
		background-color: #a8bbbf;
	}

	.ant-menu-submenu,
	.ant-menu-item {
		border-radius: 8px;
		max-width: 230px;
		margin: 5px 10px;

		&:hover {
			.ant-menu-submenu-title {
				border-radius: 8px;
				background-color: rgba(255, 175, 2, 0.08);
			}
		}
	}
	.ant-menu-submenu {
		.ant-menu-item {
			&:hover {
				border-radius: 8px;
				background-color: rgba(245, 247, 251, 0.6);
			}
		}
	}
	.ant-menu-item {
		&:hover {
			border-radius: 8px;
			background-color: rgba(255, 175, 2, 0.08);
		}
	}

	.ant-menu-submenu-selected {
		.ant-menu-submenu-title {
			border-radius: 8px;
			background-color: rgba(255, 175, 2, 0.08);
		}

		.ant-menu-item {
			border-radius: 8px 0 0 8px;
		}
		.ant-menu-item-selected,
		.ant-menu-item-active {
			background-color: #f2f2f2;
		}
	}
	.ant-menu-vertical .ant-menu-item::after,
	.ant-menu-vertical-left .ant-menu-item::after,
	.ant-menu-vertical-right .ant-menu-item::after,
	.ant-menu-inline .ant-menu-item::after {
		border-right: unset;
	}
	.ant-menu-sub.ant-menu-inline {
		background: #fff;
	}
	.anticon svg {
		color: #808080;
	}
`;

const Logo = styled.div`
	height: 64px;
	padding: 0 12px;
	overflow: hidden;
	line-height: 64px;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		display: inline-block;
		height: 50px;
		vertical-align: middle;
	}
`;

const IconProject = styled.span`
	width: 14px;
	height: 14px;
	border-radius: 4px;
	background-color: ${(props) => (props.active ? '#ffaf02' : '#d8d8d8')};
`;

const MenuLabel = styled.span`
	font-size: 14px;
	font-weight: 600;
	color: #333333;
`;

const SubMenuLabel = styled(MenuLabel)`
	font-weight: 500;
	color: #808080;
`;

const Submenu = styled(Menu.SubMenu)``;

const MenuItem = styled(Menu.Item)`
	${MenuLabel} {
		color: ${(props) => props.active && '#333333'};
		font-weight: ${(props) => props.active && '600'};
	}
	.anticon svg,
	.ant-menu-item-icon {
		color: ${(props) => props.active && '#ffaf02'};
	}
`;
