import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/drive_file_rename_outline_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M21,16 L21,20 L11,20 L15,16 L21,16 Z M12.06,7.18999998 L15.81,10.94 L6.74999998,20 L2.99999996,20 L2.99999996,16.25 L12.06,7.18999998 Z M12.06,10.02 L4.99999997,17.08 L4.99999997,18 L5.91999997,18 L12.98,10.94 L12.06,10.02 Z M15.66,3.99999997 C15.92,3.99999997 16.17,4.08999997 16.37,4.28999997 L16.37,4.28999997 L18.71,6.62999998 C19.1,7.01999998 19.1,7.64999998 18.71,8.03999998 L18.71,8.03999998 L16.88,9.86999999 L13.13,6.11999998 L14.96,4.28999997 C15.15,4.09999997 15.41,3.99999997 15.66,3.99999997 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Edit = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Edit;
