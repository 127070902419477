import { Button, Col, Row, Skeleton, Space } from 'antd';
import { ArrowLeft } from 'assets/icons';
import Empty from 'components/etc/Empty';
import { CollapsedContext } from 'components/Layouts/LayoutContainer';
import AlertModal from 'components/modals/AlertModal';
import ImportFlowModal from 'components/modals/ImportFlowModal';
import LinkDeviceModal from 'components/modals/linkDeviceModal';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { linkDevice, loadDevices } from 'reduxStore/actions/deviceAction';
import { loadModels } from 'reduxStore/actions/modelAction';
import { loadProject } from 'reduxStore/actions/projectAction';
import {
	createRecipe,
	deleteRecipe,
	downloadRecipe,
	duplicateRecipe,
	importFlow,
	loadRecipes,
	updateConfig,
	updateRecipe,
} from 'reduxStore/actions/recipeAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';
import EdgeListCard from '../../components/Card/EdgeListCard';
import AddEdgeModal from '../../components/modals/addEdgeModal';
import ConfigEdgeModal from '../../components/modals/configEdgeModal';
import EditEdgeModal from '../../components/modals/editEdgeModal';

const EdgePage = ({
	loadProject,
	project,
	loadRecipes,
	createRecipe,
	updateRecipe,
	updateConfig,
	deleteRecipe,
	duplicateRecipe,
	recipes,
	message,
	loading,
	crudLoading,
	loadModels,
	models,
	loadDevices,
	rDevices,
	devices,
	linkLoading,
	linkDevice,
	dMessage,
	fileDownload,
	downloadRecipe,
	importFlow,
}) => {
	const history = useHistory();
	const projectid = history.location.pathname.split('/')[2];

	const [modalVisible, setModalVisible] = useState(false);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [configModalVisible, setConfigModalVisible] = useState(false);
	const [selectDeviceVisible, setSelectDeviceVisible] = useState(false);
	const [importModalVisible, setImportModalVisible] = useState(false);
	const [alertVisible, setAlertVisible] = useState([false, null]); // visible, alertType
	const [selectedEdge, setSelectedEdge] = useState(null);
	const [isDraft, setIsDraft] = useState(false);
	const globalCollapsed = useContext(CollapsedContext);
	const prevMsg = usePrevious(message);
	const prevDMsg = usePrevious(dMessage);
	let rDeviceid = rDevices?.map((item) => item.deviceid);
	let newDevices = devices.filter((el) => -1 === rDeviceid.indexOf(el.deviceid));
	const [selectedDevice, setSelectedDevice] = useState(null);

	const prevFile = usePrevious(fileDownload);

	// ----- componentDidMount -----

	// ----- componentDidUpdate -----
	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			openNotification(message);
			if (message?.type === 'success') {
				onCloseModal();
				if (isDraft) {
					history.push('/m/createmodel');
					setIsDraft(false);
				}
			}
		}
		if (prevDMsg !== undefined && prevDMsg !== dMessage) {
			openNotification(dMessage);
			if (dMessage?.type === 'success') {
				onCloseModal();
			}
		}
	}, [message, dMessage]);
	useEffect(() => {
		if (projectid) {
			loadProject(projectid);
			loadRecipes(projectid);
		}
		loadModels();
	}, [loadProject, loadRecipes, projectid]);
	useEffect(() => {
		if (fileDownload && prevFile !== fileDownload) {
			downloadFile(fileDownload);
		}
	}, [fileDownload]);

	// func
	const handleEdit = (item, e) => {
		setEditModalVisible(true);
		setSelectedEdge(item);
		e.domEvent.stopPropagation();
	};
	const handleConfig = (item, e) => {
		setConfigModalVisible(true);
		setSelectedEdge(item);
		e.domEvent.stopPropagation();
	};
	const handleDelete = (item, e) => {
		setAlertVisible([true, 'delete']);
		setSelectedEdge(item);
		e.domEvent.stopPropagation();
	};
	const handleExport = (item, e) => {
		exportData(item);
		e.domEvent.stopPropagation();
	};
	const handleImport = (item, e) => {
		setImportModalVisible((curVis) => !curVis);
		setSelectedEdge(item);
		e.domEvent.stopPropagation();
	};
	const onImportFlow = (file) => {
		importFlow(file, selectedEdge.recipeid);
	};
	const handleDuplicate = (item, e) => {
		setAlertVisible([true, 'duplicate']);
		setSelectedEdge(item);
		e.domEvent.stopPropagation();
	};
	const handleRevert = (item, e) => {
		setAlertVisible([true, 'revert']);
		setSelectedEdge(item);
		e.domEvent.stopPropagation();
	};
	const handleSelectDevice = (item, e) => {
		loadDevices(item.recipeid);
		setSelectedEdge(item);
		setSelectDeviceVisible(true);
		e.domEvent.stopPropagation();
	};

	const handleClicklink = (selectedDevices, e) => {
		setAlertVisible([true, 'movedevice']);
		setSelectedDevice(selectedDevices);
		// e.domEvent.stopPropagation();
	}

	const handleCancelConfirmMove = () => {
		setAlertVisible([false, 'movedevice']);
	}

	const onLinkDevice = () => {
		linkDevice(selectedEdge.recipeid, selectedDevice);
		setSelectedDevice(null);
		setAlertVisible([false, 'movedevice']);
	}

	const handleOkOnAlert = (data) => {
		switch (alertVisible[1]) {
			case 'delete':
				return deleteRecipe(data.recipeid);
			case 'revert':
				return updateConfig({ recipeid: data.recipeid, ...data.recipeconfig[1] });
			case 'duplicate':
				return duplicateRecipe(data.recipeid);
			case 'movedevice':
				return onLinkDevice();
			default:
				break;
		}
	};

	const downloadFile = (file) => {
		const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(file.fileData))}`;
		const link = document.createElement('a');
		link.href = jsonString;
		link.download = `${file.fileName}.${file.fileType}`;

		link.click();
	};

	const onCloseModal = () => {
		setModalVisible(false);
		setEditModalVisible(false);
		setConfigModalVisible(false);
		setImportModalVisible(false);
		setAlertVisible([false, null]);
		setSelectedEdge(null);
		setSelectDeviceVisible(false);
		setSelectedDevice(null)
	};

	

	// ----- Export json  -----
	const exportData = (item) => {
		const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(item))}`;
		const link = document.createElement('a');
		link.href = jsonString;
		link.download = `${item.name}.json`;

		link.click();
	};

	const renderEdgeList = () => {
		return (
			<Row gutter={[24, 24]}>
				{recipes?.map((item, index) => (
					<Col xs={24} sm={12} md={8} lg={globalCollapsed.collapsed ? 6 : 8} key={index}>
						<EdgeListCard
							item={item}
							models={models}
							onEdit={(e) => handleEdit(item, e)}
							onConfig={(e) => handleConfig(item, e)}
							onDelete={(e) => handleDelete(item, e)}
							onExport={(e) => handleExport(item, e)}
							onDuplicate={(e) => handleDuplicate(item, e)}
							onUndo={(e) => handleRevert(item, e)}
							onSelectDevice={(e) => handleSelectDevice(item, e)}
							onDownload={downloadRecipe}
							onImport={(e) => handleImport(item, e)}
						/>
					</Col>
				))}
			</Row>
		);
	};

	const renderNoEdge = () => {
		return <Empty title="Recipe" />;
	};

	if (loading) {
		return <Skeleton active />;
	}

	return (
		<div>
			<Row justify="space-between" align="middle" style={{ marginBottom: '24px' }}>
				<Col>
					<Link key={`back`} to="/p" style={{ display: 'flex', alignItems: 'center' }}>
						<ArrowLeft style={{ fontSize: 24 }} />
						<BackTo>Back to projects </BackTo>
					</Link>
					<Label>{project?.name}</Label>
					<Results>
						Results <NumEdges>{recipes?.length}</NumEdges> edges
					</Results>
				</Col>
				<Col>
					<Space>
						<Button onClick={() => setModalVisible(true)} type="primary">
							Create Recipe
						</Button>
					</Space>
				</Col>
			</Row>

			{recipes?.length ? renderEdgeList() : renderNoEdge()}

			{/* ----- Modal ----- */}
			<AddEdgeModal
				visible={modalVisible}
				handleCancel={onCloseModal}
				handleSubmit={(data, isdraft) => {
					createRecipe({ ...data, projectid });
					setIsDraft(isdraft);
				}}
				loading={crudLoading}
				models={models}
			/>
			<EditEdgeModal
				visible={editModalVisible}
				info={selectedEdge}
				handleCancel={onCloseModal}
				handleSubmit={(data) => updateRecipe(data)}
				models={models}
			/>
			<ConfigEdgeModal
				visible={configModalVisible}
				edge={selectedEdge}
				models={models}
				handleCancel={onCloseModal}
				handleSubmit={(data) => updateConfig(data)}
				setIsDraft={setIsDraft}
			/>
			<AlertModal
				alertType={alertVisible[1]}
				type="recipe"
				item={selectedEdge}
				visible={alertVisible[0]}
				handleCancel={alertVisible[1]=='movedevice' ? handleCancelConfirmMove : onCloseModal}
				handleOk={handleOkOnAlert}
				recipeName={selectedEdge?.name}
			/>
			<LinkDeviceModal
				visible={selectDeviceVisible}
				onOk={(selectedDevices) => handleClicklink(selectedDevices)}
				onCancel={onCloseModal}
				devices={newDevices}
				loading={linkLoading}
			/>
			<ImportFlowModal visible={importModalVisible} onCancel={onCloseModal} onOk={onImportFlow} />
		</div>
	);
};

const mapStateToProps = ({ projectReducer, recipeReducer, modelReducer, deviceReducer }) => {
	return {
		project: projectReducer.project,
		recipes: recipeReducer.recipes,
		message: recipeReducer.message,
		loading: recipeReducer.loading,
		crudLoading: recipeReducer.crudLoading,
		models: modelReducer.models,
		rDevices: deviceReducer.rDevices,
		devices: deviceReducer.devices,
		linkLoading: deviceReducer.linkLoading,
		dMessage: deviceReducer.message,
		fileDownload: recipeReducer.file,
	};
};

const mapDispatchToProps = {
	loadProject: loadProject.request,
	loadRecipes: loadRecipes.request,
	loadDevices: loadDevices.request,
	createRecipe: createRecipe.request,
	updateRecipe: updateRecipe.request,
	updateConfig: updateConfig.request,
	deleteRecipe: deleteRecipe.request,
	duplicateRecipe: duplicateRecipe.request,
	loadModels: loadModels.request,
	linkDevice: linkDevice.request,
	downloadRecipe: downloadRecipe.request,
	importFlow: importFlow.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(EdgePage);

const Label = styled.div`
	font-size: 24px;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: 0.5px;
	color: #333333;
	margin-top: 10px;
	margin-bottom: 14px;
`;

const BackTo = styled.span`
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
	letter-spacing: 0.5px;
	text-align: center;
	color: #ffaf02;
	padding: 0 8px;
`;

const Results = styled.span`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.29;
	letter-spacing: normal;
	color: #808080;
`;

const NumEdges = styled.span`
	font-size: 14px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #4d4d4d;
`;
