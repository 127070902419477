import { Col, Divider, Space, Button, Dropdown, Menu, message } from 'antd';
import React, { useState, useCallback } from 'react';
import Card from './Card';
import styled from 'styled-components';
import { Copy, Invisible, Visible, Ellipsis, Edit, Delete } from 'assets/icons';

const DeviceCard = ({ device, handleEdit, handleDelete }) => {
	// state
	const [isTokenVisible, setTokenVisible] = useState(false);

	// func
	const toggleVisible = useCallback(() => {
		setTokenVisible((prev) => !prev);
	}, []);

	const menu = (
		<StyledMenu>
			<Menu.Item icon={<Edit />} onClick={handleEdit}>
				Edit
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item icon={<Delete />} danger onClick={handleDelete}>
				Delete
			</Menu.Item>
		</StyledMenu>
	);

	return (
		<Card style={{ position: 'relative' }}>
			<DeviceName>{device.devicename}</DeviceName>
			<DeviceId>{device.deviceid}</DeviceId>
			<div>
				<Label>Edge Recipe :</Label> {device.recipename ? device.recipename : 'None'}
			</div>
			<div style={{ position: 'absolute', top: 5, right: 5 }}>
				<Dropdown overlay={menu} placement="bottomRight">
					<Button type="link" icon={<Ellipsis style={{ color: '#808080' }} />} />
				</Dropdown>
			</div>

			<Divider style={{ margin: '10px 0' }} />
			<FlexContainer>
				<Col flex="none" style={{ padding: 0 }}>
					<Label>Device Token : </Label>
				</Col>
				<DeviceTokenContainer>
					<FlexContainer>
						<Token>
							{isTokenVisible ? device.devicetoken : [...Array(device.devicetoken.length)].map(() => 'X')}
						</Token>
						<Col flex="none" style={{ padding: 0, fontSize: '14px' }}>
							<Space size={4}>
								<IconBtn icon={isTokenVisible ? <Visible /> : <Invisible />} onClick={toggleVisible} />
								<IconBtn
									icon={<Copy />}
									onClick={() => {
										navigator.clipboard.writeText(device.devicetoken);
										message.info('Copied Device Token to Clipboard');
									}}
								/>
							</Space>
						</Col>
					</FlexContainer>
				</DeviceTokenContainer>
			</FlexContainer>
		</Card>
	);
};

export default DeviceCard;
// style
const DeviceName = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: #333;
`;
const DeviceId = styled(DeviceName)`
	font-size: 14px;
	color: #4d4d4d;
	margin-bottom: 5px;
`;
const FlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
const Label = styled.span`
	font-size: 12px;
	color: #808080;
`;

const DeviceTokenContainer = styled(Col).attrs({
	flex: 'auto',
	size: 4,
})`
	background: #f7f7f7;
	padding: 2px 5px;
	border-radius: 4px;
	margin-left: 4px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Token = styled(Col).attrs({
	flex: 'auto',
})`
	color: #808080;
	padding: 0 !important;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	text-security: disc;
`;
const IconBtn = styled(Button).attrs({
	type: 'link',
})`
	width: 20px !important;
	padding: 0;

	color: #808080 !important;
`;
const StyledMenu = styled(Menu)`
	.ant-dropdown-menu-item,
	.ant-dropdown-menu-submenu-title {
		font-size: 14px;
		font-weight: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #4d4d4d;
		padding-left: 20px;
	}

	min-width: 180px;
	padding: 8px 0;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #ffffff;

	.ant-dropdown-menu-item-divider,
	.ant-dropdown-menu-submenu-title-divider {
		background-color: rgba(207, 218, 230, 0.5);
	}
	.ant-dropdown-menu-item:hover,
	.ant-dropdown-menu-submenu-title:hover {
		background-color: #fff8e8;
	}

	.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
		:hover {
			color: #f64e60;
			background-color: rgba(246, 78, 96, 0.14);
			font-weight: normal;
		}
	}
`;
