import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from '../actions/modelAction';
import {
	CANCEL_QUEUE,
	CREATE_MODEL,
	DELETE_MODEL,
	PREDICT_MODEL,
	QUERY_MODEL,
	QUERY_MODELS,
	QUERY_MODEL_SUBTYPE,
	QUERY_MODEL_TYPE,
	QUERY_PREVIEW_MODEL_CONFIG,
	TRAIN_MODEL,
	UPDATE_MODEL,
	UPDATE_MODEL_CONFIG,
	UPLOAD_TRAINING_FILE as UPLOAD_FILE,
} from 'util/apollo/gql/model';
import { apolloMutation, apolloQuery } from '../../util/apollo/apollo';
import { push } from 'connected-react-router';

function* loadModels(action) {
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_MODELS));
		if (errors) throw errors;

		yield put(actions.loadModels.success(data.models));
	} catch (error) {
		yield put(actions.loadModels.failure(error));
	}
}

function* loadModel(action) {
	const { modelid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_MODEL, { id: modelid }));
		if (errors) throw errors;

		yield put(actions.loadModel.success(data.model));
	} catch (error) {
		yield put(actions.loadModel.failure(error));
	}
}

function* loadTypes(action) {
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_MODEL_TYPE));
		if (errors) throw errors;

		yield put(actions.loadTypes.success(data.modelType));
	} catch (error) {
		yield put(actions.loadTypes.failure(error));
	}
}

function* loadSubtypes(action) {
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_MODEL_SUBTYPE));
		if (errors) throw errors;

		yield put(actions.loadSubtypes.success(data.modelSubtype));
	} catch (error) {
		yield put(actions.loadSubtypes.failure(error));
	}
}

function* createModel(action) {
	const { model } = action.payload;

	try {
		const { data, errors } = yield call(async () => await apolloMutation(CREATE_MODEL, { ...model }));
		if (errors) throw errors;

		// update redux
		let newModels = [];
		const prevModels = yield select(({ modelReducer }) => modelReducer.models);
		newModels = [...prevModels, data.createModel];

		yield put(actions.createModel.success(newModels));
		yield put(push(`/m/${data.createModel.modelid}/config`));
	} catch (error) {
		yield put(actions.createModel.failure(error));
	}
}

function* editModel(action) {
	const { model } = action.payload;
	try {
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(UPDATE_MODEL, {
					id: model.modelid,
					name: model.name,
					description: model.description,
				})
		);
		if (errors) throw errors;

		// update redux
		let newModels = [];
		const prevModels = yield select(({ modelReducer }) => modelReducer.models);
		newModels = prevModels?.map((model) =>
			model.modelid === data?.updateModel.modelid ? data.updateModel : model
		);

		yield put(actions.updateModel.success(newModels));
	} catch (error) {
		yield put(actions.updateModel.failure(error));
	}
}

function* configModel(action) {
	try {
		const { model } = action.payload;
		const newModel = {
			id: model.id,
			epochs: +model.epoch,
			batchsize: +model.batch,
			learningrate: +model.rate,
		};

		// todo => gql func.
		const { data, errors } = yield call(async () => await apolloMutation(UPDATE_MODEL_CONFIG, { ...newModel }));
		if (errors) throw errors;

		// update redux
		let newModels = [];
		const prevModels = yield select(({ modelReducer }) => modelReducer.models);
		newModels = prevModels?.map((model) => (model.id === newModel.id ? data.updateModelConfig : model));

		yield put(actions.configModel.success(data.updateModelConfig, newModels));
	} catch (error) {
		yield put(actions.configModel.failure(error));
	}
}

function* deleteModel(action) {
	const { model } = action.payload;
	try {
		const delModel = model;
		const { data, errors } = yield call(async () => await apolloMutation(DELETE_MODEL, { id: delModel.modelid }));
		if (errors) throw errors;

		// update redux
		let newModels = [];
		const prevModels = yield select(({ modelReducer }) => modelReducer.models);
		newModels = prevModels?.filter((model) => model.modelid !== data.deleteModel.modelid);

		yield put(actions.deleteModel.success(newModels));
	} catch (error) {
		yield put(actions.deleteModel.failure(error));
	}
}

function* cancelQueue(action) {
	const { model } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(CANCEL_QUEUE, { id: model.modelid }));
		if (errors) throw errors;

		if (data.cancelQueue.msg === 'Success') yield put(actions.cancelQueue.success());
	} catch (error) {
		yield put(actions.cancelQueue.failure(error));
	}
}

function* previewModel(action) {
	const { modelid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PREVIEW_MODEL_CONFIG, { id: modelid }));
		if (errors) throw errors;

		yield put(actions.previewModel.success(data.predictModelConfig));
	} catch (error) {
		yield put(actions.previewModel.failure(error));
	}
}

function* predictValue(action) {
	const { file, modelid } = action.payload;
	try {
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(PREDICT_MODEL, {
					file: file,
					id: modelid,
				})
		);
		if (errors) throw errors;

		yield put(actions.predictValue.success(data.predictModel.value));
	} catch (error) {
		yield put(actions.predictValue.failure(error));
	}
}

function* trainModel(action) {
	const { modelid, share } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(TRAIN_MODEL, { id: modelid, share }));
		if (errors) throw errors;

		yield put(actions.trainModel.success(data.trainModel));
	} catch (error) {
		yield put(actions.trainModel.failure(error));
	}
}

function* uploadTrainingFile(action) {
	const { modelid, file } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(UPLOAD_FILE, { id: modelid, file }));
		if (errors) throw errors;

		yield put(actions.uploadTrainModelFile.success(data.uploadTrainModelFile));
	} catch (error) {
		yield put(actions.uploadTrainModelFile.failure(error));
	}
}

export default function* watchModel() {
	yield all([
		takeLatest(actions.LOAD_MODELS.REQUEST, loadModels),
		takeLatest(actions.LOAD_MODEL.REQUEST, loadModel),
		takeLatest(actions.LOAD_TYPE.REQUEST, loadTypes),
		takeLatest(actions.LOAD_SUBTYPE.REQUEST, loadSubtypes),
		takeLatest(actions.PREVIEW_MODEL.REQUEST, previewModel),

		takeEvery(actions.CREATE_MODEL.REQUEST, createModel),
		takeEvery(actions.UPDATE_MODEL.REQUEST, editModel),
		takeEvery(actions.CONFIG_MODEL.REQUEST, configModel),
		takeEvery(actions.DELETE_MODEL.REQUEST, deleteModel),
		takeEvery(actions.CANCEL_QUEUE.REQUEST, cancelQueue),
		takeEvery(actions.PREDICT_VALUE.REQUEST, predictValue),
		takeEvery(actions.TRAIN_MODEL.REQUEST, trainModel),
		takeEvery(actions.UPLOAD_TRAINING_FILE.REQUEST, uploadTrainingFile),
	]);
}
