import React, { useState, useEffect } from 'react';
import { Form, Button } from 'antd';
import BaseModal from './baseModal';
import AlertModal from './AlertModal';
import { useForm } from 'react-hook-form';
import { LocalConfigForm } from '../forms/edge';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useHistory } from 'react-router-dom';

const schema = yup.object().shape({
	isConnectNetpie: yup.boolean(),
	clientId: yup
		.string()
		.when('isConnectNetpie', { is: true, then: yup.string().required(), otherwise: yup.string() }),
	token: yup.string().when('isConnectNetpie', { is: true, then: yup.string().required(), otherwise: yup.string() }),
	secret: yup.string().when('isConnectNetpie', { is: true, then: yup.string().required(), otherwise: yup.string() }),
});

const ConfigEdgeModal = ({ visible, edge, handleCancel, handleSubmit, models, setIsDraft, ...rest }) => {
	const formProps = useForm({ resolver: yupResolver(schema) });
	const config = edge?.recipeconfig[0];
	const [alertVisible, setAlertVisible] = useState(false);
	const history = useHistory();

	useEffect(() => {
		if (visible === true) {
			formProps.setValue('edgeClientsNo', config?.edgeClient || 0);
			formProps.setValue('hardware', config?.hardware || '');
			formProps.setValue('protocol', config?.protocol || []);
			formProps.setValue('mlbox', config?.mlBox || []);
			formProps.setValue('database', config?.database || false);
			formProps.setValue('visualization', config?.visualization || false);
		}
		if (visible === false) {
			formProps.reset();
		}
	}, [visible]);

	const onCancel = () => {
		formProps.reset({});
		handleCancel();
	};

	const onSubmit = (data) => {
		handleSubmit({
			...edge,
			...data,
		});
	};

	return (
		<>
			<BaseModal
				key="base-modal"
				title="Recipe Configs"
				titlePosition="left"
				visible={visible}
				onCancel={onCancel}
				footer={[
					<Button key="cancel-btn" onClick={onCancel}>
						Cancel
					</Button>,
					<Button key="save-btn" type="primary" onClick={formProps.handleSubmit(onSubmit)}>
						Save
					</Button>,
				]}
				{...rest}
			>
				<Form layout="vertical" onFinish={formProps.handleSubmit(onSubmit)}>
					<LocalConfigForm formProps={formProps} models={models} onBuildModel={() => setAlertVisible(true)} />
				</Form>
			</BaseModal>
			<AlertModal
				key="alert-modal"
				alertType="warning"
				visible={alertVisible}
				type="Recipe"
				handleCancel={() => setAlertVisible(false)}
				handleOk={() => history.push('/m/createmodel')}
				handleSave={() => {
					setIsDraft(true);
					formProps.handleSubmit(onSubmit)();
				}}
			/>
		</>
	);
};

export default ConfigEdgeModal;
