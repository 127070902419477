import gql from 'graphql-tag';

const MODEL_FRAGMENT = gql`
	fragment model on model {
		modelid
		name
		description
		createdtime
		updatetime
		status
		trainingtime
		queueNumber
		fileID
		filename
		modified
		fileError
		modeltype
		modelsubtype
		recipeid
		accuracy
		modelconfig {
			epochs
			batchsize
			learningrate
			share
		}
	}
`;

const MODEL_TYPE_FRAGMENT = gql`
	fragment modeltype on modeltype {
		id
		icon
		data
		typeName
		description
	}
`;

const MODEL_SUBTYPE_FRAGMENT = gql`
	fragment modelsubtype on modelsubtype {
		id
		type
		image
		subTypeName
		description
		toolTip
	}
`;

const PREVIEW_MODEL_CONFIG_FRAGMENT = gql`
	fragment predictmodelconfig on predictmodelconfig {
		modelid
		name
		description
		fileType
		maxSize
		modelclass
	}
`;

const QUERY_MODELS = gql`
	query {
		models {
			...model
		}
	}
	${MODEL_FRAGMENT}
`;

const QUERY_MODEL = gql`
	query model($id: ID!) {
		model(modelid: $id) {
			...model
		}
	}
	${MODEL_FRAGMENT}
`;

const QUERY_MODEL_TYPE = gql`
	query {
		modelType {
			...modeltype
		}
	}
	${MODEL_TYPE_FRAGMENT}
`;

const QUERY_MODEL_SUBTYPE = gql`
	query {
		modelSubtype {
			...modelsubtype
		}
	}
	${MODEL_SUBTYPE_FRAGMENT}
`;

const QUERY_PREVIEW_MODEL_CONFIG = gql`
	query predictModelConfig($id: ID!) {
		predictModelConfig(modelid: $id) {
			...predictmodelconfig
		}
	}
	${PREVIEW_MODEL_CONFIG_FRAGMENT}
`;

const CREATE_MODEL = gql`
	mutation createModel($name: String!, $description: String!, $modelType: Int!, $modelSubType: Int!) {
		createModel(name: $name, description: $description, modeltype: $modelType, modelsubtype: $modelSubType) {
			...model
		}
	}
	${MODEL_FRAGMENT}
`;

const UPDATE_MODEL = gql`
	mutation updateModel($id: ID!, $name: String!, $description: String!) {
		updateModel(modelid: $id, name: $name, description: $description) {
			...model
		}
	}
	${MODEL_FRAGMENT}
`;

const UPDATE_MODEL_CONFIG = gql`
	mutation updateModelConfig($id: ID!, $epochs: Float!, $batchsize: Float!, $learningrate: Float!) {
		updateModelConfig(modelid: $id, epochs: $epochs, batchsize: $batchsize, learningrate: $learningrate) {
			...model
		}
	}
	${MODEL_FRAGMENT}
`;

const DELETE_MODEL = gql`
	mutation deleteModel($id: ID!) {
		deleteModel(modelid: $id) {
			...model
		}
	}
	${MODEL_FRAGMENT}
`;

const TRAIN_MODEL = gql`
	mutation trainModel($id: ID!, $share: Boolean!) {
		trainModel(modelid: $id, share: $share) {
			filename
			fileID
			Error
		}
	}
`;

const CANCEL_QUEUE = gql`
	mutation cancelQueue($id: ID!) {
		cancelQueue(modelid: $id) {
			msg
		}
	}
`;

const PREDICT_MODEL = gql`
	mutation predictModel($file: Upload!, $id: ID!) {
		predictModel(file: $file, modelid: $id) {
			modelid
			filename
			mimetype
			url
			msg
			value
		}
	}
`;

const UPLOAD_FILE_PREDICT = gql`
	mutation uploadPredictModelFile($file: Upload!, $id: ID!) {
		uploadPredictModelFile(file: $file, modelid: $id) {
			filename
			mimetype
			url
			msg
		}
	}
`;

const UPLOAD_TRAINING_FILE = gql`
	mutation uploadTrainModelFile($file: Upload!, $id: ID!) {
		uploadTrainModelFile(file: $file, modelid: $id) {
			filename
			mimetype
			url
			msg
		}
	}
`;

export {
	QUERY_MODELS,
	QUERY_MODEL,
	QUERY_MODEL_TYPE,
	QUERY_MODEL_SUBTYPE,
	QUERY_PREVIEW_MODEL_CONFIG,
	CREATE_MODEL,
	UPDATE_MODEL,
	UPDATE_MODEL_CONFIG,
	DELETE_MODEL,
	TRAIN_MODEL,
	CANCEL_QUEUE,
	PREDICT_MODEL,
	UPLOAD_FILE_PREDICT,
	UPLOAD_TRAINING_FILE,
};
