import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/article_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M19,2.99999996 C20.1,2.99999996 21,3.89999997 21,4.99999996 L21,4.99999996 L21,19 C21,20.1 20.1,21 19,21 L19,21 L4.99999996,21 C3.89999997,21 2.99999996,20.1 2.99999996,19 L2.99999996,19 L2.99999996,4.99999996 C2.99999996,3.89999997 3.89999997,2.99999996 4.99999996,2.99999996 L4.99999996,2.99999996 Z M19,4.99999997 L4.99999997,4.99999997 L4.99999997,19 L19,19 L19,4.99999997 Z M14,15 L14,17 L6.99999998,17 L6.99999998,15 L14,15 Z M17,11 L17,13 L6.99999998,13 L6.99999998,11 L17,11 Z M17,6.99999998 L17,8.99999998 L6.99999998,8.99999998 L6.99999998,6.99999998 L17,6.99999998 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Document = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Document;
