import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Button, Form } from 'antd';
import React, { useEffect } from 'react';
import BaseModal from './baseModal';
import { useForm } from 'react-hook-form';
import DeviceForm from 'components/forms/device/deviceForm';

// schema
const deviceSchema = yup.object().shape({
	name: yup.string().required().max(50),
	recipeId: yup.string(),
});

const DeviceModal = ({ visible, handleCancel, handleAdd, handleEdit, device, recipes, ...rest }) => {
	const deviceForm = useForm({ mode: 'onTouched', resolver: yupResolver(deviceSchema) });
	const recipeOptions = recipes.map((recipe) => {
		return { value: recipe.recipeid, label: recipe.name };
	});

	useEffect(() => {
		if (visible === false) {
			deviceForm.reset();
		} else {
			deviceForm.setValue('name', device?.devicename || '');
			deviceForm.setValue('recipeId', device?.recipeid || '');
		}
	}, [visible]);

	const onSubmit = (data) => {
		if (device) {
			handleEdit({ deviceid: device.deviceid, ...data });
		} else {
			handleAdd(data);
		}
	};

	return (
		<BaseModal
			title={device ? 'Edit Device' : 'Add Device'}
			onCancel={handleCancel}
			visible={visible}
			footer={[
				<Button onClick={handleCancel}>Cancel</Button>,
				<Button type="primary" onClick={deviceForm.handleSubmit(onSubmit)}>
					{device ? 'Save' : 'Add'}
				</Button>,
			]}
			{...rest}
		>
			<Form layout="vertical">
				<DeviceForm formProps={deviceForm} device={device} options={recipeOptions} />
			</Form>
		</BaseModal>
	);
};

export default DeviceModal;
