import { SET_LAYOUT_VISIBLE } from '../actions/appSettingAction';

const initState = {
	layoutVisible: true,
};

const appSettingReducer = (state = initState, action = {}) => {
	switch (action.type) {
		case SET_LAYOUT_VISIBLE:
			return { ...state, layoutVisible: action.layoutVisible };
		default:
			return state;
	}
};

export default appSettingReducer;
