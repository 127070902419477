import axios from 'axios';
import { signOut } from '../../reduxStore/actions/authAction';
import { store } from '../../reduxStore/configureStore';
import cookie from '../cookie';

export const UserAPI = axios.create({
	baseURL: process.env.REACT_APP_AUTH_API,
	timeout: 30000,
	headers: { "Content-Type": "application/json" }
});

UserAPI.interceptors.request.use(
	(config) => {
		const { authReducer } = store.getState();
		const token = authReducer.token || cookie.get("accessToken");
		return {
			...config,
			headers: {
				'x-access-token': token,
				...config.headers,
			},
		};
	},
	(error) => {
		Promise.reject(error);
	}
);

UserAPI.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		console.log(`error.response `, error.response);

		if (error.response.status === 401) {
			store.dispatch(signOut());
		}

		return Promise.reject(error);
	}
);


export default UserAPI;