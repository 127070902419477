import { Input } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import FormItem from '../formItem';

const EdgeInfoForm = ({
	formProps: {
		control,
		formState: { errors },
	},
}) => {
	return (
		<>
			<FormItem label="Name" error={errors?.name?.message}>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <Input placeholder="Enter your edge name" {...field} />}
				/>
			</FormItem>
			<FormItem label="Description" error={errors?.description?.message}>
				<Controller
					name="description"
					control={control}
					render={({ field }) => (
						<Input.TextArea placeholder="Enter your edge description " rows={4} {...field} />
					)}
				/>
			</FormItem>
		</>
	);
};

export default EdgeInfoForm;
