import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form, Image, Skeleton } from 'antd';
import Linkexpired from 'assets/images/Linkexpired.png';
import SetupPasswordFrom from 'components/forms/auth/SetupPasswordFrom';
import PublicContainer from 'components/Layouts/PublicContainer';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { readToken, setuppassword } from 'reduxStore/actions/authAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';
import * as yup from 'yup';

const setupSchema = yup.object().shape({
	password: yup.string().required('Please enter your password.').min(6, 'Password must be at least 6 characters.'),
	confirmpassword: yup
		.string()
		.when('password', (password, field) =>
			password
				? field
						.required('Please enter your password confirmation.')
						.oneOf([yup.ref('password')], 'Your password and confirmation password does not match.')
				: field
		),
});

const ResetAccountPage = ({ setuppassword, loading, message, email, username, readToken, isRead, ...props }) => {
	let params = queryString.parse(props.location.search);
	const prevMsg = usePrevious(message);

	const setupForm = useForm({ resolver: yupResolver(setupSchema), mode: 'onTouched' });

	const onSetup = (form) => {
		setuppassword(email || null, form.password, params?.token);
	};

	useEffect(() => {
		readToken(params.token);
	}, []);

	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			openNotification(message);
		}
	}, [message]);

	if (loading) {
		return <Skeleton active />;
	}

	if (isRead === false) {
		return (
			<PublicContainer>
				<ParentContainer>
					<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
						<Image src={Linkexpired} preview={false} width="76px" />
					</div>
					<div style={{ margin: '10px 0px', textAlign: 'center' }}>
						<Label>Invalid Link!</Label>
						<SubLabel>The link you trying to access does not exist.</SubLabel>

						<Link to={'/login'}>
							<Button type="primary" style={{ marginTop: '10px' }}>
								Back to Log in
							</Button>
						</Link>
					</div>
				</ParentContainer>
			</PublicContainer>
		);
	}

	return (
		<PublicContainer>
			<ParentContainer>
				<div style={{ margin: '10px 0px' }}>
					<Label>Reset account password</Label>
					<SubLabel>Enter a new password for</SubLabel>
					<UserAndE>
						Username: <ValueUserAndE>{username || 'Daisy2021'}</ValueUserAndE>
					</UserAndE>
					<UserAndE>
						Email: <ValueUserAndE>{email || 'Daisy2021@gmail.com'}</ValueUserAndE>
					</UserAndE>
				</div>
				<Form layout="vertical" onFinish={setupForm.handleSubmit(onSetup)}>
					<SetupPasswordFrom formProps={setupForm} />
				</Form>
				<Button type="primary" style={{ width: '100%' }} onClick={setupForm.handleSubmit(onSetup)}>
					Reset password
				</Button>
			</ParentContainer>
		</PublicContainer>
	);
};

const mapStateToProps = ({ authReducer }) => ({
	loading: authReducer.loading,
	message: authReducer.otMessage,
	email: authReducer.atvEmail,
	username: authReducer.atvUsername,
	isRead: authReducer.isActivate,
});

const mapDispatchToProps = {
	setuppassword: setuppassword.request,
	readToken: readToken.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetAccountPage);

const Label = styled.div`
	font-size: 28px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.44px;
	color: #333333;
`;

const SubLabel = styled.div`
	font-size: 14px;
	font-weight: 300;
	line-height: normal;
	letter-spacing: normal;
	color: #808080;
`;
const ParentContainer = styled.div`
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	max-width: 380px;

	@media only screen and (max-width: 768px) {
		width: 90%;
	}
`;

const UserAndE = styled.div`
	font-size: 14px;
	font-weight: 300;
	color: #4d4d4d;
`;

const ValueUserAndE = styled.span`
	font-size: 16px;
	font-weight: 500;
	color: #4d4d4d;
`;
