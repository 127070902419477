import React from 'react';
import { Avatar } from 'antd';
import styled from 'styled-components';

const AlertIcon = ({ icon, color }) => {
	return (
		<AvatarWrapper color={color}>
			<StyledAvatar icon={icon} />
		</AvatarWrapper>
	);
};

export default AlertIcon;
const StyledAvatar = styled(Avatar).attrs({
	size: 'small',
})``;
const AvatarWrapper = styled.div`
	padding: 6px;
	border-radius: 50%;

	${({ color }) => `
        background-color: ${color}33;

        ${StyledAvatar} {
            background-color: ${color};
        }
    `}
`;
