import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Col, Form, Grid, Row, Skeleton, Space, message as antMessage } from 'antd';
import { ArrowLeft } from 'assets/icons';
import Card from 'components/Card/Card';
import StatusCard from 'components/Card/StatusCard';
import { UploadTrainFile } from 'components/forms/model';
import ConfigModelForm from 'components/forms/model/configModelForm';
import AlertModal from 'components/modals/AlertModal';
import BaseModal from 'components/modals/baseModal';
import EditModelModal from 'components/modals/editModelModal';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
	configModel,
	loadModel,
	loadSubtypes,
	loadTypes,
	trainModel,
	updateModel,
	uploadTrainModelFile,
} from 'reduxStore/actions/modelAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';
import * as yup from 'yup';

const { useBreakpoint } = Grid;

const configSchema = yup.object().shape({
	epoch: yup.number().min(5).max(300),
	batch: yup.string(),
	rate: yup.number().min(0.0001).max(0.001),
	windowSize: yup.string(),
});

const ConfigModelPage = ({
	crudLoading,
	message,
	loadModel,
	loadTypes,
	loadSubtypes,
	modelTypes,
	modelSubtypes,
	model,
	configModel,
	editModel,
	trainModel,
	trainStatus,
	loading,
	uploadError,
	uploadSuccess,
	uploadTrainModelFile,
}) => {
	const screens = useBreakpoint();
	const configForm = useForm({ mode: 'onChange', resolver: yupResolver(configSchema) });
	const history = useHistory();
	const location = useLocation();
	const prevMsg = usePrevious(message);
	const prevTrainStatus = usePrevious(trainStatus);

	const [alertVisible, setAlertVisible] = useState(false);
	const [alertVisibleShare, setAlertVisibleShare] = useState(false);
	const [uploadVisible, setUploadVisible] = useState(false);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const [isFormDirty, setFormDirty] = useState(false);

	const modelid = location.pathname.split('/')[2];

	const type = modelTypes.find((t) => t.id === model?.modeltype);
	const subtype = modelSubtypes.find((st) => st.id === model?.modelsubtype);

	useEffect(() => {
		loadTypes();
		loadSubtypes();
		loadModel(modelid);
	}, []);

	useEffect(() => {
		if (!uploadVisible) {
			loadTypes();
			loadSubtypes();
			loadModel(modelid);
		}
	}, [uploadVisible]);

	useEffect(() => {
		if (model?.modelconfig) {
			configForm.setValue('epoch', +model?.modelconfig?.epochs);
			configForm.setValue('batch', model?.modelconfig?.batchsize);
			configForm.setValue('rate', model?.modelconfig?.learningrate);
			configForm.setValue('windowSize', model?.modelconfig?.windowsize);
		}
	}, [model]);

	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			openNotification(message);
			if (message.type === 'success') loadModel(modelid);
		}
	}, [message]);

	useEffect(() => {
		if (prevTrainStatus !== undefined && trainStatus === true && prevTrainStatus !== trainStatus) {
			history.push('/m');
		}
	}, [trainStatus]);

	useEffect(() => {
		const config = model?.modelconfig;
		const form = configForm.watch();
		setFormDirty(
			!(
				config?.epochs === form.epoch &&
				config?.batchsize === form.batch &&
				config?.learningrate === form.rate &&
				config?.windowsize === form.windowSize
			)
		);
	}, [configForm, model?.modelconfig]);

	useEffect(() => {
		if (uploadSuccess) {
			antMessage.success('File uploaded successfully');
		}
		if (uploadError) {
			antMessage.error(`Upload failed: ${uploadError}`);
		}
	}, [uploadSuccess, uploadError]);

	const handleClickBack = () => setAlertVisible(true);

	const onSubmit = (data) => configModel({ id: model.modelid, ...data });

	const onTrainModel = (share) => {
		trainModel(modelid, share);
	};

	const getStatus = () => {
		const statusMap = {
			0: 'draft',
			1: 'ready',
			2: 'uploading',
			3: 'queuing',
			4: 'upload-failed',
			5: 'train-failed',
			6: 'training',
		};
		return { status: statusMap[model?.status] };
	};

	if (loading && !model) return <Skeleton active />;

	return (
		<>
			<Button type="link" style={{ padding: 0 }} onClick={handleClickBack}>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<ArrowLeft />
					Back to ML models
				</div>
			</Button>

			<Row justify="space-between" align="middle">
				<Col>
					<Space direction="vertical">
						<Title>
							{model?.name}{' '}
							<StatusCard status={getStatus()?.status} style={{ position: 'absolute', top: 0 }} />
						</Title>
						<Type>
							Type:{' '}
							<span>
								{type?.typeName} : {subtype?.subTypeName}
							</span>
						</Type>
						<Type>
							Description:{' '}
							<span style={{ color: 'gray', fontWeight: 'normal' }}>{model?.description}</span>
						</Type>
					</Space>
				</Col>
				<Col>
					<Button onClick={() => setEditModalVisible(true)}>Edit</Button>
				</Col>
			</Row>

			<Space direction="vertical" style={{ width: '100%' }}>
				<Card>
					<CardTitle>Training Configuration</CardTitle>
					<Type>
						<span>Model Type</span>
					</Type>
					<Form layout="vertical">
						<Space direction={screens.md ? 'horizontal' : 'vertical'}>
							<ConfigModelForm formProps={configForm} modelType={model?.modeltype} />
							<Button
								type="primary"
								style={{ marginTop: '3px' }}
								disabled={!isFormDirty}
								onClick={configForm.handleSubmit(onSubmit)}
								loading={crudLoading}
							>
								Save
							</Button>
						</Space>
					</Form>
				</Card>

				<Card>
					<UploadTrainFile onClickTrain={() => setAlertVisibleShare(true)} configUnsave={isFormDirty} />
				</Card>
			</Space>

			<AlertModal
				alertType="modelwarning"
				visible={alertVisible}
				type="ML Model"
				handleCancel={() => setAlertVisible(false)}
				handleOk={() => history.push('/m')}
			/>
			<AlertModal
				alertType="modelsharing"
				visible={alertVisibleShare}
				type="ML Model"
				handleClose={() => setAlertVisibleShare(false)}
				handleCancel={() => onTrainModel(false)}
				handleOk={() => onTrainModel(true)}
			/>
			<BaseModal
				footer={null}
				visible={uploadVisible}
				title="Select a file"
				titlePosition="left"
				onCancel={() => setUploadVisible(false)}
				width="85%"
				bodyStyle={{ height: '85vh', padding: 0 }}
			>
				<iframe
					title="google_drive_iframe"
					src="https://blog.logrocket.com/best-practices-react-iframes/"
					style={{ width: '100%', height: '100%', border: 0 }}
				/>
			</BaseModal>
			<EditModelModal
				visible={editModalVisible}
				info={model}
				handleCancel={() => setEditModalVisible(false)}
				handleSubmit={(data) => {
					editModel(data);
					setEditModalVisible(false);
				}}
			/>
		</>
	);
};

const mapStateToProps = ({ modelReducer }) => ({
	model: modelReducer.model,
	modelTypes: modelReducer.types,
	modelSubtypes: modelReducer.subtypes,
	crudLoading: modelReducer.crudLoading,
	message: modelReducer.message,
	trainStatus: modelReducer.trainStatus,
	loading: modelReducer.loading,
	uploading: modelReducer.uploading,
	uploadSuccess: modelReducer.uploadSuccess,
	uploadError: modelReducer.uploadError,
	uploadData: modelReducer.uploadData,
});

const mapDispatchToProps = {
	loadModel: loadModel.request,
	loadTypes: loadTypes.request,
	loadSubtypes: loadSubtypes.request,
	configModel: configModel.request,
	editModel: updateModel.request,
	trainModel: trainModel.request,
	uploadTrainModelFile: uploadTrainModelFile.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigModelPage);

const Title = styled(Space).attrs({ align: 'start' })`
	font-size: 24px;
	font-weight: 600;
	color: #333333;
`;

const Type = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: #b3b3b3;

	span {
		color: #4d4d4d;
	}
`;

const CardTitle = styled.div`
	font-size: 20px;
	font-weight: 500;
	color: #333333;
`;
