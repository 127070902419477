import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import appSettingReducer from './appSettingReducer';
import modelReducer from './modelReducer';
import projectReducer from './projectReducer';
import authReducer from './authReducer';
import deviceReducer from './deviceReducer';
import recipeReducer from './recipeReducer';

// export const DESTROY_SESSION = 'DESTROY_SESSION';

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		appSettingReducer,
		modelReducer,
		projectReducer,
		authReducer,
		deviceReducer,
		recipeReducer,
	});

export default createRootReducer;
