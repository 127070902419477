import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/restart_alt_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M5.99999997,13 C5.99999997,11.35 6.66999998,9.84999999 7.75999998,8.75999999 L6.33999998,7.33999998 C4.89999997,8.78999999 3.99999997,10.79 3.99999997,13 C3.99999997,17.08 7.04999998,20.44 11,20.93 L11,18.91 C8.16999998,18.43 5.99999997,15.97 5.99999997,13 Z M20,13 C20,8.57999999 16.42,4.99999996 12,4.99999996 C11.94,4.99999996 11.88,5.00999997 11.82,5.00999997 L12.91,3.91999997 L11.5,2.49999996 L7.99999998,5.99999996 L11.5,9.49999996 L12.91,8.08999998 L11.83,7.00999998 C11.89,7.00999998 11.95,6.99999996 12,6.99999996 C15.31,6.99999996 18,9.68999999 18,13 C18,15.97 15.83,18.43 13,18.91 L13,20.93 C16.95,20.44 20,17.08 20,13 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Revert = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Revert;
