import { Image, Upload, Space, Button, Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import BaseModal from './baseModal';
import styled from 'styled-components';
import UploadFileImg from 'assets/images/file@2x.png';
import FileImg from 'assets/images/fileicon@2x.png';

const ImportFlowModal = ({ visible, onOk, ...rest }) => {
	const [FILE, setFile] = useState(null);
	const [UPLOAD, setUpload] = useState(null);
	const [fileOver, setFileOverLimit] = useState(false);

	// return to default state if modal close
	useEffect(() => {
		if (visible === false) {
			setFile(null);
			setUpload(null);
			setFileOverLimit(false);
		}
	}, [visible]);

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	};
	const upload_props = {
		name: 'file',
		multiple: false,
		action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
		showUploadList: false,
		beforeUpload: (file, fileList) => {
			getBase64(file, (imageUrl) => {
				setFile({ src: imageUrl, type: file?.type, name: file?.name });
				setUpload(file);
			});
			const isFileOver = file.size < 1;
			if (!isFileOver) {
				setFileOverLimit(true);
			} else {
				setFileOverLimit(false);
			}

			return false;
		},
		style: {
			maxHeight: '220px',
			marginTop: 10,
		},
		accept: '.json',
	};

	return (
		<BaseModal
			visible={visible}
			title="Upload File"
			titlePosition="center"
			okText="Upload"
			{...rest}
			onOk={() => onOk(UPLOAD)}
		>
			<Info>Attached file</Info>
			{FILE ? (
				<PreviewContainer>
					{fileOver && (
							<Alert
								message={`File must smaller than 1 MB`}
								showIcon
								closable
								type="error"
								style={{ width: '100%' }}
							/>
					)}
					<Image src={FileImg} preview={false} width={100} />
					<div>{FILE.name}</div>
					<Space>
						<Upload {...upload_props}>
							<Button type="secondary">Change file</Button>
						</Upload>
						<Button type="text" onClick={() => setFile(null)}>
							<span style={{ color: '#f64e60' }}>Delete</span>
						</Button>
					</Space>
				</PreviewContainer>
			) : (
				<Upload.Dragger {...upload_props}>
					<p className="ant-upload-drag-icon">
						<Image src={UploadFileImg} preview={false} width={100} />
					</p>
					<p className="ant-upload-text">Drag and Drop to upload</p>
					<p className="ant-upload-hint">Maximum file size: 1 MB</p>
					<p className="ant-upload-hint">
						or <span style={{ color: '#ffaf02', fontWeight: 600 }}>Browse file</span>
					</p>
				</Upload.Dragger>
			)}

			<Info>
				Accepted file type: <span>json</span>
			</Info>
		</BaseModal>
	);
};

export default ImportFlowModal;

const Info = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: #808080;
	margin-top: 10px;

	span {
		color: #4d4d4d;
	}
`;
const PreviewContainer = styled.div`
	width: 100%;
	height: 220px;
	border: 1px solid #80808080;
	border-radius: 6px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
`;
