import LayoutPage from 'pages/LayoutPage';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import cookie from 'util/cookie';

const PrivateRoute = ({ exact, path, authenticated, component: Component, ...rest }) => {

	return (
		<Route
			{...rest}
			render={(props) => {
				// return <LayoutPage><Component {...props} /></LayoutPage>
				return authenticated || cookie.get('accessToken') ? (
					<LayoutPage>
						<Component {...props} />
					</LayoutPage>
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props.location },
						}}
					/>
				);
			}}
		/>
	);
};

const mapStateToProps = ({ authReducer }) => ({
	authenticated: authReducer.authenticated,
});

export default connect(mapStateToProps)(PrivateRoute);
