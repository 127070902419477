import React, { useState, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Row, Col, Button, Skeleton } from 'antd';
import ProjectListCard from '../../components/Card/ProjectListCard';
import ProjectModal from 'components/modals/ProjectModal';
import AlertModal from 'components/modals/AlertModal';
import Empty from 'components/etc/Empty';
import { CollapsedContext } from 'components/Layouts/LayoutContainer';
import { createProject, deleteProject, loadProjects, updateProject } from 'reduxStore/actions/projectAction';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';

const ProjectPage = ({
	projects,
	pMessage,
	loading,
	crudLoading,
	loadProjects,
	createProject,
	editProject,
	deleteProject,
}) => {

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [selectedProject, setSelectedProject] = useState(null);
	const [alertVisible, setAlertVisible] = useState([false, null]); // visible, alertType
	const globalCollapsed = useContext(CollapsedContext);
	const prevMsg = usePrevious(pMessage);

	// ----- componentDidMount -----
	useEffect(() => {
		loadProjects();
	}, [loadProjects]);

	// ----- componentDidUpdate -----
	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== pMessage) {
			openNotification(pMessage);
			if (pMessage?.type === 'success') {
				handleCancel();
			}
		}
	}, [pMessage]);

	const handleCancel = () => {
		setIsModalVisible(false);
		setSelectedProject(null);
		setAlertVisible([false, null]);
	};

	const onSubmit = (form) => {
		if (selectedProject !== null) {
			editProject({ projectid: selectedProject.projectid, ...form });
		} else {
			createProject(form);
		}
	};

	const handleEdit = (item, e) => {
		setSelectedProject(item);
		setIsModalVisible(true);
		e.domEvent.stopPropagation();
	};

	const handleDelete = (item, e) => {
		setAlertVisible([true, 'delete']);
		setSelectedProject(item);
		e.domEvent.stopPropagation();
	};

	const renderProjectList = () => {
		return (
			<Row gutter={[24, 24]}>
				{projects.map((item, index) => (
					<Col xs={24} sm={12} md={8} lg={globalCollapsed.collapsed ? 6 : 8} key={index}>
						<ProjectListCard
							item={item}
							handleEdit={(e) => handleEdit(item, e)}
							handleDelete={(e) => handleDelete(item, e)}
						/>
					</Col>
				))}
			</Row>
		);
	};

	const renderNoProject = () => {
		return <Empty title="project" />;
	};

	if (loading) {
		return <Skeleton active />;
	}

	return (
		<div>
			<Row justify="space-between" align="middle" style={{ marginBottom: '24px' }}>
				<Col>
					<Label>Project</Label>
					<Results>
						Results <NumEdges>{projects.length}</NumEdges> projects
					</Results>
				</Col>
				<Col>
					<Button
						onClick={() => setIsModalVisible(true)}
						type="primary"
						disabled={projects.length >= 3 ? true : false}
					>
						Create Project
					</Button>
				</Col>
			</Row>

			{projects.length ? renderProjectList() : renderNoProject()}

			<ProjectModal
				visible={isModalVisible}
				onSubmit={onSubmit}
				handleCancel={handleCancel}
				project={selectedProject}
				loading={crudLoading}
			/>

			<AlertModal
				alertType={alertVisible[1]}
				type="project"
				item={selectedProject}
				visible={alertVisible[0]}
				handleCancel={handleCancel}
				handleOk={(data) => deleteProject(data)}
			/>
		</div>
	);
};

const mapStateToProps = ({ projectReducer }) => {
	return {
		projects: projectReducer.projects,
		pMessage: projectReducer.message,
		loading: projectReducer.loading,
		crudLoading: projectReducer.crudLoading,
	};
};

const mapDispatchToProps = {
	loadProjects: loadProjects.request,
	createProject: createProject.request,
	editProject: updateProject.request,
	deleteProject: deleteProject.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage);

const Label = styled.div`
	font-size: 24px;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: 0.5px;
	color: #333333;
	margin-top: 10px;
	margin-bottom: 14px;
`;

const Results = styled.span`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.29;
	letter-spacing: normal;
	color: #808080;
`;

const NumEdges = styled.span`
	font-size: 14px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #4d4d4d;
`;
