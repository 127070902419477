import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/construction_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M15.903991,13.050563 L21.900199,19.046771 L19.778899,21.168071 L13.782691,15.171863 L15.903991,13.050563 Z M7.75999998,3.48999996 C8.92999999,2.31999996 10.83,2.31999996 12,3.48999996 L12,3.48999996 L9.87999999,5.60999997 L11.29,7.01999998 L10.58,7.72999998 L12.36,9.50999999 L14.21,7.65999998 C14.08,7.29999998 14,6.90999998 14,6.49999998 C14,4.56999997 15.57,2.99999996 17.5,2.99999996 C18.08,2.99999996 18.62,3.15999996 19.1,3.40999996 L19.1,3.40999996 L16.4,6.10999998 L17.89,7.59999998 L20.59,4.89999997 C20.84,5.37999997 21,5.91999997 21,6.49999998 C21,8.42999999 19.43,9.99999999 17.5,9.99999999 C17.09,9.99999999 16.7,9.91999999 16.34,9.78999999 L16.34,9.78999999 L4.95999997,21.16 L2.83999996,19.04 L10.25,11.63 L8.46999999,9.84999999 L7.75999998,10.56 L6.34999998,9.14999999 L6.34999998,11.98 L5.63999997,12.69 L2.09999996,9.14999999 L2.80999996,8.43999999 L5.62999997,8.43999999 L4.21999997,7.02999998 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Construction = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Construction;
