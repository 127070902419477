import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import UserComponent from './SubComponent/UserComponent';
import MenuRight from './SubComponent/MenuRight';

const HeaderComponent = ({
	toggleSider,
	toggleDrawer,
	onSignoutClick,
	user,
	loading,
	updateProfile,
	changePassword,
}) => {
	return (
		<Header>
			<Left>
				<div className="hideOnMobile" onClick={toggleSider} style={{ cursor: 'pointer' }}>
					<span className="material-icons-outlined">menu</span>
				</div>

				<div className="hideOnDesktop" onClick={toggleDrawer} style={{ cursor: 'pointer' }}>
					<span className="material-icons-outlined">menu</span>
				</div>
			</Left>
			<Right>
				<MenuRight />
				<UserComponent
					onSignoutClick={onSignoutClick}
					user={user}
					loading={loading}
					updateProfile={updateProfile}
					changePassword={changePassword}
				/>
			</Right>
		</Header>
	);
};

export default HeaderComponent;

const Header = styled(Layout.Header)`
	height: 55px;

	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 1px 3px 0 rgba(199, 210, 243, 0.4);
	background-color: #fefeff;
	padding: 20px 32px;

	.ant-menu-horizontal > .ant-menu-submenu {
		top: -1px;
	}
`;

const Left = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const Right = styled(Left)`
	width: 100%;
`;
