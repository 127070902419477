import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router-dom';
import cookie from 'util/cookie';
import { openNotification } from 'util/openNotification';
import LayoutContainer from '../components/Layouts/LayoutContainer';
import { changepassword, getProfile, updateProfile } from '../reduxStore/actions/authAction';

const LayoutPage = ({ children, layoutVisible, projects, userid, user, getProfile, updateProfile, changePassword, message }) => {
	const matchProject = useRouteMatch('/p/:projectid/');
	const matchs = useRouteMatch('/');
	const currentLocation = useLocation();
	
	const defineSiderMode = useCallback(() => {
		if (matchProject?.params){
			return 'edge';
		} else {
			if(currentLocation.pathname.indexOf('/p') > -1){
				return 'project';
			} else if(currentLocation.pathname.indexOf('/d') > -1){
				return 'device';
			} else if(currentLocation.pathname.indexOf('/m') > -1){
				return 'mlmodel';
			} else {
				return 'other';
			}
		}

		// return null;
	}, [matchProject, matchs]);

	const getUserInfo = useCallback(() => {
		!user && getProfile(userid);
	}, [getProfile, user]);

	useEffect(() => {
		getUserInfo();
	}, [getUserInfo, user]);

	useEffect(() => {
		if(message && message.description !== 'Invalid current password'){
			openNotification(message);
		} 
	}, [message]);

	if (layoutVisible) {
		return (
			<LayoutContainer mode={defineSiderMode()} projectList={projects} user={user} updateProfile={updateProfile} changePassword={changePassword}  >
				{children}
			</LayoutContainer>
		);
	}
	return children;
};

const mapStateToProps = ({ appSettingReducer, projectReducer, authReducer }) => ({
	layoutVisible: appSettingReducer.layoutVisible,
	projects: projectReducer.projects,
	userid: authReducer.userid || cookie.get("userId"),
	user: authReducer.user,
	message: authReducer.message,
});

const mapDispatchToProps = {
	getProfile: getProfile.request,
	updateProfile: updateProfile.request,
	changePassword: changepassword.request
};

export default connect(mapStateToProps, mapDispatchToProps)(LayoutPage);
