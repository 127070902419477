import React from 'react';
import BaseModal from './baseModal';
import styled from 'styled-components';
import { Button, Col, Row } from 'antd';
import { ExclamationOutlined, InfoOutlined } from '@ant-design/icons';
import AlertIcon from 'components/etc/AlertIcon';
import { apolloQuery } from 'util/apollo/apollo';
import { QUERY_RECIPE } from 'util/apollo/gql/recipe';

const AlertModal = ({
	alertType,
	type,
	item,
	children,
	handleClose,
	handleCancel,
	handleOk,
	loading,
	recipeName,
	handleSave,
	...rest
}) => {
	const getRecipeName = (id) => {

		try {
			const { data, errors } = apolloQuery(QUERY_RECIPE, { recipeid: id });

			if (data === null || errors) throw errors;

			return data.recipe.name;
		} catch (error) {
			console.log('get recipe errors', error);
			return id;
		}
	};

	const getPreset = () => {
		switch (alertType) {
			case 'delete':
				return {
					color: '#f64e60',
					title: `Delete ${type}`,
					description:
						type === 'model' ? (
							item?.recipeid && item?.recipeid.length>0 ? 
							<>
								{`You include this model in ${item?.recipeid.map(
									(id) => `"${getRecipeName(id)}"`
								)}. By confirming to delete, the mentioned recipes will no longer contain this model.`}
								<br />
								<br />
								{`Are you sure you want to delete "${item?.name || item?.devicename}" ${type}?`}
							</>
							: 
							<>
								{`Are you sure you want to delete "${item?.name || item?.devicename}" ${type}?`}
							</>
						) : (
							`Are you sure you want to delete "${item?.name || item?.devicename}" ${type}?`
						),
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button type="primary" danger key="cf-delete" onClick={() => handleOk(item)} loading={loading}>
							Yes
						</Button>,
					],
				};
			case 'warning':
				return {
					color: '#ffaf02',
					title: 'Warning',
					description: `Any unsaved changes will be lost. Save your changes?`,
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button type="primary" ghost key="cf-warning" onClick={handleOk}>
							Discard
						</Button>,
						<Button type="primary" key="cf-warning" onClick={handleSave}>
							Save
						</Button>,
					],
				};
			case 'modelwarning':
				return {
					color: '#ffaf02',
					title: 'Warning',
					description: `Any unsaved changes will be lost. Discard your changes?`,
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button type="primary" key="cf-warning" onClick={handleOk}>
							Discard
						</Button>,
					],
				};
			case 'draft':
				return {
					color: '#ffaf02',
					title: 'Warning',
					description: `save draft for this "${item.name}"?`,
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							Discard
						</Button>,
						<Button type="primary" key="cf-warning" onClick={handleOk} loading={loading}>
							Yes
						</Button>,
					],
				};
			case 'duplicate':
				return {
					color: '#ffaf02',
					title: 'Warning',
					description: `Are you sure you want to duplicate this "${item.name}" recipe?`,
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button type="primary" key="cf-warning" onClick={() => handleOk(item)}>
							Duplicate
						</Button>,
					],
				};
			case 'unlink':
				return {
					color: '#f64e60',
					title: 'Delete device from recipe',
					description: `This device will be remove from this recipe. After deleted, The device will stay on your device list but with no recipe. Do you want to proceed to delete "${item?.devicename}"?`,
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button type="primary" danger key="cf-warning" onClick={() => handleOk(item)}>
							Yes
						</Button>,
					],
				};
			case 'queue':
				return {
					color: '#f64e60',
					title: 'Cancel Training',
					description: `Are you sure you want to cancel training "${item?.name}" model?`,
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button type="primary" danger key="cf-warning" onClick={() => handleOk(item)}>
							Yes
						</Button>,
					],
				};
			case 'revert':
				return {
					color: '#f64e60',
					title: 'Revert to previous version',
					description: `Are you sure you want revert "${item?.name}" to it's previous version?`,
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button type="primary" danger key="cf-warning" onClick={() => handleOk(item)}>
							Yes
						</Button>,
					],
				};
			case 'movedevice':
				return {
					color: '#f64e60',
					title: 'Add Device to Recipe',
					description: `This device may already contain another recipe.
					Do you want to proceed and replace it with "${recipeName}"?`,
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							Cancel
						</Button>,
						<Button type="primary" danger key="cf-warning" onClick={() => handleOk()}>
							Yes
						</Button>,
					],
				};
			case 'modelsharing':
				return {
					color: '#ffaf02',
					title: 'Pay It Forward',
					description: <div>
					Do you want to share your trained model for others to use as a pretrained model? By clicking Yes, we do not reveal your data AND do not allow anyone else to use your model as is. <a href='https://daysie.io/privacypolicy' target="_blank" rel="noreferrer noopener">See Privacy</a>
					</div>,
					icon: <ExclamationOutlined />,
					btn: [
						<Button type="secondary" key="cancel" onClick={handleCancel}>
							No
						</Button>,
						<Button type="primary" key="cf-warning" onClick={handleOk}>
							Yes
						</Button>,
					],
				};
			default:
				return {
					color: '#ffaf02',
					description: `You exceed the number of ${type} allowed. Please delete existing ${type} to proceed?`,
					icon: <InfoOutlined />,
					btn: [
						<Button type="primary" key="cf-warning" onClick={handleCancel}>
							OK
						</Button>,
					],
				};
		}
	};
	const preset = getPreset();


	return (
		<BaseModal footer={preset.btn} onCancel={handleClose || handleCancel} {...rest}>
			<Row gutter={[10, 10]} wrap={false}>
				<Col>
					<AlertIcon icon={preset.icon} color={preset.color} />
				</Col>
				<Col>
					{alertType && <Title>{preset.title}</Title>}
					<Description>{preset.description}</Description>
				</Col>
			</Row>
		</BaseModal>
	);
};

export default AlertModal;

// ----- style -----
const Title = styled.div`
	text-transform: capitalize;
	font-size: 18px;
	font-weight: 600;
	color: #4d4d4d;
`;
const Description = styled.div``;
