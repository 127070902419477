import { Checkbox, Col, Empty, Input, List, Row, Space } from 'antd';
import { Search } from 'assets/icons';
import React, { useState, useEffect } from 'react';
import BaseModal from './baseModal';
import styled from 'styled-components';

const LinkDeviceModal = ({ devices, visible, onOk, onCancel, loading }) => {
	// state
	const [checkedList, setCheckedList] = useState([]);
	const [DATA, setData] = useState([]);

	useEffect(() => {
		setData(
			devices?.map((device) => {
				return {
					value: device.deviceid,
					label: device.devicename,
					recipe: device.recipename === '' ? 'None' : device.recipename,
				};
			})
		);
	}, [devices]);
	useEffect(() => {
		if (visible === true) setCheckedList([]);
	}, [visible]);

	// func
	const onChange = (checkedValues) => {
		setCheckedList(checkedValues);
	};
	const onSearch = (e) => {
		const val = e.target.value;
		const searchData = devices.filter((x) => x.devicename.toLowerCase().includes(val));

		setData(
			searchData?.map((device) => {
				return {
					value: device.deviceid,
					label: device.devicename,
					recipe: device.recipename === '' ? 'None' : device.recipename,
				};
			})
		);
	};

	return (
		<BaseModal
			visible={visible}
			title="Add new device"
			titlePosition="center"
			onCancel={onCancel}
			onOk={() => onOk(checkedList)}
			confirmLoading={loading}
			okText="Add"
			okButtonProps={{ disabled: checkedList.length === 0 ? true : false }}
		>
			<div style={{ fontSize: '14px', color: '#808080', paddingBottom: '10px' }}>
				<span style={{ color: '#4d4d4d', fontWeight: 600 }}>Add device :</span> Choose device you want to add to
				this recipe.
			</div>
			<StyledInput onChange={onSearch} />
			<List
				header={
					<Row align="middle" justify="space-between">
						<Col>Result {DATA?.length} devices</Col>
						<Col>{checkedList?.length} selected</Col>
					</Row>
				}
				bordered
				style={{ minHeight: 300 }}
			>
				<Checkbox.Group
					style={{ width: '100%', height: 300, overflowY: 'auto', padding: '5px' }}
					onChange={onChange}
					value={checkedList}
				>
					{DATA.length !== 0 ? (
						DATA?.map((item, index) => (
							<ListItem
								key={`device-${item.value}`}
								idx={index}
								active={checkedList.find((cl) => cl === item.value)?.length > 0 ? true : false}
							>
								<Checkbox value={item.value} style={{ alignItems: 'center' }}>
									<Space direction="vertical" size={0} style={{ width: '100%' }}>
										<span>{item.label}</span>
										<span style={{ fontSize: '12px', color: '#808080' }}>
											Edge Recipe: <span style={{ color: '#4d4d4d' }}>{item.recipe}</span>
										</span>
									</Space>
								</Checkbox>
							</ListItem>
						))
					) : (
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
					)}
				</Checkbox.Group>
			</List>
		</BaseModal>
	);
};

export default LinkDeviceModal;

const ListItem = styled(List.Item).attrs({})`
	border-radius: 4px;
	background-color: ${(props) => props.active === true && '#fff8e8'};
	border-bottom: 0 !important;
	padding: 2px;
	margin-top: ${(props) => (props.idx > 0 ? '5px' : 0)};

	&:hover {
		background-color: #fff8e8;
	}
`;
const StyledInput = styled(Input).attrs({
	placeholder: 'Search',
	prefix: <Search />,
})`
	border-radius: 4px;
	margin-bottom: 10px;

	input {
		border: 0 !important;

		&:focus {
			border: 0 !important;
		}
	}
`;
