import { Image } from 'antd';
import React, { useState } from 'react';

const OriginalHeightImage = ({ style, ...rest }) => {
	const [size, setSize] = useState(null);
	return (
		<Image
			height={+size > 140 ? 140 : size}
			onLoad={(event) => {
				setSize(event.target.naturalHeight);
			}}
			style={{ objectFit: 'cover ', ...style }}
			{...rest}
		/>
	);
};

export default OriginalHeightImage;
