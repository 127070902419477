import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/send_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M4.00999997,6.02999998 L11.52,9.24999999 L3.99999997,8.24999998 L4.00999997,6.02999998 M11.51,14.75 L3.99999997,17.97 L3.99999997,15.75 L11.51,14.75 M2.00999996,2.99999996 L1.99999996,9.99999996 L17,12 L1.99999996,14 L2.00999996,21 L23,12 L2.00999996,2.99999996 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Send = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Send;
