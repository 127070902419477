import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

const Home = ({ user }) => {
	const history = useHistory();

	useEffect(() => {
		history.push('/p');
	}, []);

	return (
		<div>
			<h1>Welcome {user?.name} !</h1>
		</div>
	);
};

const mapStateToProps = ({ authReducer }) => ({
	user: authReducer.user,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(Home);
