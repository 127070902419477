import { Form, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { ExclamationCircleFilled } from '@ant-design/icons';

const FormItem = ({ children, error, ...rest }) => {
	return (
		<StyledItem
			{...rest}
			validateStatus={error ? 'error' : null}
			help={
				error ? (
					<StyledSpace>
						<ExclamationCircleFilled />
						{error}
					</StyledSpace>
				) : null
			}
		>
			{children}
		</StyledItem>
	);
};

const StyledItem = styled(Form.Item)`
	position: relative;
	/* margin: 0 0 12px 0;
	padding: 0;

	.ant-form-item-label {
		font-size: 14px;
		font-weight: 500;
		color: #4d4d4d;
		padding: 0;
		width: 100%;

		label {
			width: 100%;
		}
	}

	.ant-form-item-label > label::after {
		content: unset;
	} */
`;
const StyledSpace = styled(Space)`
	display: flex;
	position: absolute;
	width: 100vw;
	padding: 4px 8px;
	margin-top: 4px;
`;
export default FormItem;
