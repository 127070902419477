import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/search_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M15.5,14 L14.71,14 L14.43,13.73 C15.41,12.59 16,11.11 16,9.49999996 C16,5.90999997 13.09,2.99999996 9.49999996,2.99999996 C5.90999997,2.99999996 2.99999996,5.90999997 2.99999996,9.49999996 C2.99999996,13.09 5.90999997,16 9.49999996,16 C11.11,16 12.59,15.41 13.73,14.43 L14,14.71 L14,15.5 L19,20.49 L20.49,19 L15.5,14 Z M9.49999997,14 C7.00999998,14 4.99999997,11.99 4.99999997,9.49999997 C4.99999997,7.00999998 7.00999998,4.99999997 9.49999997,4.99999997 C11.99,4.99999997 14,7.00999998 14,9.49999997 C14,11.99 11.99,14 9.49999997,14 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Search = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Search;
