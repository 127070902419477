import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/cancel_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M12,1.99999996 C6.46999998,1.99999996 1.99999996,6.46999998 1.99999996,12 C1.99999996,17.53 6.46999998,22 12,22 C17.53,22 22,17.53 22,12 C22,6.46999998 17.53,1.99999996 12,1.99999996 Z M17,15.59 L15.59,17 L12,13.41 L8.40999999,17 L6.99999998,15.59 L10.59,12 L6.99999998,8.40999999 L8.40999999,6.99999998 L12,10.59 L15.59,6.99999998 L17,8.40999999 L13.41,12 L17,15.59 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const CloseFilled = (props) => {
	return <Icon {...props} component={icon} />;
};
export default CloseFilled;
