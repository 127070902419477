import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import cookie from 'util/cookie';

const PublicRoute = ({ exact, path, authenticated, component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				// return <Component {...props} />
				return !authenticated || !cookie.get('accessToken') ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/p',
							state: { from: props.location },
						}}
					/>
				);
			}}
		/>
	);
};

const mapStateToProps = ({ authReducer }) => ({
	authenticated: authReducer.authenticated,
});

export default connect(mapStateToProps)(PublicRoute);
