import React from 'react';
import Card from './Card';
import { Button, Typography, Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import { Delete, Edit } from 'assets/icons';

const ProjectListCard = ({ item, handleEdit, handleDelete }) => {
	const history = useHistory();

	const toEdgeList = () => {
		history.push({ pathname: `/p/${item.projectid}/edge`, state: { detail: item } });
	};

	const menu = (
		<StyledMenu>
			<Menu.Item key="0" icon={<Edit />} onClick={handleEdit}>
				Edit
			</Menu.Item>
			<Menu.Divider />
			<Menu.Item danger key="1" icon={<Delete />} onClick={handleDelete}>
				Delete
			</Menu.Item>
		</StyledMenu>
	);

	return (
		<div>
			<Card onClick={toEdgeList}>
				<TopCard>
					<NamePj>{item.name}</NamePj>
					<Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
						<Button
							onClick={(e) => e.stopPropagation()}
							type="text"
							icon={<span className="material-icons-outlined IconMoreHoriz">more_horiz</span>}
							style={{ height: '32px', width: '32px' }}
						/>
					</Dropdown>
				</TopCard>
				<DescPj>{item.description || '-'}</DescPj>
			</Card>
		</div>
	);
};

export default ProjectListCard;

const NamePj = styled.div`
	font-size: 16px;
	font-weight: 500;
	line-height: 1.25;
	letter-spacing: normal;
	color: #333333;
	max-width: 288px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const TopCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const DescPj = styled(Typography.Paragraph).attrs({
	ellipsis: {
		rows: 3,
	},
})`
	margin-top: 5px;
	font-size: 14px;
	font-weight: 300;
	line-height: 1.5;
	letter-spacing: normal;
	color: #4d4d4d;
	word-break: break-all;
	min-height: 63px;
`;

const StyledMenu = styled(Menu)`
	.ant-dropdown-menu-item,
	.ant-dropdown-menu-submenu-title {
		font-size: 14px;
		font-weight: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #4d4d4d;
		padding-left: 20px;
	}

	min-width: 180px;
	padding: 8px 0;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #ffffff;

	.ant-dropdown-menu-item-divider,
	.ant-dropdown-menu-submenu-title-divider {
		background-color: rgba(207, 218, 230, 0.5);
	}
	.ant-dropdown-menu-item:hover,
	.ant-dropdown-menu-submenu-title:hover {
		background-color: #fff8e8;
	}

	.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
		:hover {
			color: #f64e60;
			background-color: rgba(246, 78, 96, 0.14);
			font-weight: normal;
		}
	}
`;
