import { shapeMessage } from 'util/openNotification';

import {
    AUTHENTICATED,
    CHANGE_PASSWORD,
    GET_PROFILE,
    LOGIN,
    RESET_PASSWORD,
    SETUPPASSWORD,
    UNAUTHENTICATED,
	REGISTER,
	RESEND_ACTIVATE,
	ACTIVATE_EMAIL,
	READ_TOKEN,
	UPDATE_PROFILE
} from '../actions/authAction';

const initialState = {
	userid: null,
	username: null,
	token: null,
	loading: false,
	message: null,
	authenticated: false,
	otUser: null,

	otMessage: null,
	forgotMessage: null,
	loginMessage: null,

	changePass: null,

	user: null,

	isActivate: null,
	atvEmail: null,
	atvUsername: null
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
			return { ...state, authenticated: true };
		case UNAUTHENTICATED:
			return initialState;
		case LOGIN.REQUEST:
			return {
				...state,
				loading: true,
				loginMessage: null,
				message: null,
				otMessage: null,
				forgotMessage: null,
			};
		case LOGIN.SUCCESS:
			return {
				...state,
				token: action.payload.user.accessToken,
				userid: action.payload.user.id,
				username: action.payload.user.name,
				authenticated: true,
				loginMessage: null,
				// token:
				// 	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lc3RhcG0iOjE2MjAxODkzNTUyOTcsInVzZXJfaWQiOjUsInJvbGUiOjAsInNjaG9vbF9pZCI6MCwiaXNSZWNvdmVyIjpmYWxzZSwiaWF0IjoxNjIwMTg5MzU1LCJleHAiOjE2MjAyNzU3NTV9.KNMJjy7uZscDgjJwoX_oUBpCHZHYzi6-ODAqX0dJo0U',
				loading: false,
			};
		case LOGIN.FAILURE:
			return {
				...state,
				token: null,
				loading: false,
				loginMessage: shapeMessage(action.type, action.payload.error),
			};
		
		// ---------- SETUP PASSWORD ----------
		case SETUPPASSWORD.REQUEST:
			return {
				...state,
				loading: true,
				message: null
			};
		case SETUPPASSWORD.SUCCESS:
			return {
				...state,
				otMessage: null,
				loading: false,
			};
		case SETUPPASSWORD.FAILURE:
			return {
				...state,
				otMessage: shapeMessage(action.type, action.payload.error),
				loading: false,
			};

		// ---------- RESET PASSWORD ----------
		case RESET_PASSWORD.REQUEST:
			return {
				...state,
				loading: true,
				forgotMessage: null
			};
		case RESET_PASSWORD.SUCCESS:
			return {
				...state,
				forgotMessage: shapeMessage(action.type),
				loading: false,
			};
		case RESET_PASSWORD.FAILURE:
			return {
				...state,
				forgotMessage: shapeMessage(action.type, action.payload.error),
				loading: false,
			};

		// ---------- Change pass ----------
		case CHANGE_PASSWORD.REQUEST:
			return {
				...state,
				loading: true,
				message: null
			};
		case CHANGE_PASSWORD.SUCCESS:
			return {
				...state,
				message: shapeMessage(action.type),
				loading: false,
			};
		case CHANGE_PASSWORD.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};

		case GET_PROFILE.REQUEST:
			return {
				...state,
				loading: true,
				message: null
			};
		case GET_PROFILE.SUCCESS:
			return {
				...state,
				user: action.payload.user,
				loading: false,
			};
		case GET_PROFILE.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error)
			};
		case UPDATE_PROFILE.REQUEST:
			return {
				...state,
				loading: true,
				message: null
			};
		case UPDATE_PROFILE.SUCCESS:
			return {
				...state,
				user: action.payload.user,
				loading: false,
				message: shapeMessage(action.type)
			};
		case UPDATE_PROFILE.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error)
			};
		case REGISTER.REQUEST:
			return {
				...state,
				loading: true,
				message: null
			};
		case REGISTER.SUCCESS:
			return {
				...state,
				message: null,
				loading: false,
			};
		case REGISTER.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error)
			};
		case RESEND_ACTIVATE.REQUEST:
			return {
				...state,
				loading: true,
				message: null
			};
		case RESEND_ACTIVATE.SUCCESS:
			return {
				...state,
				message: shapeMessage(action.type),
				loading: false,
			};
		case RESEND_ACTIVATE.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error)
			};
		case ACTIVATE_EMAIL.REQUEST:
			return {
				...state,
				loading: true,
				isActivate: null,
				atvEmail: null,
				atvUsername: null,
			};
		case ACTIVATE_EMAIL.SUCCESS:
			return {
				...state,
				isActivate: true,
				loading: false,
				atvEmail: action.payload.res.email,
				atvUsername: action.payload.res.username
			};
		case ACTIVATE_EMAIL.FAILURE:
			return {
				...state,
				loading: false,
				isActivate: false
			};
		case READ_TOKEN.REQUEST:
			return {
				...state,
				loading: true,
				atvEmail: null,
				atvUsername: null,
				isActivate: null

			};
		case READ_TOKEN.SUCCESS:
			return {
				...state,
				loading: false,
				atvEmail: action.payload.res.email,
				atvUsername: action.payload.res.username,
				isActivate: true

			};
		case READ_TOKEN.FAILURE:
			return {
				...state,
				loading: false,
				otMessage: null,
				isActivate: false

			};

		default:
			return state;
	}
};

export default authReducer;
