import { ArrowRightOutlined, MoreOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Image, Menu, Progress, Row, Space, Upload, Alert } from 'antd';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { predictValue, previewModel, clearPredictResult, clearPredictConfig } from 'reduxStore/actions/modelAction';
import styled from 'styled-components';
import AudioUpload from 'assets/images/audio@2x.png';
import CSVUpload from 'assets/images/csv@2x.png';
import CSVIcon from 'assets/images/csvicon@2x.png';
import ImageUpload from 'assets/images/img@2x.png';
import AudioPlayer from 'components/etc/AudioPlayer';
import BaseModal from './baseModal';

const PreviewModal = ({
	previewModel,
	clearPredictResult,
	clearPredictConfig,
	visible,
	item,
	config,
	result,
	predicting,
	predictValue,
	...rest
}) => {
	const [file, setFile] = useState(null);
	const [fileUpload, setFileUpload] = useState(null);
	const [fileOver, setFileOverLimit] = useState(false);
	const [canPredict, setCanPredict] = useState(true);

	const resetState = useCallback(() => {
		setFile(null);
		setFileUpload(null);
		setFileOverLimit(false);
		setCanPredict(true);
		clearPredictResult();
		clearPredictConfig();
	}, [clearPredictResult]);

	useEffect(() => {
		if (!visible) {
			resetState();
		}
	}, [visible, resetState]);

	useState(() => {
		clearPredictResult();
	}, [file]);

	useEffect(() => {
		if (visible && item) {
			previewModel(item.modelid);
		}
	}, [visible, item, previewModel]);

	useEffect(() => {
		clearPredictResult();

		if (!file) {
			setCanPredict(true);
		}
	}, [file]);

	const getAcceptedType = useMemo(() => {
		if (!config) return { image: null, type: '', accepted_type: '' };

		const splitedType = config?.fileType.split(', ');
		const imageTypes = ['JPG', 'JPEG', 'PNG'];
		const audioTypes = ['WAV', 'MP4', 'M4A', 'MP3'];
		const acceptedType = splitedType.map((type) => `.${type.toLowerCase()}`).join(',');

		let typeDetails;
		if (imageTypes.some((type) => splitedType.includes(type))) {
			typeDetails = { image: ImageUpload, type: 'image' };
		} else if (audioTypes.some((type) => splitedType.includes(type))) {
			typeDetails = { image: AudioUpload, type: 'audio' };
		} else {
			typeDetails = { image: CSVUpload, type: 'other' };
		}

		return { ...typeDetails, accepted_type: acceptedType };
	}, [config]);

	const getBase64 = (img, callback) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	};

	const uploadProps = {
		name: 'file',
		multiple: false,
		showUploadList: false,
		beforeUpload: (file) => {
			getBase64(file, (imageUrl) => {
				setFile({ src: imageUrl, type: file.type, name: file.name });
				setFileUpload(file);
				setCanPredict(true);
			});

			const isFileOver = file.size / 1024 / 1024 < parseFloat(config?.maxSize);
			setFileOverLimit(!isFileOver);

			return false;
		},
		style: { maxHeight: '220px' },
		accept: getAcceptedType.accepted_type,
	};

	const renderUploadFile = useCallback(() => {
		switch (getAcceptedType.type) {
			case 'image':
				return (
					<ImageContainer>
						<Image src={file?.src} style={{ objectFit: 'contain', maxHeight: 220 }} />
						<Dropdown
							overlay={
								<Menu>
									<Upload {...uploadProps}>
										<Menu.Item>Change file</Menu.Item>
									</Upload>
									<Menu.Item danger onClick={() => setFile(null)}>
										Delete
									</Menu.Item>
								</Menu>
							}
							trigger={['click']}
							placement="bottomRight"
						>
							<Button
								type="text"
								icon={<MoreOutlined />}
								style={{ position: 'absolute', top: 5, right: 5 }}
							/>
						</Dropdown>
					</ImageContainer>
				);
			case 'audio':
				return (
					<AudioPlayer file={file} onDelete={() => setFile(null)}>
						<Upload {...uploadProps}>
							<Button type="secondary">Change file</Button>
						</Upload>
					</AudioPlayer>
				);
			default:
				return (
					<PreviewContainer>
						<Image src={CSVIcon} preview={false} width={100} />
						<div>{file.name}</div>
						<Space>
							<Upload {...uploadProps}>
								<Button type="secondary">Change file</Button>
							</Upload>
							<Button type="text" onClick={() => setFile(null)}>
								<span style={{ color: '#f64e60' }}>Delete</span>
							</Button>
						</Space>
					</PreviewContainer>
				);
		}
	}, [file, uploadProps, getAcceptedType.type]);

	const handlePredict = () => {
		predictValue(fileUpload, config?.modelid);
		setCanPredict(false);
	};

	return (
		<StyledModal visible={visible} {...rest} isImage={!!file}>
			<Row gutter={[20, 20]}>
				<Col span={12}>
					<Info>Attach file</Info>
					<Space direction="vertical" style={{ width: '100%' }}>
						{fileOver && (
							<Alert
								message={`File must be smaller than ${config?.maxSize.toUpperCase()}`}
								showIcon
								closable
								type="error"
							/>
						)}
						{file ? (
							<Dragger isHaveFile={file} {...uploadProps}>
								{renderUploadFile()}
							</Dragger>
						) : (
							<Upload.Dragger {...uploadProps}>
								<p className="ant-upload-drag-icon">
									<Image src={getAcceptedType.image} preview={false} width={100} />
								</p>
								<p className="ant-upload-text">Drag and Drop to predict</p>
								<p className="ant-upload-hint">Maximum file size: {config?.maxSize.toUpperCase()}</p>
								<p className="ant-upload-hint">
									or <span style={{ color: '#ffaf02', fontWeight: 600 }}>Browse file</span>
								</p>
							</Upload.Dragger>
						)}
					</Space>
					<Info>
						Accepted file type: <span>{config?.fileType}</span>
					</Info>
					<PredictBtnContainer>
						{canPredict ? (
							<Button
								type="primary"
								onClick={handlePredict}
								loading={predicting}
								disabled={!file || fileOver || predicting}
							>
								Predict <ArrowRightOutlined />
							</Button>
						) : (
							<Upload {...uploadProps}>
								<Button type="primary" icon={<UploadOutlined />}>
									Upload New File
								</Button>
							</Upload>
						)}
						{!canPredict && (
							<Alert
								message="You can predict only once per uploaded file. Please upload a new file to predict again."
								type="info"
								showIcon
								style={{ marginTop: 10 }}
							/>
						)}
					</PredictBtnContainer>
				</Col>
				<Col span={12}>
					<Space direction="vertical" style={{ width: '100%' }}>
						<div>
							<Title>ML Model info</Title>
							<Info>
								Name: <span>{config?.name}</span>
							</Info>
							<Info>
								Description: <span>{config?.description}</span>
							</Info>
						</div>
						<div>
							<Title>Prediction results</Title>
						</div>
						<div style={{ maxHeight: 250, overflowY: 'auto', paddingRight: 5 }}>
							{config?.modelclass?.map((item, index) => (
								<div key={`modelclass-${index}`}>
									<Info>
										<span>
											{item} : {result ? result[index] : '-'}
										</span>
									</Info>
									<Progress
										percent={(result?.[index] / 150) * 100}
										strokeColor="#ffaf02"
										showInfo={false}
									/>
								</div>
							))}
						</div>
					</Space>
				</Col>
			</Row>
		</StyledModal>
	);
};

const mapStateToProps = ({ modelReducer }) => ({
	config: modelReducer.preview,
	predicting: modelReducer.predicting,
	result: modelReducer.result,
});

const mapDispatchToProps = {
	previewModel: previewModel.request,
	predictValue: predictValue.request,
	clearPredictResult,
	clearPredictConfig,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal);

const StyledModal = styled(BaseModal).attrs({
	title: 'Test ML Model',
	titlePosition: 'left',
	footer: null,
})`
	min-width: 964px;
	${({ isImage }) =>
		!isImage &&
		`
        .ant-modal-content {
		    padding-bottom: 60px;
	    }
    `}
`;

const Title = styled.div`
	font-size: 14px;
	font-weight: 600;
	color: #4d4d4d;
`;

const Info = styled.div`
	font-size: 14px;
	font-weight: 500;
	color: #808080;
	margin-top: 10px;

	span {
		color: #4d4d4d;
	}
`;

const PredictBtnContainer = styled.div`
	text-align: center;
	margin: 20px 0;
`;

const ImageContainer = styled.div`
	border: 1px solid #80808080;
	border-radius: 6px;
	position: relative;
	overflow: hidden;
	// max-height: 220px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const PreviewContainer = styled.div`
	width: 100%;
	height: 220px;
	border: 1px solid #80808080;
	border-radius: 6px;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const Dragger = styled(Upload.Dragger).attrs({
	openFileDialogOnClick: false,
	style: {
		border: '1px solid #fff',
		background: '#fff',
	},
})`
	.ant-upload-btn {
		padding: 0px !important;
	}
`;
