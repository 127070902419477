import React from 'react';
import { Layout, Button, Menu, Space } from 'antd';
import styled from 'styled-components';
import logotext from 'assets/images/logo-with-text.png';
import { Link } from 'react-router-dom';

const PublicContainer = ({ children, isLogin }) => {
	const onClickLink = (link) => {
		window.open(link, '_blank');
	};

	return (
		<Layout style={{ minHeight: '100vh' }}>
			<StyledHeader>
				<Left>
					<Link to="/">
						<img src={logotext} alt="Daysie Logo" style={{ height: 40 }} />
					</Link>
				</Left>
				<Right>
					<Space>
						<Menu mode="horizontal">
							<Menu.Item key="home" onClick={() => onClickLink('https://daysie.io')}>
								Home
							</Menu.Item>
							{/* <Menu.Item key="tutorial" onClick={() => onClickLink("https://daysie.io/tutorial")}>Tutorial</Menu.Item> */}
						</Menu>
						<Link to={isLogin ? '/register' : '/login'}>
							<Button type="primary">{isLogin ? 'Register' : 'Login'}</Button>
						</Link>
					</Space>
				</Right>
			</StyledHeader>
			<StyledContent>{children}</StyledContent>
			<StyledFooter>Copyright © 2022 NECTEC</StyledFooter>
		</Layout>
	);
};

export default PublicContainer;

const StyledHeader = styled(Layout.Header)`
	display: flex;
	align-items: center;
	flex-direction: row;
	box-shadow: 0 1px 3px 0 rgba(199, 210, 243, 0.4);
	background-color: #fefeff;
	padding: 20px 32px;

	.ant-menu-horizontal > .ant-menu-submenu {
		top: -1px;
	}
`;

const Left = styled.div`
	display: flex;
	flex: 1;
	max-height: 64px;
	justify-content: flex-start;
	align-items: center;
`;

const Right = styled(Left)`
	justify-content: flex-end;
`;

const StyledContent = styled(Layout.Content)`
	background-color: rgba(245, 247, 251, 0.6);
	padding: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const StyledFooter = styled(Layout.Footer)`
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 0.5px;
	text-align: center;
	color: #b3b3b3;
	min-height: 50px;
	background-color: #fff;
	padding: 16px 0;
`;
