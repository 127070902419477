import React from 'react';
import { Menu } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const MenuRight = () => {
	const location = useLocation();
	const mainPath = `/${location.pathname.split('/')[1]}`;

	const onClickLink = (link) => {
		window.open(link);
	};

	const menu = [
		{ key: 'home', onclick: (e) => onClickLink('https://daysie.io/'), label: 'Home' },
		{ routeTo: '/p', label: 'My Project' },
		{ routeTo: '/d', label: 'My device' },
		{ routeTo: '/m', label: 'ML Model' },
	];

	const getMenu = (item) => {
		if (item.routeTo) {
			return (
				<Menu.Item key={item.routeTo || item.key}>
					<Link to={item.routeTo}>{item.label}</Link>
				</Menu.Item>
			);
		} else {
			return (
				<Menu.Item key={item.key} onClick={(e) => item.onclick()}>
					{item.label}
				</Menu.Item>
			);
		}
	};

	return (
		<StyledMenu selectedKeys={[mainPath]} mode="horizontal">
			{menu.map((item, index) => getMenu(item))}
		</StyledMenu>
	);
};

export default MenuRight;

const StyledMenu = styled(Menu)`
	width: 100%;
	justify-content: flex-end !important;
	.ant-menu-item-selected a,
	.ant-menu-item-selected a:hover {
		color: #333333;
	}

	.ant-menu-horizontal > .ant-menu-item a:hover {
		color: #333333 !important;
	}
	@media only screen and (max-width: 500px) {
		display: none;
	}
`;
