import React from 'react';
import styled, { css } from 'styled-components';

const StatusCard = ({ children, status, ...rest }) => {
	return (
		<Status status={status} {...rest}>
			{children ? children : status ? status?.replace('-', ' ') : null}
		</Status>
	);
};

export default StatusCard;

const Status = styled.span`
	height: 20px;
	max-width: 100%;
	border-radius: 10px;
	font-size: 10px;
	font-weight: 600;
	letter-spacing: 0.5px;
	text-align: center;
	padding: 2px 9.5px 3px;
	margin-bottom: 5px;
	color: #fff;
	text-transform: capitalize;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	${(props) =>
		props.status === 'edge' &&
		css`
			padding: 2px 5px 3px;
			color: #4d4d4d;
			background-color: rgba(255, 175, 2, 0.14);
		`}
	${(props) =>
		(props.status === 'completed' || props.status === 'complete' || props.status === 'ready') &&
		css`
			color: #fff;
			background-color: rgba(82, 196, 26, 0.95);
		`}
	${(props) =>
		props.status === 'draft' &&
		css`
			color: #808080;
			background-color: #f2f2f2;
		`}
	${(props) =>
		(props.status === 'training' || props.status === 'uploading') &&
		css`
			background-color: rgba(54, 153, 255, 0.95);
		`}
	${(props) =>
		props.status === 'queuing' &&
		css`
			background-color: rgba(255, 168, 0, 0.95);
		`}
	${(props) =>
		props.status === 'waiting' &&
		css`
			color: #fa8c16;
			background-color: #feefde;
		`}
		
		${(props) =>
		(props.status === 'upload-failed' || props.status === 'train-failed') &&
		css`
			color: #feefde;
			background-color: #f64e60;
		`}
`;
