import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/arrow_back_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<polygon
				id="Combined-Shape"
				fill="currentColor"
				points="20 11 7.82999998 11 13.42 5.40999997 12 3.99999997 3.99999997 12 12 20 13.41 18.59 7.82999998 13 20 13"
			></polygon>
		</g>
	</svg>
);

const ArrowLeft = (props) => {
	return <Icon {...props} component={icon} />;
};
export default ArrowLeft;
