import { Input, Select } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import FormItem from '../formItem';

const DeviceForm = ({
	formProps: {
		control,
		formState: { errors },
	},
	device,
	options,
}) => {
	return (
		<>
			<FormItem label="Name" error={errors?.name?.message}>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <Input placeholder="Enter your edge name" {...field} />}
				/>
			</FormItem>
			<FormItem label="Edge Recipe" error={errors?.recipeId?.message}>
				<Controller
					name="recipeId"
					control={control}
					render={({ field }) => (
						<Select
							showSearch
							placeholder="Select Edge Recipe"
							optionFilterProp="children"
							filterOption={(input, option) =>
								option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
							}
							value={device?.recipeid}
							{...field}
						>
							<Select.Option value="">None</Select.Option>
							{options.map((item) => (
								<Select.Option value={item.value} key={item.value}>
									{item.label}
								</Select.Option>
							))}
						</Select>
					)}
				/>
			</FormItem>
		</>
	);
};

export default DeviceForm;
