import { Checkbox, Col, Divider, Row, Select, Button, Tag, Radio, InputNumber } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';
import FormItem from '../formItem';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

const LocalConfigForm = ({
	formProps: {
		control,
		formState: { errors },
	},
	config,
	models,
	onBuildModel,
}) => {
	const tagRender = (props) => {
		const { label, closable, onClose } = props;
		const onPreventMouseDown = (event) => {
			event.preventDefault();
			event.stopPropagation();
		};
		return (
			<Tag
				color={'rgba(255, 168, 0, 0.8)'}
				onMouseDown={onPreventMouseDown}
				closable={closable}
				onClose={onClose}
				closeIcon={<CloseOutlined style={{ color: '#333333' }} />}
				style={{ marginRight: 3, fontSize: '15px', color: '#333333' }}
			>
				{label}
			</Tag>
		);
	};

	return (
		<>
			{/* todo get real data of edge client no, hardware */}
			<FormItem label="No. of Edge Clients" error={errors?.edgeClientsNo?.message}>
				<Controller
					name="edgeClientsNo"
					control={control}
					defaultValue={config?.edgeClientNo || 1}
					render={({ field }) => <InputNumber min={1} max={2} style={{ borderRadius: 4 }} {...field} />}
				/>
			</FormItem>
			<FormItem label="Hardware" error={errors?.hardware?.message}>
				<Controller
					name="hardware"
					control={control}
					defaultValue={config?.hardware || ''}
					render={({ field }) => (
						<Radio.Group {...field}>
							<Radio value={'ARM64'}>ARM64</Radio>
							<Radio value={'AMD'}>AMD</Radio>
						</Radio.Group>
					)}
				/>
			</FormItem>
			<FormItem label="Protocol">
				<Controller
					name="protocol"
					control={control}
					defaultValue={config?.protocol || []}
					render={({ field }) => (
						<Checkbox.Group
							{...field}
							options={[
								{ label: 'MQTT', value: 'MQTT' },
								{ label: 'Modbus', value: 'Modbus' },
								{ label: 'HTTP', value: 'HTTP' },
								// { label: 'Websocket', value: 'Websocket' },
							]}
						/>
					)}
				/>
			</FormItem>
			<FormItem label="Data Storage and Visualization">
				<Controller
					name="database"
					control={control}
					defaultValue={config?.edgeconfig?.database || false}
					render={({ field: { value, ...rest } }) => (
						<Checkbox checked={value} {...rest}>
							InfluxDB
						</Checkbox>
					)}
				/>
				<br />
				<Controller
					name="visualization"
					control={control}
					defaultValue={config?.edgeconfig?.visualization || false}
					render={({ field: { value, ...rest } }) => (
						<Checkbox checked={value} {...rest}>
							Grafana
						</Checkbox>
					)}
				/>
			</FormItem>
			<Divider />
			<FormItem
				label={
					<Row align="middle" justify="space-between" style={{ width: '100%' }}>
						<Col>ML boxes</Col>
						<Col>
							<Button type="link" onClick={onBuildModel}>
								<PlusOutlined /> Build new ML model
							</Button>
						</Col>
					</Row>
				}
			>
				<Controller
					name="mlbox"
					control={control}
					defaultValue={config?.edgeconfig?.mlBox || []}
					render={({ field }) => (
						<Select
							mode="multiple"
							placeholder="select your existing models"
							showArrow
							tagRender={tagRender}
							{...field}
						>
							{/* todo check model status === 1 */}
							{models
								?.filter((model) => model.status === 1)
								.map((model) => (
									<Select.Option value={model.modelid}>{model.name}</Select.Option>
								))}
						</Select>
					)}
				/>
			</FormItem>
		</>
	);
};

export default LocalConfigForm;
