import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'antd';
import FormItem from '../formItem';
import styled from 'styled-components';

const SetupPasswordFrom = ({
	formProps: {
		control,
		formState: { errors },
	},
}) => {
	return (
		<div>
			<FormItem label="Password" error={errors?.password?.message}>
				<Controller
					name="password"
					control={control}
					render={({ field }) => <InputPass placeholder="Enter your password" {...field} />}
				/>
			</FormItem>
			<FormItem label="Confirm Password" error={errors?.confirmpassword?.message}>
				<Controller
					name="confirmpassword"
					control={control}
					render={({ field }) => <InputPass placeholder="Enter your password" {...field} />}
				/>
			</FormItem>
		</div>
	);
};

export default SetupPasswordFrom;

const InputPass = styled(Input.Password)`
	.ant-input {
		border: unset !important;
		box-shadow: unset !important;
		-webkit-box-shadow: unset !important;
	}
	border-radius: 5px !important;
`;
