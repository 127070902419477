import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import FormItem from '../formItem';

const ChangePassForm = ({ control, errors, authError }) => {

	return (
		<div>
			<FormItem label="Current password" error={errors?.currentpassword?.message || authError?.currentpassword?.message}>
				<Controller
					name="currentpassword"
					control={control}
					render={({ field }) => <InputPass placeholder=" Enter your current password" {...field} />}
				/>
			</FormItem>

			<FormItem label="New password" error={errors?.newpassword?.message}>
				<Controller
					name="newpassword"
					control={control}
					render={({ field }) => <InputPass placeholder=" Enter your current new password" {...field} />}
				/>
			</FormItem>

			<FormItem label="Confirm New Password" error={errors?.confirmpassword?.message}>
				<Controller
					name="confirmpassword"
					control={control}
					render={({ field }) => (
						<InputPass placeholder=" Enter your current confirm new password" {...field} />
					)}
				/>
			</FormItem>
		</div>
	);
};

export default ChangePassForm;

const InputPass = styled(Input.Password)`
	.ant-input {
		border: unset !important;
		box-shadow: unset !important;
		-webkit-box-shadow: unset !important;
	}
	border-radius: 5px !important;
`;
