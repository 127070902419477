import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_PROJECTS = createTypes('project', 'list');
const LOAD_PROJECTS_WITH_RECIPE = createTypes('projects with recipe', 'load');
const LOAD_PROJECT = createTypes('project', 'show');

const CREATE_PROJECT = createTypes('project', 'create');
const UPDATE_PROJECT = createTypes('project', 'update');
const DELETE_PROJECT = createTypes('project', 'delete');

const loadProjects = {
	request: () => createAction(LOAD_PROJECTS.REQUEST, {}),
	success: (projects) => createAction(LOAD_PROJECTS.SUCCESS, { projects }),
	failure: (error) => createAction(LOAD_PROJECTS.FAILURE, { error }),
};
const loadProjectsWithRecipe = {
	request: () => createAction(LOAD_PROJECTS_WITH_RECIPE.REQUEST, {}),
	success: (projects) => createAction(LOAD_PROJECTS_WITH_RECIPE.SUCCESS, { projects }),
	failure: (error) => createAction(LOAD_PROJECTS_WITH_RECIPE.FAILURE, { error }),
};
const loadProject = {
	request: (projectid) => createAction(LOAD_PROJECT.REQUEST, { projectid }),
	success: (project) => createAction(LOAD_PROJECT.SUCCESS, { project }),
	failure: (error) => createAction(LOAD_PROJECT.FAILURE, { error }),
};
const createProject = {
	request: (project) => createAction(CREATE_PROJECT.REQUEST, { project }),
	success: (projects) => createAction(CREATE_PROJECT.SUCCESS, { projects }),
	failure: (error) => createAction(CREATE_PROJECT.FAILURE, { error }),
};
const updateProject = {
	request: (project) => createAction(UPDATE_PROJECT.REQUEST, { project }),
	success: (projects) => createAction(UPDATE_PROJECT.SUCCESS, { projects }),
	failure: (error) => createAction(UPDATE_PROJECT.FAILURE, { error }),
};
const deleteProject = {
	request: (project) => createAction(DELETE_PROJECT.REQUEST, { project }),
	success: (projects) => createAction(DELETE_PROJECT.SUCCESS, { projects }),
	failure: (error) => createAction(DELETE_PROJECT.FAILURE, { error }),
};

export {
	LOAD_PROJECTS,
	loadProjects,
	LOAD_PROJECTS_WITH_RECIPE,
	loadProjectsWithRecipe,
	LOAD_PROJECT,
	loadProject,
	CREATE_PROJECT,
	createProject,
	UPDATE_PROJECT,
	updateProject,
	DELETE_PROJECT,
	deleteProject,
};
