import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/touch_app_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M18.19,12.44 L14.95,10.82 C16.24,9.81999999 17.07,8.25999998 17.07,6.49999995 C17.07,3.46999996 14.6,0.999999954 11.57,0.999999954 C8.53999999,0.999999954 6.06999998,3.46999996 6.06999998,6.49999995 C6.06999998,8.62999999 7.28999998,10.48 9.06999999,11.39 L9.06999999,14.65 C6.91999998,14.19 7.04999998,14.21 6.80999998,14.21 C6.27999998,14.21 5.77999997,14.42 5.39999997,14.8 L3.99999997,16.22 L9.08999999,21.31 C9.51999999,21.75 10.12,22 10.74,22 L17.04,22 C18.02,22 18.85,21.3 19.01,20.33 L19.81,15.62 C20.03,14.32 19.38,13.04 18.19,12.44 Z M17.84,15.29 L17.04,20 L10.74,20 C10.65,20 10.57,19.96 10.5,19.9 L6.81999998,16.22 L11.07,17.11 L11.07,6.49999998 C11.07,6.21999998 11.29,5.99999998 11.57,5.99999998 C11.85,5.99999998 12.07,6.21999998 12.07,6.49999998 L12.07,12.5 L13.83,12.5 L17.29,14.23 C17.69,14.43 17.91,14.86 17.84,15.29 Z M8.06999998,6.49999996 C8.06999998,4.56999997 9.63999999,2.99999996 11.57,2.99999996 C13.5,2.99999996 15.07,4.56999997 15.07,6.49999996 C15.07,7.44999998 14.69,8.30999998 14.07,8.93999999 L14.07,6.49999996 C14.07,5.11999997 12.95,3.99999996 11.57,3.99999996 C10.19,3.99999996 9.06999998,5.11999997 9.06999998,6.49999996 L9.06999998,8.93999999 C8.44999999,8.30999998 8.06999998,7.44999998 8.06999998,6.49999996 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Touch = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Touch;
