import { Input, InputNumber, Select, Row, Col } from 'antd';
import { Controller } from 'react-hook-form';
import FormItem from '../formItem';

const ConfigModelForm = ({
	formProps: {
		control,
		formState: { errors },
	},
	modelType,
}) => {
	const isThreeCol = modelType !== 2;

	return (
		<Row gutter={[8, 0]}>
			<Col xs={24} sm={isThreeCol ? 12 : 24} md={isThreeCol ? 8 : 12}>
				<FormItem label="Epoch" error={errors?.epoch?.message}>
					<Controller
						name="epoch"
						control={control}
						render={({ field }) => (
							<InputNumber
								style={{ width: '100%', borderRadius: 4 }}
								{...field}
								onChange={(value) => field.onChange(parseInt(value))}
							/>
						)}
					/>
				</FormItem>
			</Col>
			<Col xs={24} sm={isThreeCol ? 12 : 24} md={isThreeCol ? 8 : 12}>
				<FormItem label="Batch size" error={errors?.batch?.message}>
					<Controller
						name="batch"
						control={control}
						render={({ field }) => (
							<Select {...field} style={{ width: '100%' }}>
								<Select.Option value={16}>16</Select.Option>
								<Select.Option value={32}>32</Select.Option>
								<Select.Option value={64}>64</Select.Option>
							</Select>
						)}
					/>
				</FormItem>
			</Col>
			{modelType === 1 ? (
				<Col xs={24} sm={12} md={8}>
					<FormItem label="Learning rate" error={errors?.rate?.message}>
						<Controller
							name="rate"
							control={control}
							render={({ field }) => (
								<InputNumber
									style={{ width: '100%', borderRadius: 4 }}
									{...field}
									min="0.0001"
									max="0.001"
									step="0.0001"
									onChange={(value) => field.onChange(value)}
									stringMode
								/>
							)}
						/>
					</FormItem>
				</Col>
			) : modelType === 2 ? null : (
				<Col xs={24} sm={12} md={8}>
					<FormItem label="Window Size" error={errors?.windowSize?.message}>
						<Controller
							name="windowSize"
							control={control}
							render={({ field }) => (
								<Input {...field} onChange={(e) => field.onChange(parseInt(e.target.value))} />
							)}
						/>
					</FormItem>
				</Col>
			)}
		</Row>
	);
};

export default ConfigModelForm;
