import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/hdr_weak_black_24dp" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
			<path
				d="M12,7 C9.24166668,7 7,9.24166668 7,12 C7,14.7583334 9.24166668,17 12,17 C14.7583334,17 17,14.7583334 17,12 C17,9.24166668 14.7583334,7 12,7 Z M12,15.3333334 C10.1583333,15.3333334 8.66666667,13.8416667 8.66666667,12 C8.66666667,10.1583333 10.1583333,8.66666667 12,8.66666667 C13.8416667,8.66666667 15.3333334,10.1583333 15.3333334,12 C15.3333334,13.8416667 13.8416667,15.3333334 12,15.3333334 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const SmallCircle = (props) => {
	return <Icon {...props} component={icon} />;
};
export default SmallCircle;
