import gql from 'graphql-tag';

const DEVICE_FRAGMENT = gql`
	fragment device on device {
		deviceid
		devicename
		devicetoken
		recipename
		recipeid
	}
`;

const QUERY_DEVICES = gql`
	query devices($ids: [ID!]!) {
		devices(filters: $ids) {
			...device
		}
	}
	${DEVICE_FRAGMENT}
`;

const QUERY_DEVICE = gql`
	query device($id: ID!) {
		device(id: $id) {
			...device
		}
	}
	${DEVICE_FRAGMENT}
`;

const CREATE_DEVICE = gql`
	mutation addDevice($devicename: String!, $recipeid: String!) {
		addDevice(devicename: $devicename, recipeid: $recipeid) {
			...device
		}
	}
	${DEVICE_FRAGMENT}
`;

const UPDATE_DEVICE = gql`
	mutation updatedevice($deviceid: ID!, $devicename: String!, $recipeid: String!) {
		updatedevice(deviceid: $deviceid, devicename: $devicename, recipeid: $recipeid) {
			...device
		}
	}
	${DEVICE_FRAGMENT}
`;

const DELETE_DEVICE = gql`
	mutation deleteDevice($deviceid: ID!) {
		deleteDevice(deviceid: $deviceid) {
			...device
		}
	}
	${DEVICE_FRAGMENT}
`;

const LINK_DEVICE = gql`
	mutation linkDevice($recipeid: ID!, $ids: [ID!]!) {
		linkDevice(recipeid: $recipeid, deviceid: $ids) {
			...device
		}
	}
	${DEVICE_FRAGMENT}
`;

const UNLINK_DEVICE = gql`
	mutation unlinkDevice($recipeid: ID!, $ids: [ID!]!) {
		unlinkDevice(recipeid: $recipeid, deviceid: $ids) {
			...device
		}
	}
	${DEVICE_FRAGMENT}
`;

export { QUERY_DEVICES, QUERY_DEVICE, CREATE_DEVICE, UPDATE_DEVICE, DELETE_DEVICE, LINK_DEVICE, UNLINK_DEVICE };
