import { ApolloClient, InMemoryCache } from '@apollo/client';
import { from } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { createUploadLink } from 'apollo-upload-client';
import cookie from 'util/cookie';
import { signOut, UNAUTHENTICATED } from '../../reduxStore/actions/authAction';
import { store } from '../../reduxStore/configureStore';

const errorLink = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		console.log('graphQLErrors', graphQLErrors);
		//ถ้า auth error ลองมาเช็ค signout ตรงนี้//
	}
	if (networkError) {
		console.log('networkError', networkError);
	}
});

// const endPointMiddleware = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL });Ò
const httpLink = createUploadLink({
	uri: process.env.REACT_APP_GRAPHQL,
});

const checkAuthorization = setContext(async () => {
	const access_token = await cookie.get('accessToken');

	if (!access_token || access_token === undefined || access_token === 'undefined') {
		store.dispatch(signOut());
		return store.dispatch({ type: UNAUTHENTICATED });
	}
});

const setAuthorizationLink = setContext(async () => {
	const access_token = await cookie.get('accessToken');
	return { headers: { authorization: `Bearer ${access_token}`, 'X-Custom-Header': 'foo' } };
});

const client = new ApolloClient({
	link: from([checkAuthorization, setAuthorizationLink, errorLink, httpLink]),
	cache: new InMemoryCache(),
});

const apolloQuery = async (query, variables) => {
	return await client.query({
		query,
		variables,
		fetchPolicy: 'network-only',
		errorPolicy: 'all',
		context: { timeout: 30000 },
	});
};

const apolloMutation = async (mutation, variables) => {
	return await client.mutate({
		mutation,
		variables,
		errorPolicy: 'all',
		fetchPolicy: 'no-cache',
	});
};

export { client, apolloQuery, apolloMutation };
