import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_MODELS = createTypes('models', 'list');
const LOAD_MODEL = createTypes('model', 'list');
const LOAD_TYPE = createTypes('model type', 'list');
const LOAD_SUBTYPE = createTypes('model sub-type', 'list');
const CREATE_MODEL = createTypes('model', 'create');
const UPDATE_MODEL = createTypes('model', 'update');
const CONFIG_MODEL = createTypes('model', 'config');
const TRAIN_MODEL = createTypes('model', 'train');
const CANCEL_QUEUE = createTypes('queue', 'cancel');
const DELETE_MODEL = createTypes('model', 'delete');
const PREVIEW_MODEL = createTypes('model', 'preview');
const PREDICT_VALUE = createTypes('value', 'predict');
const CLEAR_PREDICT_RESULT = 'CLEAR_PREDICT_RESULT';
const CLEAR_PREDICT_CONFIG = 'CLEAR_PREDICT_CONFIG';
const UPLOAD_TRAINING_FILE = createTypes('upload', 'file');

const loadModels = {
	request: () => createAction(LOAD_MODELS.REQUEST, {}),
	success: (models) => createAction(LOAD_MODELS.SUCCESS, { models }),
	failure: (error) => createAction(LOAD_MODELS.FAILURE, { error }),
};

const loadModel = {
	request: (modelid) => createAction(LOAD_MODEL.REQUEST, { modelid }),
	success: (model) => createAction(LOAD_MODEL.SUCCESS, { model }),
	failure: (error) => createAction(LOAD_MODEL.FAILURE, { error }),
};

const loadTypes = {
	request: () => createAction(LOAD_TYPE.REQUEST, {}),
	success: (types) => createAction(LOAD_TYPE.SUCCESS, { types }),
	failure: (error) => createAction(LOAD_TYPE.FAILURE, { error }),
};

const loadSubtypes = {
	request: () => createAction(LOAD_SUBTYPE.REQUEST, {}),
	success: (subtypes) => createAction(LOAD_SUBTYPE.SUCCESS, { subtypes }),
	failure: (error) => createAction(LOAD_SUBTYPE.FAILURE, { error }),
};

const createModel = {
	request: (model) => createAction(CREATE_MODEL.REQUEST, { model }),
	success: (models) => createAction(CREATE_MODEL.SUCCESS, { models }),
	failure: (error) => createAction(CREATE_MODEL.FAILURE, { error }),
};

const updateModel = {
	request: (model) => createAction(UPDATE_MODEL.REQUEST, { model }),
	success: (models) => createAction(UPDATE_MODEL.SUCCESS, { models }),
	failure: (error) => createAction(UPDATE_MODEL.FAILURE, { error }),
};

const configModel = {
	request: (model) => createAction(CONFIG_MODEL.REQUEST, { model }),
	success: (model, models) => createAction(CONFIG_MODEL.SUCCESS, { model, models }),
	failure: (error) => createAction(CONFIG_MODEL.FAILURE, { error }),
};

const trainModel = {
	request: (modelid, share) => createAction(TRAIN_MODEL.REQUEST, { modelid, share }),
	success: (models) => createAction(TRAIN_MODEL.SUCCESS, { models }),
	failure: (error) => createAction(TRAIN_MODEL.FAILURE, { error }),
};

const cancelQueue = {
	request: (model) => createAction(CANCEL_QUEUE.REQUEST, { model }),
	success: (models) => createAction(CANCEL_QUEUE.SUCCESS, { models }),
	failure: (error) => createAction(CANCEL_QUEUE.FAILURE, { error }),
};

const deleteModel = {
	request: (model) => createAction(DELETE_MODEL.REQUEST, { model }),
	success: (models) => createAction(DELETE_MODEL.SUCCESS, { models }),
	failure: (error) => createAction(DELETE_MODEL.FAILURE, { error }),
};

const previewModel = {
	request: (modelid) => createAction(PREVIEW_MODEL.REQUEST, { modelid }),
	success: (preview) => createAction(PREVIEW_MODEL.SUCCESS, { preview }),
	failure: (error) => createAction(PREVIEW_MODEL.FAILURE, { error }),
};

const predictValue = {
	request: (file, modelid) => createAction(PREDICT_VALUE.REQUEST, { file, modelid }),
	success: (result) => createAction(PREDICT_VALUE.SUCCESS, { result }),
	failure: (error) => createAction(PREDICT_VALUE.FAILURE, { error }),
};

const uploadTrainModelFile = {
	request: (file, modelid) => createAction(UPLOAD_TRAINING_FILE.REQUEST, { file, modelid }),
	success: (data) => createAction(UPLOAD_TRAINING_FILE.SUCCESS, { data }),
	failure: (error) => createAction(UPLOAD_TRAINING_FILE.FAILURE, { error }),
};

const clearPredictResult = () => ({
	type: CLEAR_PREDICT_RESULT,
});
const clearPredictConfig = () => ({
	type: CLEAR_PREDICT_CONFIG,
});

export {
	// ----- types -----
	LOAD_MODELS,
	LOAD_MODEL,
	LOAD_TYPE,
	LOAD_SUBTYPE,
	CREATE_MODEL,
	UPDATE_MODEL,
	CONFIG_MODEL,
	TRAIN_MODEL,
	CANCEL_QUEUE,
	DELETE_MODEL,
	PREVIEW_MODEL,
	PREDICT_VALUE,
	CLEAR_PREDICT_RESULT,
	UPLOAD_TRAINING_FILE,
	CLEAR_PREDICT_CONFIG,
	// ----- actions -----
	loadModels,
	loadModel,
	loadTypes,
	loadSubtypes,
	createModel,
	updateModel,
	configModel,
	trainModel,
	cancelQueue,
	deleteModel,
	previewModel,
	predictValue,
	uploadTrainModelFile,
	clearPredictResult,
	clearPredictConfig,
};
