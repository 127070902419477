import { ExclamationOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import AlertIcon from 'components/etc/AlertIcon';
import PublicContainer from 'components/Layouts/PublicContainer';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { resendActivateEmail } from 'reduxStore/actions/authAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';

export const ConfirmAccountPage = ({ resendActivateEmail, loading, message }) => {
	const prevMsg = usePrevious(message);

	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			openNotification(message);
		}
	}, [message]);

	return (
		<PublicContainer>
			<ParentContainer>
				<div style={{ marginTop: '36px' }} />
				<Row>
					<Col>
						<AlertIcon icon={<ExclamationOutlined />} color="#ffaf02" />
					</Col>
				</Row>
				<Label>
					{' '}
					{localStorage.getItem('confirm_type') === 'register'
						? 'Confirm your account'
						: 'Your account has not been verified yet.'}
				</Label>
				<StyledCard>
					{localStorage.getItem('confirm_type') === 'register' && (
						<TextBox>
							One last step, activate your account. An activation mail has been sent to your email
							<ValueE> {localStorage.getItem('activate_email')}</ValueE>
							Open the link in the email you received to complete verification.
						</TextBox>
					)}
					{localStorage.getItem('confirm_type') === 'login' && (
						<TextBox>
							Please verify activate your account. An activation mail has been sent to your email
							<ValueE> {localStorage.getItem('activate_email')}</ValueE>
							Open the link in the email you received to complete verification.
						</TextBox>
					)}
				</StyledCard>

				<Link to={'/login'}>
					<Button type="primary" style={{ width: '100%', marginBottom: '25px' }}>
						Back to login
					</Button>
				</Link>
				<Resend type="link" onClick={(e) => resendActivateEmail(localStorage.getItem('activate_email'))}>
					Resend activation email{' '}
				</Resend>
				{/* <Link to="#" >
					<Resend> Resend activation email </Resend>
				</Link> */}
			</ParentContainer>
		</PublicContainer>
	);
};

const mapStateToProps = ({ authReducer }) => ({
	loading: authReducer.loading,
	message: authReducer.message,
});

const mapDispatchToProps = { resendActivateEmail: resendActivateEmail.request };

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmAccountPage);

const Label = styled.div`
	font-size: 28px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.44px;
	color: #333333;
`;

const Resend = styled(Button)`
	font-size: 14px;
	font-weight: 600;
	line-height: 1;
	letter-spacing: 0.5px;
	text-align: center;
	color: #ffaf02;
	padding-left: 0;
`;
const ParentContainer = styled.div`
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	max-width: 380px;

	@media only screen and (max-width: 768px) {
		width: 90%;
	}
`;

const StyledCard = styled.div`
	padding: 11px 21px 13px 16px;
	border-radius: 2px;
	border: solid 0.8px #d8d8d8;
	background-color: #fff;
	margin: 22px 0px;
`;

const TextBox = styled.div`
	font-size: 14px;
	font-weight: 300;
	color: #4d4d4d;
`;
const ValueE = styled(TextBox)`
	font-weight: 500;
`;
