import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
// import { persistReducer, persistStore } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import rootSagas from './sagas/rootSaga';
import history from '../util/history';

import rootReducer from './reducers/rootReducer';

// const persistConfig = {
// 	key: 'root',
// 	storage,
// 	whitelist: ['authReducer', 'appSettingReducer'],
// };

// const persistedReducer = (rootReducer) => persistReducer(persistConfig, rootReducer);

const configureStore = (initialState) => {
	const sagaMiddleware = createSagaMiddleware();
	const middlewares = [routerMiddleware(history), sagaMiddleware];

	const store = createStore(
		// persistedReducer(rootReducer(history)),
        rootReducer(history),
		initialState,
		composeWithDevTools(applyMiddleware(...middlewares))
	);

	//   sagaMiddleware.run(appInitialize);
	sagaMiddleware.run(rootSagas);
	// let persistor = persistStore(store);

	return { store };
};

export const { store } = configureStore();
