import { Button, Col, Divider, Row, Skeleton } from 'antd';
import DeviceCard from 'components/Card/DeviceCard';
import Empty from 'components/etc/Empty';
import SortMenu from 'components/etc/SortMenu';
import { CollapsedContext } from 'components/Layouts/LayoutContainer';
import AlertModal from 'components/modals/AlertModal';
import DeviceModal from 'components/modals/deviceModal';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createDevice, deleteDevice, loadDevices, updateDevice } from 'reduxStore/actions/deviceAction';
import { loadRecipes } from 'reduxStore/actions/recipeAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';

const DevicePage = ({
	loadDevices,
	createDevice,
	updateDevice,
	deleteDevice,
	devices,
	loading,
	message,
	loadRecipes,
	recipes,
}) => {
	const globalCollapsed = useContext(CollapsedContext);

	// state
	const [isModalVisible, setModalVisible] = useState(false);
	const [delWarn, setDeleteWarn] = useState(false);
	const [selectedDevice, setSelectedDevice] = useState(null);
	const [DATA, setData] = useState([]);
	const [sort, setSort] = useState(null);
	const prevMsg = usePrevious(message);

	// did mount
	useEffect(() => {
		loadDevices();
		loadRecipes();
	}, []);

	// did update
	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			openNotification(message);
			if (message.type === 'success') closeModal();
		}
	}, [message]);
	useEffect(() => {
		if (devices) {
			sortData();
		}
	}, [devices]);

	// function
	const closeModal = () => {
		setModalVisible(false);
		setDeleteWarn(false);
		setSelectedDevice(null);
	};
	const handleClickEdit = (device) => {
		setModalVisible(true);
		setSelectedDevice(device);
	};
	const handleClickDelete = (device) => {
		setDeleteWarn(true);
		setSelectedDevice(device);
	};
	const sortData = (type, order) => {
		const t = type || sort?.type;
		const o = order || sort?.order;

		const res =
			sort !== null || sort !== undefined
				? [...devices].sort((a, b) => {
						if (a[t] < b[t]) {
							if (o === 'asc') return -1;
							else return 1;
						}
						if (a[t] > b[t]) {
							if (o === 'asc') return 1;
							else return -1;
						}
						return 0;
				  })
				: devices;
		setData(res);
		setSort({ type: t, order: o });
	};

	// UI
	const renderDevicesList = () => {
		return (
			<Row gutter={[20, 20]}>
				{DATA.map((device, index) => (
					<Col xs={24} sm={12} md={8} lg={globalCollapsed.collapsed ? 6 : 8} key={`device-${index}`}>
						<DeviceCard
							device={device}
							handleEdit={() => handleClickEdit(device)}
							handleDelete={() => handleClickDelete(device)}
						/>
					</Col>
				))}
			</Row>
		);
	};

	if (loading && DATA.length === 0) {
		return <Skeleton active />;
	}

	return (
		<div>
			<Row align="middle" justify="space-between">
				<Col>
					<Label>Devices</Label>
				</Col>
				<Col>
					<Button type="primary" onClick={() => setModalVisible(true)}>
						Add Device
					</Button>
				</Col>
			</Row>
			<Row align="middle" justify="space-between" style={{ marginTop: '5px', fontSize: '12px' }}>
				<Col>
					<Results>
						Result <NumEdges>{DATA.length}</NumEdges> devices
					</Results>
				</Col>
				<Col>
					<SortMenu onSortApply={sortData} />
				</Col>
			</Row>
			<StyledDivider />

			{DATA.length === 0 ? <Empty title="project" /> : renderDevicesList()}

			{/* Modal */}
			<DeviceModal
				visible={isModalVisible}
				handleAdd={(data) => createDevice(data)}
				handleEdit={(data) => updateDevice(data)}
				handleCancel={closeModal}
				device={selectedDevice}
				recipes={recipes}
			/>
			<AlertModal
				alertType={'delete'}
				type="device"
				item={selectedDevice}
				visible={delWarn}
				handleCancel={closeModal}
				handleOk={(data) => deleteDevice(data)}
			/>
		</div>
	);
};

// redux
const mapStateToProps = ({ deviceReducer, recipeReducer }) => {
	return {
		loading: deviceReducer.loading,
		devices: deviceReducer.devices,
		message: deviceReducer.message,
		recipes: recipeReducer.recipes,
	};
};

const mapDispatchToProps = {
	loadDevices: loadDevices.request,
	createDevice: createDevice.request,
	updateDevice: updateDevice.request,
	deleteDevice: deleteDevice.request,
	loadRecipes: loadRecipes.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(DevicePage);

// style
const StyledDivider = styled(Divider)`
	margin: 5px 0;
	padding: 0;
`;
const Label = styled.div`
	font-size: 24px;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: 0.5px;
	color: #333333;
	margin-top: 10px;
	margin-bottom: 14px;
`;
const Results = styled.span`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.29;
	letter-spacing: normal;
	color: #808080;
`;
const NumEdges = styled.span`
	font-size: 14px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #4d4d4d;
`;
