import gql from 'graphql-tag';

const PROJECT_FRAGMENT = gql`
	fragment project on project {
		projectid
		name
		description
		numberrecipe
	}
`;

const QUERY_PROJECTS = gql`
	query {
		projects {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const QUERY_PROJECT = gql`
	query project($id: ID!) {
		project(projectid: $id) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const CREATE_PROJECT = gql`
	mutation createProject($name: String!, $description: String!) {
		createProject(name: $name, description: $description) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const UPDATE_PROJECT = gql`
	mutation updateProject($id: ID!, $name: String!, $description: String!) {
		updateProject(projectid: $id, name: $name, description: $description) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const DELETE_PROJECT = gql`
	mutation deleteProject($id: ID!) {
		deleteProject(projectid: $id) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

export { QUERY_PROJECTS, QUERY_PROJECT, CREATE_PROJECT, UPDATE_PROJECT, DELETE_PROJECT };
