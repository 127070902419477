import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/book_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M18,1.99999996 L5.99999997,1.99999996 C4.89999997,1.99999996 3.99999997,2.89999996 3.99999997,3.99999996 L3.99999997,20 C3.99999997,21.1 4.89999997,22 5.99999997,22 L18,22 C19.1,22 20,21.1 20,20 L20,3.99999996 C20,2.89999996 19.1,1.99999996 18,1.99999996 Z M8.99999999,3.99999997 L11,3.99999997 L11,8.99999997 L9.99999999,8.24999998 L8.99999999,8.99999997 L8.99999999,3.99999997 Z M18,20 L5.99999997,20 L5.99999997,3.99999997 L6.99999997,3.99999997 L6.99999997,13 L9.99999997,10.75 L13,13 L13,3.99999997 L18,3.99999997 L18,20 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Bookmark = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Bookmark;
