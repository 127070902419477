import React from 'react';
import { Controller } from 'react-hook-form';
import { Input } from 'antd';
import FormItem from '../formItem';

const ResetPasswordForm = ({
	formProps: {
		control,
		formState: { errors },
	}
}) => {

	console.log(errors)

	return (
		<FormItem label="Email" error={errors?.email?.message}>
			<Controller
				name="email"
				control={control}
				render={({ field }) => <Input placeholder="Enter your email" {...field} />}
			/>
		</FormItem>
				
	);
};

export default ResetPasswordForm;
