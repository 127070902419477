import React, { useEffect, useState } from 'react';
import Card from './Card';
import { Button, Typography, Dropdown, Menu, Space, Select, Divider, Row, Col } from 'antd';
import styled from 'styled-components';
import StatusCard from './StatusCard';
import { Construction, Copy, Delete, Edit, Ellipsis, CloudUpload, Revert, Touch } from 'assets/icons';
import { useHistory } from 'react-router-dom';
import { Download } from 'assets/icons';

const menuIconStyle = { style: { fontSize: 20 } };

const EdgeListCard = ({
	item,
	models,
	onEdit,
	onConfig,
	onDelete,
	onUndo,
	onSelectDevice,
	onImport,
	onDuplicate,
	onDownload,
}) => {
	// state
	const [ver, setVer] = useState('latest');
	const [config, setConfig] = useState(() => item.recipeconfig.find((config) => config.version === 'latest'));
	const history = useHistory();

	const menuList = [
		[
			{ label: 'Duplicate', icon: <Copy {...menuIconStyle} />, onClick: onDuplicate },
			{ label: 'Configure', icon: <Construction {...menuIconStyle} />, onClick: onConfig },
			{ label: 'Edit', icon: <Edit {...menuIconStyle} />, onClick: onEdit },
			{ label: 'Import flow', icon: <CloudUpload {...menuIconStyle} />, onClick: onImport },
			{ label: 'Choose device', icon: <Touch {...menuIconStyle} />, onClick: onSelectDevice },
		],
		[{ label: 'Revert to previous version', icon: <Revert {...menuIconStyle} />, onClick: onUndo }],
		[{ label: 'Delete', icon: <Delete {...menuIconStyle} />, onClick: onDelete }],
	];

	// effect
	useEffect(() => {
		const selectedConfig = item.recipeconfig.find((config) => config.version === ver);
		setConfig(selectedConfig);
	}, [ver, item]);

	const toDeviceList = () => {
		history.push({ pathname: `${history.location.pathname}/${item.recipeid}` });
	};

	const onClickButton = (e) => {
		e.stopPropagation();
		onDownload(item.recipeid || null, ver.toLowerCase());
	};

	const renderTag = () => {
		if (!config) return null;

		return Object.keys(config).map((key, index) => renderConfigTag(key, config[key], index));
	};

	const renderConfigTag = (key, value, index) => {
		switch (key) {
			case 'hardware':
				return (
					value && (
						<StatusCard status="edge" key={index}>
							{value.toUpperCase()}
						</StatusCard>
					)
				);
			case 'database':
			case 'visualization':
				return (
					value === true && (
						<StatusCard status="edge" key={index}>
							{key}
						</StatusCard>
					)
				);
			case 'protocol':
				return (
					value.length > 0 && (
						<StatusCard status="edge" key={index}>
							{value.map((item, idx) => [idx ? ', ' : null, item])}
						</StatusCard>
					)
				);
			case 'mlBox':
				return value.map((item, idx) => {
					const result = models.find(({ modelid }) => modelid === item);
					return (
						<StatusCard status="edge" key={idx}>
							{result?.name}
						</StatusCard>
					);
				});
			default:
				return null;
		}
	};

	const menu = (
		<StyledMenu>
			{menuList.map((section, index) => (
				<React.Fragment key={index}>
					{index !== 0 && <Menu.Divider />}
					{section.map((menu) => (
						<Menu.Item
							key={`${menu.label}-btn`}
							icon={menu.icon}
							danger={menu.label === 'Delete'}
							onClick={menu.onClick}
							disabled={menu.label === 'Revert to previous version' && item.recipeconfig.length < 2}
						>
							{menu.label}
						</Menu.Item>
					))}
				</React.Fragment>
			))}
		</StyledMenu>
	);

	return (
		<Card onClick={toDeviceList}>
			<div>
				<TopCard>
					<NameEdge>{item.name}</NameEdge>
					<Space>
						<Dropdown
							overlay={menu}
							trigger={['click']}
							placement="bottomRight"
							disabled={ver === 'previous'}
						>
							<Button
								onClick={(e) => e.stopPropagation()}
								type="text"
								icon={<Ellipsis style={{ fontSize: 20 }} />}
								style={{ height: '32px', width: '32px' }}
							/>
						</Dropdown>
					</Space>
				</TopCard>
				{item.description && <DescEdge>{item.description}</DescEdge>}

				<Row justify="space-around">
					<Col md={5}>
						<div
							style={{
								fontSize: '0.9em',
								color: '#808080',
								maxWidth: 60,
								float: 'right',
								paddingTop: '7px',
								marginRight: '8px',
							}}
						>
							Version :
						</div>
					</Col>
					<Col md={19}>
						<Select
							onChange={setVer}
							value={ver}
							disabled={item.recipeconfig.length < 2}
							onClick={(e) => e.stopPropagation()}
							style={{ width: '82%', marginRight: '5px' }}
						>
							<Select.Option value="previous">Previous</Select.Option>
							<Select.Option value="latest">Latest</Select.Option>
						</Select>
						<Button
							onClick={onClickButton}
							icon={<Download />}
							style={{ width: '15%', minWidth: '33px' }}
						/>
					</Col>
				</Row>

				<DividerCard />
				<CardEdge>{renderTag()}</CardEdge>
			</div>
		</Card>
	);
};

export default EdgeListCard;

const NameEdge = styled.div`
	font-size: 16px;
	font-weight: 500;
	line-height: 1.25;
	letter-spacing: normal;
	color: #333333;
	max-width: 288px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const DescEdge = styled(Typography.Paragraph).attrs({
	ellipsis: {
		rows: 3,
	},
})`
	margin-top: 0px;
	font-size: 14px;
	font-weight: 300;
	line-height: 1.5;
	letter-spacing: normal;
	color: #4d4d4d;
	word-break: break-all;
	min-height: 63px;
`;

const TopCard = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
`;

const DividerCard = styled(Divider)`
	margin-top: 10px;
	margin-bottom: 0px;
`;

const CardEdge = styled(Space).attrs({ wrap: true, align: 'baseline', size: 2 })`
	width: 100% !important;
	margin-bottom: 8px;
	margin-top: 8px;
	min-height: 24px;
`;

const StyledMenu = styled(Menu)`
	.ant-dropdown-menu-item,
	.ant-dropdown-menu-submenu-title {
		font-size: 14px;
		font-weight: normal;
		line-height: 1.5;
		letter-spacing: normal;
		color: #4d4d4d;
		padding-left: 20px;
	}
	.ant-dropdown-menu-item-disabled {
		background-color: rgba(230, 230, 230, 0.8) !important;
	}

	min-width: 180px;
	padding: 8px 0;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #ffffff;

	.ant-dropdown-menu-item-divider,
	.ant-dropdown-menu-submenu-title-divider {
		background-color: rgba(207, 218, 230, 0.5);
	}
	.ant-dropdown-menu-item:hover,
	.ant-dropdown-menu-submenu-title:hover {
		background-color: #fff8e8;
	}

	.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
		:hover {
			color: #f64e60;
			background-color: rgba(246, 78, 96, 0.14);
			font-weight: normal;
		}
	}
`;
