import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/visibility_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M12,5.99999998 C15.79,5.99999998 19.17,8.12999998 20.82,11.5 C19.17,14.87 15.79,17 12,17 C8.20999998,17 4.82999997,14.87 3.17999996,11.5 C4.82999997,8.12999998 8.20999998,5.99999998 12,5.99999998 M12,3.99999997 C6.99999995,3.99999997 2.72999996,7.10999998 0.999999954,11.5 C2.72999996,15.89 6.99999995,19 12,19 C17,19 21.27,15.89 23,11.5 C21.27,7.10999998 17,3.99999997 12,3.99999997 Z M12,8.99999999 C13.38,8.99999999 14.5,10.12 14.5,11.5 C14.5,12.88 13.38,14 12,14 C10.62,14 9.49999999,12.88 9.49999999,11.5 C9.49999999,10.12 10.62,8.99999999 12,8.99999999 M12,6.99999998 C9.51999999,6.99999998 7.49999998,9.01999999 7.49999998,11.5 C7.49999998,13.98 9.51999999,16 12,16 C14.48,16 16.5,13.98 16.5,11.5 C16.5,9.01999999 14.48,6.99999998 12,6.99999998 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Visible = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Visible;
