import React, { useState, useMemo, useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import ProfileSettingModal from 'components/modals/SettingProfileModal';
import { connect } from 'react-redux';
import { signOut } from 'reduxStore/actions/authAction';
const pageMenu = [
	{ key: 'home', onclick: () => window.open('https://daysie.io/'), label: 'Home' },
	{ routeTo: '/p', label: 'My Project' },
	{ routeTo: '/d', label: 'My device' },
	{ routeTo: '/m', label: 'ML Model' },
];

const getMenu = (item) => {
	if (item.routeTo) {
		return (
			<Menu.Item key={item.routeTo || item.key} style={{ margin: '3px 0px' }}>
				<Link to={item.routeTo}>{item.label}</Link>
			</Menu.Item>
		);
	} else {
		return (
			<Menu.Item key={item.key} style={{ margin: '3px 0px' }} onClick={(e) => item.onclick()}>
				{item.label}
			</Menu.Item>
		);
	}
};

const UserComponent = ({ loading, signOut, user, updateProfile, changePassword, message }) => {
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [authError, setAuthError] = useState({});

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleEditProfile = (data) => {
		updateProfile(data);
	};

	const handleChangePass = (data) => {
		changePassword(data);
	};

	useEffect(() => {
		if (message && message.description === 'Invalid current password') {
			setAuthError({ currentpassword: { message: message.description } });
		}

		if (message?.type === 'success') {
			handleCancel();
		}
	}, [message]);

	const menu = (
		<StyledMenu>
			<Menu.Item
				key="profile"
				icon={<span className="material-icons-outlined">manage_accounts</span>}
				style={{ marginTop: '0px', marginBottom: '5px' }}
				onClick={showModal}
			>
				Profile Setting
			</Menu.Item>
			<Menu.Divider key={'user-divider1'} style={{ backgroundColor: '#c7d2f3', width: '90%', margin: 'auto' }} />
			<MobileMenu className="pageMenu-mobile">
				{pageMenu.map((menu) => getMenu(menu))}
				<Menu.Divider
					key={'user-divider2'}
					style={{ backgroundColor: '#c7d2f3', width: '90%', margin: 'auto' }}
				/>
			</MobileMenu>
			<Menu.Item
				key="logout"
				icon={<span className="material-icons-outlined">logout</span>}
				style={{ marginTop: '5px', marginBottom: '0px' }}
				onClick={signOut}
			>
				Logout
			</Menu.Item>

			<ProfileSettingModal
				visible={isModalVisible}
				handleCancel={handleCancel}
				loading={loading}
				user={user}
				handleChangePass={(data) => handleChangePass(data)}
				handleEditProfile={(data) => handleEditProfile(data)}
				message={message}
				authError={authError}
			/>
		</StyledMenu>
	);

	const textAvatar = useMemo(() => {
		// const user = { name: 'Sittinon nooaid1991' };
		const firstletter = user?.name?.[0] ?? '';
		return firstletter.toString().toUpperCase();
	}, [user]);

	return (
		<Dropdown overlay={menu} trigger={['click']}>
			<Container>
				<Outside>
					<StyleAvatar>{textAvatar}</StyleAvatar>
				</Outside>
				<div>
					<Name>
						<Ellipsis>{user?.name}</Ellipsis>
					</Name>
					<NEmail>
						<Ellipsis>{user?.email}</Ellipsis>
					</NEmail>
				</div>
				<CaretDownOutlined style={{ color: '#5e6886', marginLeft: '5px' }} />
			</Container>
		</Dropdown>
	);
};

const mapStateToProps = ({ authReducer }) => ({
	message: authReducer.message,
});

const mapDispatchToProps = {
	signOut: signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);

const MobileMenu = styled.div`
	display: none;
	@media only screen and (max-width: 500px) {
		display: block;
	}
`;
const Container = styled.div`
	cursor: pointer;
	padding: 5px 10px;
	transition: 'color 0.3s';
	display: flex;
	align-items: center;
`;

const Name = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 0.47px;
	color: #333333;
`;

const NEmail = styled.div`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #4d4d4d;
`;

const Ellipsis = styled.div`
	max-width: 174px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	@media only screen and (max-width: 500px) {
		width: 110px;
	}
`;

const StyleAvatar = styled.span`
	width: 34px;
	height: 34px;
	border-radius: 50%;
	background-color: #ffaf02;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 24px;
	font-weight: 600;
	color: #ffffff;
`;

const Outside = styled.span`
	margin: 0px 8px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: solid 1px #ffaf02;
`;

const StyledMenu = styled(Menu)`
	.ant-dropdown-menu-item,
	.ant-dropdown-menu-submenu-title {
		font-size: 14px;
		font-weight: 300;
		line-height: 1.5;
		letter-spacing: normal;
		color: #4d4d4d;
	}

	width: 180px;
	float: right;
	bottom: 10px;
	padding: 8px 0;
	border-radius: 5px;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
	background-color: #ffffff;

	.ant-dropdown-menu-item-divider,
	.ant-dropdown-menu-submenu-title-divider {
		background-color: rgba(207, 218, 230, 0.5);
	}
	.ant-dropdown-menu-item:hover,
	.ant-dropdown-menu-submenu-title:hover {
		background-color: #fff8e8;
		font-weight: normal;
	}
`;
