import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/model_training_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M15.5,13.5 C15.5,15.5 13,17 13,18.5 L11,18.5 C11,17 8.49999999,15.5 8.49999999,13.5 C8.49999999,11.57 10.07,9.99999999 12,9.99999999 L12,9.99999999 C13.93,9.99999999 15.5,11.57 15.5,13.5 Z M13,19.5 L11,19.5 L11,21 L13,21 L13,19.5 Z M19,13 C19,14.68 18.41,16.21 17.42,17.42 L18.84,18.84 C20.18,17.27 21,15.23 21,13 C21,10.26 19.77,7.80999998 17.84,6.15999998 L16.42,7.57999998 C17.99,8.85999999 19,10.82 19,13 Z M16,4.99999995 L12,0.999999954 L12,3.99999995 C12,3.99999995 12,3.99999995 12,3.99999995 C7.02999998,3.99999995 2.99999996,8.02999998 2.99999996,13 C2.99999996,15.23 3.81999997,17.27 5.15999997,18.84 L6.57999998,17.42 C5.58999997,16.21 4.99999996,14.68 4.99999996,13 C4.99999996,9.13999999 8.13999998,5.99999995 12,5.99999995 C12,5.99999995 12,5.99999995 12,5.99999995 L12,8.99999995 L16,4.99999995 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const ModelTraining = (props) => {
	return <Icon {...props} component={icon} />;
};
export default ModelTraining;
