import { Button, Col, Divider, Row, Skeleton } from 'antd';
import { ArrowLeft } from 'assets/icons';
import RecipeDeviceCard from 'components/Card/RecipeDeviceCard';
import Empty from 'components/etc/Empty';
import SortMenu from 'components/etc/SortMenu';
import { CollapsedContext } from 'components/Layouts/LayoutContainer';
import AlertModal from 'components/modals/AlertModal';
import LinkDeviceModal from 'components/modals/linkDeviceModal';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { linkDevice, loadDevices, unlinkDevice } from 'reduxStore/actions/deviceAction';
import { loadProject } from 'reduxStore/actions/projectAction';
import { loadRecipe } from 'reduxStore/actions/recipeAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';

const RecipeDevicePage = ({
	loadProject,
	project,
	loadRecipe,
	recipe,
	loadDevices,
	linkDevice,
	unlinkDevice,
	dLoading,
	devices,
	rDevices,
	message,
	linkLoading,
}) => {
	const globalCollapsed = useContext(CollapsedContext);
	const { location } = useHistory();
	const splitpath = location.pathname.split('/');
	const projectid = splitpath[2];
	const recipeid = splitpath[4];
	let rDeviceid = rDevices.map((item) => item.deviceid);
	let newDevices = devices.filter((el) => -1 === rDeviceid.indexOf(el.deviceid));
	const prevMsg = usePrevious(message);

	// state
	const [visible, setVisible] = useState(false);
	const [alertVisible, setAlertVisible] = useState(false);
	const [alertMoveConfirm, setAlertMoveConfirm] = useState(false);
	const [selectedDevice, setSelectedDevice] = useState(null);
	const [DATA, setData] = useState([]);

	// did mount & did update
	useEffect(() => {
		loadProject(projectid);
		if (recipeid) {
			loadRecipe(recipeid);
			loadDevices(recipeid);
		}
	}, [projectid, recipeid]);
	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			openNotification(message);
			if (message.type === 'success') closeModal();
		}
	}, [message]);
	useEffect(() => {
		if (rDevices) {
			setData(rDevices);
		}
	}, [rDevices]);

	// func
	const handleClickUnlink = (device) => {
		setAlertVisible(true);
		setSelectedDevice([device]);
	};
	const closeModal = () => {
		setVisible(false);
		setAlertVisible(false);
		setAlertMoveConfirm(false);
		setSelectedDevice(null);
	};
	const sortData = (type, order) => {
		const res = [...DATA].sort((a, b) => {
			if (a[type] < b[type]) {
				if (order === 'asc') return -1;
				else return 1;
			}
			if (a[type] > b[type]) {
				if (order === 'asc') return 1;
				else return -1;
			}
			return 0;
		});
		setData(res);
	};

	const handleClicklink = (selectedDevices) => {
		setAlertMoveConfirm(true);
		setSelectedDevice(selectedDevices);
	}

	const onLinkDevice = () => {
		linkDevice(recipeid, selectedDevice);
		setSelectedDevice(null);
		setAlertMoveConfirm(false);
	}

	// ui
	const renderDevicesList = () => {
		return (
			<Row gutter={[20, 20]}>
				{DATA?.map((device, index) => (
					<Col xs={24} sm={12} md={8} lg={globalCollapsed.collapsed ? 6 : 8} key={`device-${index}`}>
						<RecipeDeviceCard device={device} handleUnlink={() => handleClickUnlink(device)} />
					</Col>
				))}
			</Row>
		);
	};

	if (dLoading) return <Skeleton active />;
	return (
		<div>
			<Link key={`back`} to={`/p/${projectid}/edge`} style={{ display: 'flex', alignItems: 'center' }}>
				<ArrowLeft style={{ fontSize: 24 }} />
				<BackTo>Back to {project?.name}</BackTo>
			</Link>
			<Row justify="space-between" align="middle">
				<Col>
					<Label>{recipe?.name}</Label>
				</Col>
				<Col>
					<Button type="primary" onClick={() => setVisible(true)}>
						Add new device
					</Button>
				</Col>
			</Row>
			<Row justify="space-between" align="middle">
				<Col>
					<Results>
						Results <NumEdges>{DATA?.length}</NumEdges> devices
					</Results>
				</Col>
				<Col>
					<SortMenu onSortApply={sortData} page="recipeDevice" />
				</Col>
			</Row>
			<StyledDivider />

			{DATA?.length === 0 ? <Empty title="device" /> : renderDevicesList()}

			{/* Modal */}
			{alertVisible && (
				<AlertModal
					alertType="unlink"
					type="device"
					item={selectedDevice[0]}
					recipeName={recipe?.name}
					visible={alertVisible}
					handleCancel={closeModal}
					handleOk={(data) => unlinkDevice(recipeid, [data.deviceid])}
				/>
			)}
			{alertMoveConfirm && (
				<AlertModal
					alertType="movedevice"
					type="device"
					item={selectedDevice[0]}
					recipeName={recipe?.name}
					visible={alertMoveConfirm}
					handleCancel={e => setAlertMoveConfirm(false)}
					handleOk={() => onLinkDevice()}
				/>
			)}
			<LinkDeviceModal
				visible={visible}
				onOk={(selectedDevices) => handleClicklink(selectedDevices)}
				onCancel={closeModal}
				devices={newDevices}
				loading={linkLoading}
			/>
		</div>
	);
};

// redux
const mapStateToProps = ({ projectReducer, recipeReducer, deviceReducer }) => {
	return {
		project: projectReducer.project,
		recipe: recipeReducer.recipe,
		devices: deviceReducer.devices,
		rDevices: deviceReducer.rDevices,
		dLoading: deviceReducer.loading,
		message: deviceReducer.message,
		linkLoading: deviceReducer.linkLoading,
	};
};

const mapDispatchToProps = {
	loadProject: loadProject.request,
	loadRecipe: loadRecipe.request,
	loadDevices: loadDevices.request,
	linkDevice: linkDevice.request,
	unlinkDevice: unlinkDevice.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(RecipeDevicePage);

// style
const Label = styled.div`
	font-size: 24px;
	font-weight: 600;
	color: #333333;
	margin-top: 10px;
	margin-bottom: 14px;
`;
const BackTo = styled.span`
	font-size: 14px;
	font-weight: 600;
	color: #ffaf02;
	padding: 0 8px;
`;
const Results = styled.span`
	font-size: 14px;
	font-weight: 500;
	color: #808080;
`;
const NumEdges = styled.span`
	font-size: 14px;
	font-weight: 600;
	color: #4d4d4d;
`;
const StyledDivider = styled(Divider)`
	margin: 5px 0;
	padding: 0;
`;
