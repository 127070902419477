import React from 'react';
import { Image } from 'antd';
import styled from 'styled-components';
import Nodata from 'assets/images/Nodata.png';

const Empty = ({ title }) => {
	return (
		<div style={{ textAlign: 'center', marginTop: '10em' }}>
			<Image src={Nodata} width={160} preview={false} />
			<Title>NoData </Title>
			<Desc>
				No data, create your first {title} <br />
				Click the button on the top right to create a {title}
			</Desc>
		</div>
	);
};

export default Empty;

const Title = styled.div`
	font-size: 20px;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #333;
`;
const Desc = styled.div`
	font-size: 15px;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #4d4d4d;
`;
