import { all, fork } from 'redux-saga/effects';
import watchModel from './modelSaga';
import watchProject from './projectSaga';
import watchAuth from './authSaga';
import watchDevice from './deviceSaga';
import watchRecipe from './recipeSaga';

export default function* rootSagas() {
	yield all([
		// fork(watchUser),
		fork(watchModel),
		fork(watchProject),
		fork(watchAuth),
		fork(watchDevice),
		fork(watchRecipe),
	]);
}
