import './styles/styles.scss';
import 'antd/dist/antd.less';
// import 'react-image-crop/lib/ReactCrop.scss';
// import 'tippy.js/dist/tippy.css';
// import 'tippy.js/dist/backdrop.css';
// import 'tippy.js/animations/shift-away.css';
// import 'tippy.js/themes/light.css';

// import Wrapper from 'components/Layouts/Wrapper';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';
import { store } from './reduxStore/configureStore';

import AppRouter from './router';
import history from './util/history';

const App = () => {
	// const preLoading = usePreloadFaceDetectionModel();
	return (
		<Provider store={store}>
			{/* <PersistGate loading={null} persistor={persistor}> */}
			<ConnectedRouter history={history}>
				<CookiesProvider>
					{/* <Wrapper> */}
					<HelmetProvider>
						<Helmet>
							<title>DAYSIE</title>
						</Helmet>
						<AppRouter />
					</HelmetProvider>
					{/* </Wrapper> */}
				</CookiesProvider>
			</ConnectedRouter>
			{/* </PersistGate> */}
		</Provider>
	);
};

export default App;
