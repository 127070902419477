import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/content_copy_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M16,0.999999954 L3.99999996,0.999999954 C2.89999996,0.999999954 1.99999996,1.89999996 1.99999996,2.99999995 L1.99999996,17 L3.99999996,17 L3.99999996,2.99999995 L16,2.99999995 L16,0.999999954 Z M19,4.99999997 L7.99999998,4.99999997 C6.89999998,4.99999997 5.99999998,5.89999997 5.99999998,6.99999997 L5.99999998,21 C5.99999998,22.1 6.89999998,23 7.99999998,23 L19,23 C20.1,23 21,22.1 21,21 L21,6.99999997 C21,5.89999997 20.1,4.99999997 19,4.99999997 Z M19,21 L7.99999998,21 L7.99999998,6.99999998 L19,6.99999998 L19,21 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Copy = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Copy;
