import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/cloud_upload_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M19.35,10.04 C18.67,6.58999998 15.64,3.99999997 11.9999999,3.99999997 C9.10999999,3.99999997 6.59999998,5.63999997 5.34999997,8.03999998 C2.33999996,8.35999998 -5.00000006e-08,10.91 -5.00000006e-08,14 C-5.00000006e-08,17.31 2.68999996,20 5.99999995,20 L18.9999999,20 C21.76,20 23.9999999,17.76 23.9999999,15 C23.9999999,12.36 21.95,10.22 19.35,10.04 Z M19,18 L5.99999996,18 C3.78999997,18 1.99999996,16.21 1.99999996,14 C1.99999996,11.95 3.52999996,10.24 5.55999997,10.03 L6.62999998,9.91999999 L7.12999998,8.96999999 C8.07999998,7.13999998 9.93999999,5.99999998 12,5.99999998 C14.62,5.99999998 16.88,7.85999998 17.39,10.43 L17.69,11.93 L19.22,12.04 C20.78,12.14 22,13.45 22,15 C22,16.65 20.65,18 19,18 Z M7.99999998,13 L10.55,13 L10.55,16 L13.45,16 L13.45,13 L16,13 L12,8.99999999 L7.99999998,13 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const CloudUpload = (props) => {
	return <Icon {...props} component={icon} />;
};
export default CloudUpload;
