import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import * as actions from '../actions/deviceAction';
import {
	QUERY_DEVICES,
	QUERY_DEVICE,
	CREATE_DEVICE,
	UPDATE_DEVICE,
	DELETE_DEVICE,
	UNLINK_DEVICE,
	LINK_DEVICE,
} from 'util/apollo/gql/device';
import { apolloQuery, apolloMutation } from '../../util/apollo/apollo';

// ----- load devices -----
function* loadDevices(action) {
	const { ids } = action.payload;
	try {
		if (ids) {
			const rdevicesRes = yield call(async () => await apolloQuery(QUERY_DEVICES, { ids: [ids] }));

			yield put(actions.loadRDevices.success(rdevicesRes.data.devices));
		}
		const devicesRes = yield call(async () => await apolloQuery(QUERY_DEVICES, { ids: [''] }));
		if (devicesRes.errors) throw devicesRes.errors;

		yield put(actions.loadDevices.success(devicesRes.data.devices));
	} catch (error) {
		yield put(actions.loadDevices.failure(error));
	}
}

// ----- load project -----
function* loadDevice(action) {
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_DEVICE, { id: '' }));
		if (errors) throw errors;

		yield put(actions.loadDevices.success(data.device));
	} catch (error) {
		yield put(actions.loadDevices.failure(error));
	}
}

// ----- create project -----
function* createDevice(action) {
	const { device } = action.payload;

	try {
		const newDevice = {
			devicename: device.name,
			recipeid: `${device.recipeId}`,
		};

		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_DEVICE, {
					...newDevice,
				})
		);
		if (mutationRes.errors) throw mutationRes.errors;

		// update redux
		let newDevices = [];
		const prevDevices = yield select(({ deviceReducer }) => deviceReducer.devices);
		newDevices = [...prevDevices, mutationRes.data.addDevice];

		yield put(actions.createDevice.success(newDevices));
	} catch (error) {
		yield put(actions.createDevice.failure(error));
	}
}

// ----- edit project -----
function* editDevice(action) {
	const { device } = action.payload;

	try {
		const newDevice = {
			deviceid: device.deviceid,
			devicename: device.name,
			recipeid: device.recipeId,
		};

		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_DEVICE, {
					...newDevice,
				})
		);
		if (mutationRes.errors) throw mutationRes.errors;

		// update redux
		let newDevices = [];
		const prevDevices = yield select(({ deviceReducer }) => deviceReducer.devices);
		newDevices = prevDevices?.map((device) =>
			device.deviceid === newDevice.deviceid ? mutationRes.data.updatedevice : device
		);

		yield put(actions.updateDevice.success(newDevices));
	} catch (error) {
		yield put(actions.updateDevice.failure(error));
	}
}

// ----- delete project -----
function* deleteDevice(action) {
	const { device } = action.payload;
	const delDevice = device;
	try {
		const { errors } = yield call(
			async () =>
				await apolloMutation(DELETE_DEVICE, {
					deviceid: delDevice.deviceid,
				})
		);
		if (errors) throw errors;

		// update redux
		let newDevices = [];
		const prevDevices = yield select(({ deviceReducer }) => deviceReducer.devices);
		newDevices = prevDevices?.filter((device) => device.deviceid !== delDevice.deviceid);

		yield put(actions.deleteDevice.success(newDevices));
	} catch (error) {
		yield put(actions.deleteDevice.failure(error));
	}
}

// ----- unlink device -----
function* unlinkDevice(action) {
	const { recipeid, devices } = action.payload;

	try {
		const { data, errors } = yield call(
			async () => await apolloMutation(UNLINK_DEVICE, { recipeid, ids: devices })
		);
		if (errors) throw errors;

		// update redux
		let newDevices = [];
		const prevRDevices = yield select(({ deviceReducer }) => deviceReducer.rDevices);
		newDevices = prevRDevices?.filter((rdevice) => data.unlinkDevice[0].deviceid !== rdevice.deviceid);

		yield put(actions.unlinkDevice.success(newDevices));
	} catch (error) {
		yield put(actions.unlinkDevice.failure(error));
	}
}

// ----- link device -----
function* linkDevice(action) {
	const { recipeid, devices } = action.payload;

	try {
		const { data, errors } = yield call(async () => await apolloMutation(LINK_DEVICE, { recipeid, ids: devices }));
		if (errors) throw errors;

		// update redux
		let newDevices = [];
		const prevRDevices = yield select(({ deviceReducer }) => deviceReducer.rDevices);
		newDevices = prevRDevices.concat(data.linkDevice);

		yield put(actions.linkDevice.success(newDevices));
	} catch (error) {
		yield put(actions.linkDevice.failure(error));
	}
}

export default function* watchDevice() {
	yield all([
		takeLatest(actions.LOAD_DEVICES.REQUEST, loadDevices),
		takeLatest(actions.LOAD_DEVICE.REQUEST, loadDevice),

		takeEvery(actions.CREATE_DEVICE.REQUEST, createDevice),
		takeEvery(actions.UPDATE_DEVICE.REQUEST, editDevice),
		takeEvery(actions.DELETE_DEVICE.REQUEST, deleteDevice),

		takeEvery(actions.UNLINK_DEVICE.REQUEST, unlinkDevice),
		takeEvery(actions.LINK_DEVICE.REQUEST, linkDevice),
	]);
}
