import { Col, Divider, Space, Button, Tooltip, Badge, message } from 'antd';
import React, { useState, useCallback } from 'react';
import Card from './Card';
import styled from 'styled-components';
import { Copy, Invisible, Visible, Delete } from 'assets/icons';

const RecipeDeviceCard = ({ device, handleUnlink }) => {
	// state
	const [isTokenVisible, setTokenVisible] = useState(false);

	// func
	const toggleVisible = useCallback(() => {
		setTokenVisible((prev) => !prev);
	}, []);

	return (
		<Card style={{ position: 'relative' }}>
			<Badge
				count={device.status === 0 ? 'Obsolate' : 'Up to date'}
				style={{
					width: 'fit-content',
					fontSize: 10,
					color: device.status === 0 ? '#808080' : '#fff',
					background: device.status === 0 ? '#f2f2f2' : '#ffaf02',
				}}
			/>
			<DeviceName>{device.devicename}</DeviceName>
			<DeviceId>{device.deviceid}</DeviceId>
			<div style={{ position: 'absolute', top: 5, right: 5 }}>
				<Tooltip placement="bottomRight" title="Delete device from this recipe">
					<Button
						type="link"
						icon={<Delete style={{ color: '#808080' }} />}
						onClick={() => handleUnlink(device)}
					/>
				</Tooltip>
			</div>

			<Divider style={{ margin: '10px 0' }} />
			<FlexContainer>
				<Col flex="none" style={{ padding: 0 }}>
					<Label>Device Token : </Label>
				</Col>
				<DeviceTokenContainer>
					<FlexContainer>
						<Token>
							{isTokenVisible ? device.devicetoken : [...Array(device.devicetoken.length)].map(() => 'X')}
						</Token>
						<Col flex="none" style={{ padding: 0, fontSize: '14px' }}>
							<Space size={4}>
								<IconBtn icon={isTokenVisible ? <Visible /> : <Invisible />} onClick={toggleVisible} />
								<IconBtn
									icon={<Copy />}
									onClick={() => {
										navigator.clipboard.writeText(device.devicetoken);
										message.info('Copied Device Token to Clipboard');
									}}
								/>
							</Space>
						</Col>
					</FlexContainer>
				</DeviceTokenContainer>
			</FlexContainer>
		</Card>
	);
};

export default RecipeDeviceCard;
// style
const DeviceName = styled.div`
	font-size: 16px;
	font-weight: 500;
	color: #333;
`;
const DeviceId = styled(DeviceName)`
	font-size: 14px;
	color: #4d4d4d;
	margin-bottom: 5px;
`;
const FlexContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
const Label = styled.span`
	font-size: 12px;
	color: #808080;
`;

const DeviceTokenContainer = styled(Col).attrs({
	flex: 'auto',
	size: 4,
})`
	background: #f7f7f7;
	padding: 2px 5px;
	border-radius: 4px;
	margin-left: 4px;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const Token = styled(Col).attrs({
	flex: 'auto',
})`
	color: #808080;
	padding: 0 !important;

	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	text-security: disc;
`;
const IconBtn = styled(Button).attrs({
	type: 'link',
})`
	width: 20px !important;
	padding: 0;

	color: #808080 !important;
`;
