import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/file_download_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M18,15 L18,18 L5.99999997,18 L5.99999997,15 L3.99999997,15 L3.99999997,18 C3.99999997,19.1 4.89999997,20 5.99999997,20 L18,20 C19.1,20 20,19.1 20,18 L20,15 L18,15 Z M17,11 L15.59,9.58999999 L13,12.17 L13,3.99999997 L11,3.99999997 L11,12.17 L8.40999999,9.58999999 L6.99999998,11 L12,16 L17,11 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const Download = (props) => {
	return <Icon {...props} component={icon} />;
};
export default Download;
