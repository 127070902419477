import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'antd';
import ResetPasswordForm from 'components/forms/auth/ResetPasswordForm';
import PublicContainer from 'components/Layouts/PublicContainer';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { resetPassword } from 'reduxStore/actions/authAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';
import * as yup from 'yup';

const schema = yup.object().shape({
	email: yup.string().required('Please enter your email.').email('Invalid email address format.'),
});

const ResetPasswordPage = ({ message, resetPassword }) => {
	const prevMsg = usePrevious(message);

	const resetProps = useForm({ mode: 'onTouched', resolver: yupResolver(schema) });

	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			if (message && message.description === 'User not found') {
				resetProps.setError('email', { message: message.description });
			} else {
				openNotification(message);
			}
		}
	}, [message, prevMsg, resetProps]);

	const onReset = (data) => {
		resetPassword(data);
	};

	return (
		<PublicContainer>
			<ParentContainer>
				<Label>Reset password</Label>
				<SubLabel>
					If you forgot your password, well, then we’ll email you instructions to reset your password.
				</SubLabel>
				<Form layout="vertical">
					<ResetPasswordForm formProps={resetProps} />
					<Button type="primary" style={{ width: '100%' }} onClick={resetProps.handleSubmit(onReset)}>
						Send Reset Link
					</Button>
				</Form>
			</ParentContainer>
		</PublicContainer>
	);
};

const mapStateToProps = ({ authReducer }) => ({
	message: authReducer.forgotMessage,
	loading: authReducer.loading,
});

const mapDispatchToProps = { resetPassword: resetPassword.request };

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

const Label = styled.div`
	font-size: 28px;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 0.44px;
	color: #333333;
`;

const SubLabel = styled.div`
	margin: 8px 0px 24px 0px;
	font-size: 14px;
	font-weight: 300;
	line-height: normal;
	letter-spacing: normal;
	color: #808080;
`;

const ParentContainer = styled.div`
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	max-width: 380px;
	@media only screen and (max-width: 768px) {
		width: 90%;
	}
`;
