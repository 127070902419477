import {
	LOAD_PROJECTS,
	LOAD_PROJECTS_WITH_RECIPE,
	LOAD_PROJECT,
	CREATE_PROJECT,
	UPDATE_PROJECT,
	DELETE_PROJECT,
} from '../actions/projectAction';
import { shapeMessage } from 'util/openNotification';

const initState = {
	projects: [],
	projectsWithRecipe: [],
	project: {},
	loading: false,
	crudLoading: false,
	message: '',
};

const projectReducer = (state = initState, action) => {
	switch (action.type) {
		// ----- LOAD_PROJECTS -----
		case LOAD_PROJECTS.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_PROJECTS.SUCCESS:
			return {
				...state,
				projects: action.payload.projects,
				loading: false,
			};
		case LOAD_PROJECTS.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- LOAD_PROJECTS WITH RECIPE -----
		case LOAD_PROJECTS_WITH_RECIPE.REQUEST:
			return {
				...state,
			};
		case LOAD_PROJECTS_WITH_RECIPE.SUCCESS:
			return {
				...state,
				projectsWithRecipe: action.payload.projects,
			};
		case LOAD_PROJECTS_WITH_RECIPE.FAILURE:
			return {
				...state,
			};

		// ----- LOAD_PROJECT -----
		case LOAD_PROJECT.REQUEST:
			return {
				...state,
				message: '',
				loading: true,
			};
		case LOAD_PROJECT.SUCCESS:
			return {
				...state,
				project: action.payload.project,
				loading: false,
			};
		case LOAD_PROJECT.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- CREATE_PROJECT -----
		case CREATE_PROJECT.REQUEST:
			return {
				...state,
				message: '',
				crudLoading: true,
			};
		case CREATE_PROJECT.SUCCESS:
			return {
				...state,
				projects: action.payload.projects,
				crudLoading: false,
				message: shapeMessage(action.type),
			};
		case CREATE_PROJECT.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- UPDATE_PROJECT -----
		case UPDATE_PROJECT.REQUEST:
			return {
				...state,
				message: '',
				crudLoading: true,
			};
		case UPDATE_PROJECT.SUCCESS:
			return {
				...state,
				projects: action.payload.projects,
				crudLoading: false,
				message: shapeMessage(action.type),
			};
		case UPDATE_PROJECT.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- DELETE_PROJECT -----
		case DELETE_PROJECT.REQUEST:
			return {
				...state,
				message: '',
				crudLoading: true,
			};
		case DELETE_PROJECT.SUCCESS:
			return {
				...state,
				projects: action.payload.projects,
				crudLoading: false,
				message: shapeMessage(action.type),
			};
		case DELETE_PROJECT.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ----- DEFAULT -----
		default:
			return state;
	}
};

export default projectReducer;
