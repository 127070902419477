import { Divider, Input, Space, Switch } from 'antd';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import FormItem from '../formItem';
import styled from 'styled-components';

const ConnectionConfigForm = ({
	config,
	formProps: {
		control,
		formState: { errors },
		getValues,
	},
}) => {
	const [isConnect, setIsConnect] = useState(getValues('isConnectNetpie'));

	return (
		<>
			<FormItem label="Connect this edge to NETPIE" error={errors?.isConnectNetpie?.message || null}>
				<SwitchContainer>
					<Space>
						<SwitchStatus active={isConnect}>{isConnect ? 'On' : 'Off'}</SwitchStatus> : Create and enter
						NETPIE device credential below
					</Space>
					<Controller
						name="isConnectNetpie"
						control={control}
						// defaultValue={isConnect}
						render={({ field: { value, ...rest } }) => (
							<Switch onClick={() => setIsConnect(!value)} checked={value} {...rest} />
						)}
					/>
				</SwitchContainer>
			</FormItem>

			<Divider />
			<FormItem label="Client ID" error={isConnect ? errors?.clientId?.message : null}>
				<Controller
					name="clientId"
					control={control}
					defaultValue={config ? config.clientId : ''}
					render={({ field }) => (
						<Input placeholder="Enter your edge client ID" disabled={!isConnect} {...field} />
					)}
				/>
			</FormItem>
			<FormItem label="Token" error={isConnect ? errors?.token?.message : null}>
				<Controller
					name="token"
					control={control}
					defaultValue={config ? config.token : ''}
					render={({ field }) => (
						<Input placeholder="Enter your edge token" disabled={!isConnect} {...field} />
					)}
				/>
			</FormItem>
			<FormItem label="Secret" error={isConnect ? errors?.secret?.message : null}>
				<Controller
					name="secret"
					control={control}
					defaultValue={config ? config.secret : ''}
					render={({ field }) => (
						<Input placeholder="Enter your edge secret" disabled={!isConnect} {...field} />
					)}
				/>
			</FormItem>
		</>
	);
};

export default ConnectionConfigForm;

const SwitchContainer = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;

	font-size: 14px;
	font-weight: 300;
	color: #4d4d4d;
`;
const SwitchStatus = styled.div`
	font-weight: 600;
	color: ${({ active }) => (active ? '#ffaf02' : '#4d4d4d')};
`;
