import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_DEVICES = createTypes('device', 'list');
const LOAD_RDEVICES = createTypes('recipe device', 'list');
const LOAD_DEVICE = createTypes('device', 'show');

const CREATE_DEVICE = createTypes('device', 'create');
const UPDATE_DEVICE = createTypes('device', 'update');
const DELETE_DEVICE = createTypes('device', 'delete');
const UNLINK_DEVICE = createTypes('device', 'unlink');
const LINK_DEVICE = createTypes('device', 'link');

const loadDevices = {
	request: (ids) => createAction(LOAD_DEVICES.REQUEST, { ids }),
	success: (devices) => createAction(LOAD_DEVICES.SUCCESS, { devices }),
	failure: (error) => createAction(LOAD_DEVICES.FAILURE, { error }),
};
const loadRDevices = {
	success: (rDevices) => createAction(LOAD_RDEVICES.SUCCESS, { rDevices }),
};
const loadDevice = {
	request: () => createAction(LOAD_DEVICE.REQUEST, {}),
	success: () => createAction(LOAD_DEVICE.SUCCESS, {}),
	failure: (error) => createAction(LOAD_DEVICE.FAILURE, { error }),
};
const createDevice = {
	request: (device) => createAction(CREATE_DEVICE.REQUEST, { device }),
	success: (devices) => createAction(CREATE_DEVICE.SUCCESS, { devices }),
	failure: (error) => createAction(CREATE_DEVICE.FAILURE, { error }),
};
const updateDevice = {
	request: (device) => createAction(UPDATE_DEVICE.REQUEST, { device }),
	success: (devices) => createAction(UPDATE_DEVICE.SUCCESS, { devices }),
	failure: (error) => createAction(UPDATE_DEVICE.FAILURE, { error }),
};
const deleteDevice = {
	request: (device) => createAction(DELETE_DEVICE.REQUEST, { device }),
	success: (devices) => createAction(DELETE_DEVICE.SUCCESS, { devices }),
	failure: (error) => createAction(DELETE_DEVICE.FAILURE, { error }),
};
const unlinkDevice = {
	request: (recipeid, devices) => createAction(UNLINK_DEVICE.REQUEST, { recipeid, devices }),
	success: (rdevices) => createAction(UNLINK_DEVICE.SUCCESS, { rdevices }),
	failure: (error) => createAction(UNLINK_DEVICE.FAILURE, { error }),
};
const linkDevice = {
	request: (recipeid, devices) => createAction(LINK_DEVICE.REQUEST, { recipeid, devices }),
	success: (rdevices) => createAction(LINK_DEVICE.SUCCESS, { rdevices }),
	failure: (error) => createAction(LINK_DEVICE.FAILURE, { error }),
};

export {
	LOAD_DEVICES,
	loadDevices,
	LOAD_RDEVICES,
	loadRDevices,
	LOAD_DEVICE,
	loadDevice,
	CREATE_DEVICE,
	createDevice,
	UPDATE_DEVICE,
	updateDevice,
	DELETE_DEVICE,
	deleteDevice,
	UNLINK_DEVICE,
	unlinkDevice,
	LINK_DEVICE,
	linkDevice,
};
