import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Form, Space, Steps, Grid } from 'antd';
import { ArrowLeft } from 'assets/icons';
import { ModelDetailForm, ModelSubtypeForm, ModelTypeForm } from 'components/forms/model';
import AlertModal from 'components/modals/AlertModal';
import React, { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createModel, loadSubtypes, loadTypes } from 'reduxStore/actions/modelAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import { usePrevious } from 'util/usePrev';
import * as yup from 'yup';

const { useBreakpoint } = Grid;
const { Step } = Steps;

// Schema
const nameSchema = yup.object().shape({
	name: yup.string().required().max(50),
	description: yup.string().max(200),
});
const typeSchema = yup.object().shape({
	modelType: yup.number().required(),
});
const subtypeSchema = yup.object().shape({
	modelSubType: yup.number().required(),
});

const CreateModelPage = ({ loadTypes, loadSubTypes, types, subtypes, createModel, message }) => {
	const screens = useBreakpoint();
	const prevMsg = usePrevious(message);

	// Form
	const nameForm = useForm({ mode: 'onTouched', resolver: yupResolver(nameSchema) });
	const typeForm = useForm({ mode: 'onChange', resolver: yupResolver(typeSchema) });
	const subtypeForm = useForm({ mode: 'onChange', resolver: yupResolver(subtypeSchema) });
	const type = typeForm.watch('modelType');
	const subtype = subtypeForm.watch('modelSubType');

	// State
	const history = useHistory();
	const [alertVisible, setAlertVisible] = useState(false);
	const [current, setCurrent] = useState(0);
	const [DATA, setData] = useState(null);

	// Steps
	const createSteps = [
		{
			title: 'Model detail',
			form: <ModelDetailForm formProps={nameForm} />,
		},
		{
			title: 'Select model type',
			form: <ModelTypeForm formProps={typeForm} select={type} types={types} />,
		},
		{
			title: 'Select model sub-type',
			form: <ModelSubtypeForm formProps={subtypeForm} select={subtype} selectedType={type} subtypes={subtypes} />,
		},
	];

	// Effects
	useEffect(() => {
		loadTypes();
		loadSubTypes();
	}, [loadTypes, loadSubTypes]);

	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			openNotification(message);
		}
	}, [message, prevMsg]);

	// Callbacks
	const next = useCallback(() => setCurrent((prev) => prev + 1), []);
	const prev = useCallback(() => setCurrent((prev) => prev - 1), []);
	const onSubmit = useCallback(
		(data) => {
			setData((prevData) => ({ ...prevData, ...data }));

			if (current === createSteps.length - 1) {
				createModel({ ...DATA, ...data });
			} else {
				next();
			}
		},
		[current, createSteps.length, createModel, DATA, next]
	);

	const handleClickCancel = useCallback(() => setAlertVisible(true), []);
	const handleCancel = useCallback(() => {
		setAlertVisible(false);
		setCurrent(0);
		history.push('/m');
	}, [history]);

	return (
		<>
			<Container>
				<Button type="link" style={{ padding: 0 }} onClick={handleClickCancel}>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<ArrowLeft />
						Back to ML models
					</div>
				</Button>
				<Title>Build Model</Title>
			</Container>
			<StepsContainer screens={screens} current={current} createSteps={createSteps} />
			<Divider style={{ marginTop: 0 }} />
			<Container>
				<StepTitle>{createSteps[current].title}</StepTitle>
				<Form layout="vertical">{createSteps[current].form}</Form>
				<ButtonGroup
					current={current}
					createSteps={createSteps}
					nameForm={nameForm}
					typeForm={typeForm}
					subtypeForm={subtypeForm}
					onSubmit={onSubmit}
					handleClickCancel={handleClickCancel}
					prev={prev}
				/>
			</Container>
			<AlertModal
				visible={alertVisible}
				alertType="modelwarning"
				handleCancel={() => setAlertVisible(false)}
				handleOk={handleCancel}
			/>
		</>
	);
};

const StepsContainer = ({ screens, current, createSteps }) => (
	<Steps
		type="navigation"
		size="small"
		current={current}
		direction={screens.md ? 'horizontal' : 'vertical'}
		style={{ maxWidth: '970px', margin: '20px auto 0' }}
	>
		{createSteps.map((step, index) => (
			<Step
				style={!screens.md && { textAlign: 'left', marginLeft: '30px' }}
				key={`createstep_${index}`}
				title={
					<Space direction="vertical" size={0} style={!screens.md && { paddingBottom: '6px' }}>
						<StepNo>STEP {index + 1}</StepNo>
						<StepLabel>{step.title}</StepLabel>
					</Space>
				}
			/>
		))}
	</Steps>
);

const ButtonGroup = ({ current, createSteps, nameForm, typeForm, subtypeForm, onSubmit, handleClickCancel, prev }) => (
	<BtnContainer>
		<Space>
			{current === 0 && <Button onClick={handleClickCancel}>Cancel</Button>}
			{current > 0 && (
				<Button onClick={prev}>
					<Space align="center">
						<LeftOutlined /> Previous
					</Space>
				</Button>
			)}
			{current < createSteps.length - 1 && (
				<Button
					type="primary"
					onClick={current === 0 ? nameForm.handleSubmit(onSubmit) : typeForm.handleSubmit(onSubmit)}
				>
					<Space align="center">
						Next <RightOutlined />
					</Space>
				</Button>
			)}
			{current === createSteps.length - 1 && (
				<Button type="primary" onClick={subtypeForm.handleSubmit(onSubmit)}>
					Build
				</Button>
			)}
		</Space>
	</BtnContainer>
);

// Styles
const Title = styled.div`
	font-size: 20px;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #333333;
	@media (max-width: 576px) {
		font-size: 16px;
	}
`;

const StepNo = styled.span`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #b3b3b3;
	@media (max-width: 576px) {
		font-size: 12px;
	}
`;

const StepLabel = styled(StepNo)`
	font-weight: 600;
	color: #4d4d4d;
	@media (max-width: 576px) {
		font-size: 12px;
	}
`;

const StepTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
	letter-spacing: 0.4px;
	color: #333333;
	@media (max-width: 576px) {
		font-size: 14px;
	}
`;

const BtnContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

const Container = styled.div`
	max-width: 992px;
	margin: 0 auto;
	padding: 0 20px;
`;

const mapStateToProps = ({ modelReducer }) => ({
	types: modelReducer.types,
	subtypes: modelReducer.subtypes,
	message: modelReducer.message,
});

const mapDispatchToProps = {
	loadTypes: loadTypes.request,
	loadSubTypes: loadSubtypes.request,
	createModel: createModel.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateModelPage);
