import React, { useState } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import SiderComponent from './Sider';
import HeaderComponent from './Header';

export const CollapsedContext = React.createContext();

const LayoutContainer = ({ children, mode, projectList, user, updateProfile, changePassword }) => {
	const [collapsed, setCollapsed] = useState(false);
	const handleBreakpoint = (broken) => setCollapsed(broken);
	const toggleSider = () => {
		setCollapsed(!collapsed);
	};

	const [visible, setvisible] = useState(false);
	const toggleDrawer = () => {
		setvisible(!visible);
	};

	return (
		<CollapsedContext.Provider value={{ collapsed }}>
			<Layout>
				<SiderComponent
					collapsed={collapsed}
					onBreakpoint={handleBreakpoint}
					onCloseDrawer={toggleDrawer}
					visibleDrawer={visible}
					mode={mode}
					projectList={projectList}
				/>
				<Layout style={{ minHeight: '100vh', marginLeft: collapsed ? 0 : 250 }}>
					<HeaderComponent
						toggleSider={toggleSider}
						toggleDrawer={toggleDrawer}
						user={user}
						updateProfile={updateProfile}
						changePassword={changePassword}
					/>
					<Content>{children}</Content>
					<Footer>Copyright © 2022 NECTEC</Footer>
				</Layout>
			</Layout>
		</CollapsedContext.Provider>
	);
};

export default LayoutContainer;

const Content = styled(Layout.Content)`
	background-color: rgba(245, 247, 251, 0.6);
	padding: 24px 25px 12px 24px;
`;

const Footer = styled(Layout.Footer)`
	font-size: 12px;
	font-weight: 300;
	letter-spacing: 0.5px;
	text-align: center;
	color: #b3b3b3;
	min-height: 50px;
	background-color: #fff;
	padding: 16px 0 17px 1px;
`;
