import React, { useState, useMemo, useEffect } from 'react';
import { Button, Form } from 'antd';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BaseModal from './baseModal';
import ChangePassForm from 'components/forms/auth/ChangePassForm';
import SettingProfileForm from 'components/forms/auth/SettingProfileForm';
import styled, { css } from 'styled-components';

const ProfileSettingModal = ({ visible, handleCancel, stateApi, user, handleEditProfile, handleChangePass, authError }) => {

	const [mode, setMode] = useState('profileSetting');

	const schema = {
		SetProfileSchema: yup.object().shape({
			email: yup.string().required().email('email'),
			name: yup.string().required('Please enter your name.'),
			username: yup.string().required(),
		}),
		ChangePassSchema: yup.object().shape({
			currentpassword: yup.string().required('Please enter your old password.').min(6, 'Current password must be at least 6 characters.'),
			newpassword: yup.string().required('Please enter your new password.').min(6, 'Password must be at least 6 characters.'),
			confirmpassword: yup
			.string()
			.when('newpassword', (newpassword, field) =>
				newpassword
					? field.required('Please confirm your new password.').oneOf([yup.ref('newpassword')], 'Your new password and confirmation password does not match.')
					: field
			),
		}),
	};

	const selectedSchema = useMemo(() => {
		if (mode === 'profileSetting') return schema.SetProfileSchema;
		if (mode === 'changePass') return schema.ChangePassSchema;
		return null;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mode]);

	const resolver = yupResolver(selectedSchema);

	const { control, handleSubmit, reset, setValue, formState: {errors}, setError } = useForm({
		resolver,
		mode: 'onTouched',
	});

	const setProfileForm = () => {
		setValue('name', user?.name || '');
		setValue('email', user?.email || '');
		setValue('username', user?.username);
	}

	useEffect(() => {
		if (visible === true) {
			setProfileForm()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [visible]);

	useEffect(() => {
		if(authError.currentpassword){
			setError("currentpassword", {message: authError?.currentpassword?.message});
		}
	}, [authError])

	const onCancel = () => {
		reset();
		handleCancel();
		setMode('profileSetting');
	};

	useEffect(() => {
		visible === false && reset();
	}, [visible, reset]);


	const onSubmit = (data) => {
		handleEditProfile({...data, userid: user?.userid});
	};
	
	const onChangePassSubmit = (data) => {
		handleChangePass({...data, userid: user?.userid});
	};

	const onEnter = (e) => {
		if (e.code === 'Enter') handleSubmit(onSubmit)();
	};

	const onEnterCP = (e) => {
		if (e.code === 'Enter') handleSubmit(onChangePassSubmit)();
	};

	const renderContent = () => {
		if (mode === 'changePass') {
			return (
					<Form layout="vertical" onKeyDown={(e) => onEnterCP(e)}>
						<ChangePassForm user={user} control={control} errors={errors} />
					</Form>
			);
		}

		return (
				<Form layout="vertical" onKeyDown={(e) => onEnter(e)}>
					<SettingProfileForm
						control={control}
						errors={errors}
						user={user}
						modeChangePass={() => setMode('changePass')}
					/>
				</Form>
		);
	};

	return (
		<div>
			<CustBaseModal
				heightModal={mode === 'changePass' ? true : null}
				visible={visible}
				iconBack={
					mode === 'changePass' && (
						<div
							onClick={() => {
								setMode('profileSetting');
								reset();
								setProfileForm();
							}}
						>
							<span className="material-icons-outlined">arrow_back</span>
						</div>
					)
				}
				title={mode === 'profileSetting' ? 'Profile Settings' : 'Change Password'}
				titleDescription={mode === 'changePass' ? 'Set a unique password to protect your account.' : null}
				onCancel={onCancel}
				footer={
					mode === 'profileSetting'
						? [
								<Button type="primary" key="submit" loading="" onClick={handleSubmit(onSubmit)}>
									Update Profile
								</Button>,
						  ]
						: [
								<Button type="secondary" key="cancel" onClick={onCancel}>
									Cancel
								</Button>,
								<Button type="primary" key="submit2" loading="" onClick={handleSubmit(onChangePassSubmit)}>
									Change
								</Button>,
						  ]
				}
			>
				{renderContent()}
			</CustBaseModal>
		</div>
	);
};

export default ProfileSettingModal;

const CustBaseModal = styled(BaseModal)`
	${({ heightModal }) =>
		heightModal &&
		css`
			.ant-modal-body {
				//padding-top: 44px;
			}
			.ant-modal-content {
				min-height: 450px;
			}
			.ant-modal-footer {
				margin-top: 65px;
			}
		`}
`;
