import React from 'react';
import { Controller } from 'react-hook-form';
import { Input, Checkbox } from 'antd';
import FormItem from '../formItem';
import styled from 'styled-components';

const RegisterForm = ({
	formProps: {
		control,
		formState: { errors },
	},
}) => {
	return (
		<div>
			<FormItem label="Name" error={errors?.name?.message}>
				<Controller
					name="name"
					control={control}
					render={({ field }) => <Input placeholder="Enter your name" {...field} />}
				/>
			</FormItem>
			<FormItem label="Email" error={errors?.email?.message}>
				<Controller
					name="email"
					control={control}
					render={({ field }) => <Input placeholder="Enter your email" {...field} />}
				/>
			</FormItem>
			<FormItem label="Username" error={errors?.username?.message}>
				<Controller
					name="username"
					control={control}
					render={({ field }) => <Input placeholder="Enter your username" {...field} />}
				/>
			</FormItem>
			<FormItem label="Password" error={errors?.password?.message}>
				<Controller
					name="password"
					control={control}
					render={({ field }) => <InputPass placeholder="Enter your password" {...field} />}
				/>
			</FormItem>
			<FormItem label="Confirm Password" error={errors?.confirmpassword?.message}>
				<Controller
					name="confirmpassword"
					control={control}
					render={({ field }) => <InputPass placeholder="Enter your password" {...field} />}
				/>
			</FormItem>
			<FormItem label="" error={errors?.agree?.message}>
				<Controller
					name="agree"
					control={control}
					render={({ field: { value, ...rest } }) => (
						<Checkbox checked={value} {...rest}>
							I have read and agree to the{' '}
							<a href="https://daysie.io/privacypolicy" target="_blank" rel="noreferrer">
								Privacy Policy
							</a>{' '}
							and{' '}
							<a href="https://daysie.io/terms-of-service" target="_blank" rel="noreferrer">
								Terms of Service
							</a>
						</Checkbox>
					)}
				/>
			</FormItem>
		</div>
	);
};

export default RegisterForm;

const InputPass = styled(Input.Password)`
	.ant-input {
		border: unset !important;
		box-shadow: unset !important;
		-webkit-box-shadow: unset !important;
	}
	border-radius: 5px !important;
`;
