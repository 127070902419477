import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import styled from 'styled-components';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';

dayjs.extend(duration);

export default function Bar(props) {
	const { playing, duration, curTime, onTimeUpdate, onPause, onPlay } = props;

	const curPercentage = (curTime / duration) * 100;

	function formatDuration(duration) {
		return dayjs.duration(duration, 'seconds').format('mm:ss', { trim: false });
	}

	function calcClickedTime(e) {
		const clickPositionInPage = e.pageX;
		const bar = document.querySelector('.bar__progress');
		const barStart = bar.getBoundingClientRect().left + window.scrollX;
		const barWidth = bar.offsetWidth;
		const clickPositionInBar = clickPositionInPage - barStart;
		const timePerPixel = duration / barWidth;
		return timePerPixel * clickPositionInBar;
	}

	function handleTimeDrag(e) {
		onTimeUpdate(calcClickedTime(e));

		const updateTimeOnMove = (eMove) => {
			onTimeUpdate(calcClickedTime(eMove));
		};

		document.addEventListener('mousemove', updateTimeOnMove);

		document.addEventListener('mouseup', () => {
			document.removeEventListener('mousemove', updateTimeOnMove);
		});
	}

	return (
		<BarContainer>
			{playing ? <PauseCircleOutlined onClick={onPause} /> : <PlayCircleOutlined onClick={onPlay} />}
			<span className="bar__time">
				{formatDuration(curTime)} / {formatDuration(duration)}{' '}
			</span>
			<BarProgress
				style={{
					background: `linear-gradient(to right, orange ${curPercentage}%, #e6e6e6 0)`,
				}}
				onMouseDown={(e) => handleTimeDrag(e)}
				className="bar__progress"
			>
				<span className="bar__progress__knob" style={{ left: `${curPercentage - 2}%` }} />
			</BarProgress>
		</BarContainer>
	);
}

const BarContainer = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const BarProgress = styled.div`
	width: 70%;
	border-radius: 5px;
	/* margin: 0 20px; */
	height: 5px;
	display: flex;
	align-items: center;
	cursor: pointer;

	.bar__progress__knob {
		position: relative;
		height: 16px;
		width: 16px;
		border: 1.5px solid white;
		border-radius: 50%;
		background-color: orange;
	}
`;
