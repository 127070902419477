import { Modal } from 'antd';
import React from 'react';
import styled from 'styled-components';

const BaseModal = ({ children, title, titleDescription, titlePosition, iconBack, ...rest }) => {
	return (
		<StyledModal
			closeIcon={
				<IconClose>
					<span className="material-icons-outlined">close</span>
				</IconClose>
			}
			title={
				title ? (
					<TitleContainer pos={titlePosition}>
						<IconBack>{iconBack}</IconBack>
						<Title>{title}</Title>
						<TitleDescription>{titleDescription}</TitleDescription>
					</TitleContainer>
				) : null
			}
			{...rest}
		>
			{children}
		</StyledModal>
	);
};

const StyledModal = styled(Modal).attrs({
	centered: true,
	maskClosable: false,
})`
	.ant-modal-header {
		padding: 16px 24px 0;
		border-bottom: 0;
	}
	.ant-modal-content {
		border-radius: 6px;
		overflow: hidden;
	}
	.ant-modal-footer {
		border: 0;
		padding: 0 16px 10px;
	}
`;
const TitleContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: ${({ pos }) => (pos === 'left' ? 'flex-start' : 'center')};
`;

const Title = styled.div`
	font-size: 20px;
	font-weight: bold;
`;
const TitleDescription = styled.div`
	margin-top: 4px;
	font-size: 14px;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: 0.5px;
	text-align: center;
	color: #808080;
`;

const IconBack = styled.div`
	position: absolute;
	left: 0;
	top: 15px;
	padding-left: 24px;
	cursor: pointer;
`;

const IconClose = styled.span`
	position: absolute;
	left: 15px;
	top: 15px;
`;

export default BaseModal;
