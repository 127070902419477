import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import BaseModal from './baseModal';
import { useForm } from 'react-hook-form';
import { ModelDetailForm } from '../forms/model';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const infoSchema = yup.object().shape({
	name: yup.string().required().max(50),
	description: yup.string().max(200),
});

const EditModelModal = ({ visible, handleCancel, handleSubmit, info, ...rest }) => {
	const infoForm = useForm({ mode: 'onTouched', resolver: yupResolver(infoSchema) });

	useEffect(() => {
		if (visible === true) {
			infoForm.setValue('name', info?.name || '');
			infoForm.setValue('description', info?.description || '');
		}
	}, [visible]);

	useEffect(() => {
		visible === false && infoForm.reset();
	}, [visible]);

	const onCancel = () => {
		infoForm.reset({
			name: '',
			description: '',
		});
		handleCancel();
	};

	// ----- Func. -----
	const onSubmit = (data) => {
		handleSubmit({ ...info, ...data });
	};

	// ----- render -----
	return (
		<BaseModal
			title="Edit Model"
			titlePosition="left"
			visible={visible}
			onCancel={onCancel}
			footer={[
				<Button onClick={onCancel}>Cancel</Button>,
				<Button type="primary" onClick={infoForm.handleSubmit(onSubmit)}>
					Save
				</Button>,
			]}
			{...rest}
		>
			<Form layout="vertical" onFinish={infoForm.handleSubmit(onSubmit)}>
				<ModelDetailForm formProps={infoForm} />
			</Form>
		</BaseModal>
	);
};

export default EditModelModal;

// ----- style -----
