import React from 'react';
import { Result, Image, Button } from 'antd';
import ErrImg from 'assets/images/404.png';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
	return (
		<Result
			icon={<Image preview={false} src={ErrImg} width="50%" />}
			title={'Oops! , Page not found on server'}
			subTitle={
				'The link you followed is either outdated, inaccurate, or the server has been instructed not to let you have it.'
			}
			extra={
				<Button type="primary">
					<Link to="/p">Back Home</Link>
				</Button>
			}
		/>
	);
};

export default NotFoundPage;
