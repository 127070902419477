import { Button, Col, Divider, Row } from 'antd';
import { ModelTraining } from 'assets/icons';
import Empty from 'components/etc/Empty';
import { CollapsedContext } from 'components/Layouts/LayoutContainer';
import AlertModal from 'components/modals/AlertModal';
import EditModelModal from 'components/modals/editModelModal';
import PreviewModal from 'components/modals/previewModal';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	cancelQueue,
	deleteModel,
	loadModels,
	loadSubtypes,
	loadTypes,
	updateModel,
} from 'reduxStore/actions/modelAction';
import styled from 'styled-components';
import { openNotification } from 'util/openNotification';
import useInterval from 'util/useInterval';
import { usePrevious } from 'util/usePrev';
import ModelListCard from '../../components/Card/ModelListCard';
import StatusCard from '../../components/Card/StatusCard';

const ModelPage = ({
	models,
	loadModels,
	loadTypes,
	loadSubtypes,
	deleteModel,
	editModel,
	cancelQueue,
	crudLoading,
	message,
}) => {
	// state
	const [previewVisible, setPreviewVisible] = useState(false);
	const [alertVisible, setAlertVisible] = useState(false);
	const [cancelQueueVisible, setCancelQueueVisible] = useState(false);
	const [selectedModel, setSelectedModel] = useState(null);
	const [editModalVisible, setEditModalVisible] = useState(false);
	const history = useHistory();
	const trainingModel = models?.filter((item) => item.status === 6);
	const queueModel = models
		?.filter((item) => item.status === 3)
		.sort((a, b) => {
			return a.queueNumber - b.queueNumber;
		});
	const uploadModel = models?.filter((item) => item.status === 2);
	const trainModelLength = trainingModel?.length + queueModel?.length + uploadModel?.length;
	const totalModel = models?.filter((item) => item.status !== 6 && item.status !== 2 && item.status !== 3);
	const globalCollapsed = useContext(CollapsedContext);
	const prevMsg = usePrevious(message);

	// componentdidmount
	useEffect(() => {
		loadModels();
		loadTypes();
		loadSubtypes();
	}, []);

	// componentdidupdate
	useEffect(() => {
		if (prevMsg !== undefined && prevMsg !== message) {
			openNotification(message);
			if (message.type === 'success') onCloseModal();
		}
	}, [message]);

	useInterval(() => {
		// put your interval code here.
		loadModels();
	}, 2000);

	// func.
	const onCloseModal = () => {
		setPreviewVisible(false);
		setAlertVisible(false);
		setEditModalVisible(false);
		setSelectedModel(null);
		setCancelQueueVisible(false);
	};
	const handleDelete = (data) => {
		deleteModel(data);
	};
	const handleEdit = (data) => {
		editModel(data);
	};
	const handleCancelQueue = (data) => {
		cancelQueue(data);
	};

	const renderModelList = (list) => {
		return list?.map((item, index) => (
			<Col xs={24} sm={12} md={8} lg={globalCollapsed.collapsed ? 6 : 8} key={`model_${item.modelid}_${index}`}>
				<ModelListCard
					item={item}
					onClickConfig={() => history.push(`/m/${item.modelid}/config`)}
					onCancelQueue={() => {
						setCancelQueueVisible(true);
						setSelectedModel(item);
					}}
					onClickEdit={() => {
						setEditModalVisible(true);
						setSelectedModel(item);
					}}
					onClickPreview={() => {
						setPreviewVisible(true);
						setSelectedModel(item);
					}}
					onClickDelete={() => {
						setAlertVisible(true);
						setSelectedModel(item);
					}}
				/>
			</Col>
		));
	};

	const renderNoMLModel = () => {
		return <Empty title="ML model" />;
	};

	return (
		<div>
			<Row justify="space-between" align="middle" style={{ marginBottom: '24px' }}>
				<Col>
					<Label>ML Models</Label>
					<Results>
						Results <NumEdges>{models?.length}</NumEdges> models
					</Results>
				</Col>
				<Col>
					<Button onClick={() => history.push('/m/createmodel')} type="primary">
						Build Model
					</Button>
				</Col>
			</Row>

			{models?.length ? (
				<>
					{(trainingModel.length !== 0 || queueModel.length !== 0 || uploadModel.length !== 0) && (
						<>
							<LabelTop>
								<ModelTraining />
								<LabelTraining>Training Model Status</LabelTraining>
								<StatusCard
									status="waiting"
									style={{
										marginBottom: '0px',
									}}
								>
									{trainModelLength} <span style={{ fontWeight: 'normal' }}>waiting..</span>
								</StatusCard>
							</LabelTop>
							<Row gutter={[24, 24]}>
								{trainingModel.length !== 0 && renderModelList(trainingModel)}
								{queueModel.length !== 0 && renderModelList(queueModel)}
								{uploadModel.length !== 0 && renderModelList(uploadModel)}
							</Row>
						</>
					)}
					{trainingModel.length !== 0 && totalModel.length !== 0 && (
						<StyledDivider orientation="left" plain className="StyledDivider">
							<LabelModelTotal>Model Total</LabelModelTotal>
						</StyledDivider>
					)}
					<Row gutter={[24, 24]}>{renderModelList(totalModel)}</Row>
				</>
			) : (
				renderNoMLModel()
			)}

			{/* ----- Modal ----- */}
			<PreviewModal visible={previewVisible} item={selectedModel} onCancel={onCloseModal} />
			<EditModelModal
				visible={editModalVisible}
				info={selectedModel}
				handleCancel={onCloseModal}
				handleSubmit={handleEdit}
			/>
			<AlertModal
				alertType="delete"
				type="model"
				visible={alertVisible}
				item={selectedModel}
				handleCancel={onCloseModal}
				handleOk={handleDelete}
				loading={crudLoading}
			/>
			<AlertModal
				alertType="queue"
				type="model"
				item={selectedModel}
				visible={cancelQueueVisible}
				loading={crudLoading}
				handleCancel={onCloseModal}
				handleOk={handleCancelQueue}
			/>
		</div>
	);
};

const mapStateToProps = ({ modelReducer }) => {
	return {
		models: modelReducer.models,
		crudLoading: modelReducer.crudLoading,
		message: modelReducer.message,
	};
};

const mapDispatchToProps = {
	loadModels: loadModels.request,
	loadTypes: loadTypes.request,
	loadSubtypes: loadSubtypes.request,
	deleteModel: deleteModel.request,
	editModel: updateModel.request,
	cancelQueue: cancelQueue.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelPage);

const Label = styled.div`
	font-size: 24px;
	font-weight: 600;
	line-height: 1.2;
	letter-spacing: 0.5px;
	color: #333333;
	margin-top: 10px;
	margin-bottom: 14px;
`;

const LabelTraining = styled.span`
	font-size: 18px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: normal;
	color: #4d4d4d;
	padding: 0 8px;
`;

const LabelModelTotal = styled.span`
	font-size: 12px;
	font-weight: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #808080;
`;

const Results = styled.span`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.29;
	letter-spacing: normal;
	color: #808080;
	padding-bottom: 25px;
`;

const NumEdges = styled.span`
	font-size: 14px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #4d4d4d;
`;

const StyledDivider = styled(Divider)`
	::before {
		width: 0 !important;
	}
	padding: unset !important;

	padding-left: unset !important;
	padding-right: 20px !important;
`;

const LabelTop = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;
`;
