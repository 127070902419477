import React from 'react';
import { Input } from 'antd';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import FormItem from '../formItem';

const SettingProfileForm = ({ control, modeChangePass, errors }) => {

	return (
		<div>
			<Title> Personal info </Title>

			<FormItem label="Name" error={errors?.name?.message}>
				<Controller name="name" control={control} render={({ field }) => <Input placeholder="" {...field} />} />
			</FormItem>
			<FormItem label="Email" error={errors?.email?.message}>
				<Controller
					name="email"
					control={control}
					render={({ field }) => <Input disabled placeholder="" {...field} />}
				/>
			</FormItem>
			<Title> Aaccount details </Title>
			<FormItem label="Username" error={errors?.username?.message} >
				<Controller
					name="username"
					control={control}
					render={({ field }) => <Input disabled placeholder="" {...field} />}
				/>
			</FormItem>

			<Flex>
				<ChangePass>
					Change Password
					<Detail>Set a unique password to protect your account. </Detail>
				</ChangePass>
				<BtnChangePass onClick={modeChangePass}>Change Password</BtnChangePass>
			</Flex>
		</div>
	);
};

export default SettingProfileForm;

const Title = styled.div`
	font-size: 14px;
	font-weight: 500;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #b3b3b3;
`;

const ChangePass = styled.div`
	font-size: 14px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #4d4d4d;
`;

const Detail = styled.div`
	font-size: 12px;
	font-weight: 300;
	line-height: 1.5;
	letter-spacing: normal;
	color: #4d4d4d;
`;

const BtnChangePass = styled.div`
	font-size: 14px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0.5px;
	color: #ffaf02;
	cursor: pointer;
`;

const Flex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
