import React from 'react';
import Icon from '@ant-design/icons';

const icon = () => (
	<svg width="1em" height="1em" viewBox="0 0 24 24" version="1.1">
		<g id="icon/info_black_24dp" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
			<path
				d="M12,1.99999996 C6.47999998,1.99999996 1.99999996,6.47999998 1.99999996,12 C1.99999996,17.52 6.47999998,22 12,22 C17.52,22 22,17.52 22,12 C22,6.47999998 17.52,1.99999996 12,1.99999996 Z M13,17 L11,17 L11,11 L13,11 L13,17 Z M13,8.99999998 L11,8.99999998 L11,6.99999998 L13,6.99999998 L13,8.99999998 Z"
				id="Combined-Shape"
				fill="currentColor"
			></path>
		</g>
	</svg>
);

const InfoFilled = (props) => {
	return <Icon {...props} component={icon} />;
};
export default InfoFilled;
